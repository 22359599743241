import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { UText } from "../../../components"
import { Box, Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  downloadPhotographs,
  fetchFilesList,
} from "../../../core/app/slices/records/photograph/photographThunkApi"
import { RootState } from "../../../core/app/store"

import CompositePhotgraph from "./CompositePhotgraph"
import Individualphotograph from "./IndividualphotoGraph"

type FileObject = {
  fileName: string
  original_name: string
  sequence: string
  photo_position: string
}

const Photograph: FC<{
  setPhotographErrors: (value: boolean) => void
}> = ({ setPhotographErrors }) => {
  const { t } = useTranslation("common")
  const [showUploadSection, setShowUploadSection] = useState<
    "composite" | "individual" | "both"
  >("both")
  const [compositeList, setCompositeList] = useState<FileObject[]>([])
  const [individualList, setIndividualList] = useState<FileObject[]>([])
  const [additionalList, setAdditionalList] = useState<FileObject[]>([])
  const selectedFileSection = (
    section: "composite" | "individual" | "both",
  ) => {
    setShowUploadSection(section)
  }

  const [isDragOpen, setIsDragOpen] = useState<boolean>(false)
  const { patientId, caseId } = useParams()

  const dispatch = useAppDispatch()
  const { fileList } = useAppSelector((state: RootState) => state.PhotosService)

  useEffect(() => {
    // Fetch the fileList if it's empty.
    if (fileList.length === 0) {
      dispatch(fetchFilesList({ patientId, caseId }))
        .then((response) => {
          if (response.payload.data.result) {
            const compositeFiles: FileObject[] = []
            const individualFiles: FileObject[] = []
            const otherFiles: FileObject[] = []

            response.payload.data.result.forEach((file) => {
              const img = {
                fileName: file["file_name"],
                original_name: file["original_file_name"],
                sequence: file["sequence"],
                photo_position: file["photoPosition"],
              }
              if (img.fileName.includes("composite")) {
                compositeFiles.push(img)
              } else if (img.fileName.includes("individual")) {
                individualFiles.push(img)
              } else if (img.fileName.includes("others")) {
                otherFiles.push(img)
              }
            })

            // Update state based on the categorized files.
            if (compositeFiles.length > 0) {
              console.log("composite files", compositeFiles)
              setCompositeList(compositeFiles)
              setShowUploadSection("composite")
            } else if (individualFiles.length > 0) {
              setIndividualList(individualFiles)
              setShowUploadSection("individual")
            }
            if (otherFiles.length > 0) {
              console.log("other files", otherFiles)
              setAdditionalList(otherFiles)
            }
          }
        })
        .catch((err) => {
          console.log("error", err)
        })
    }
  }, [])

  useEffect(() => {
    if (additionalList && additionalList.length > 0) {
      console.log(additionalList, "additionalList")
      additionalList.map((item) => {
        dispatch(
          downloadPhotographs({
            patientId: patientId,
            caseId: caseId,
            fileName: item.fileName,
            photographType: "others",
            original_file_name: item.original_name,
            sequence: item.sequence,
            position: item.photo_position,
          }),
        )
      })
    }
  }, [additionalList])

  return (
    <>
      <UText
        sxProp={{
          display: "flex",
          justifyContent: "center",
          lineHeight: "133.4%",
          height: 40,
        }}
        color={"text.primary"}
        variant={"h5"}
      >
        {t("records.photograph.title")}
      </UText>
      <Grid
        container
        spacing={3}
        justifyContent={isDragOpen ? "unset" : "center"}
        ml={0}
        mt={!isDragOpen && "8px"}
        width={isDragOpen ? "1070px" : "872px"}
        minHeight={328}
        sx={{ flexWrap: "nowrap" }}
      >
        {(showUploadSection === "both" ||
          showUploadSection === "composite") && (
          <Grid
            sm={10}
            md={6}
            item
            justifyContent={"center"}
            sx={{
              padding: "10px !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: "10px",
              maxWidth: "416px",
            }}
          >
            <CompositePhotgraph
              selectedFileSection={selectedFileSection}
              compositeList={compositeList}
            />
          </Grid>
        )}
        {showUploadSection === "both" && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ position: "relative", padding: 1 }}
          >
            <UText
              color={"text.primary"}
              sxProp={{
                mt: 3,
                position: "inherit",
                top: "-25px",
                letterSpacing: "0.15px",
              }}
            >
              {"OR"}
            </UText>
          </Box>
        )}
        {(showUploadSection === "both" ||
          showUploadSection === "individual") && (
          <Grid
            item
            justifyContent={"center"}
            sx={{
              padding: isDragOpen ? "0!important" : "10px !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: !isDragOpen && "10px",
              maxWidth: isDragOpen ? "1070px" : "416px",
            }}
          >
            <Individualphotograph
              selectedFileSection={selectedFileSection}
              isDragOpen={isDragOpen}
              setIsDragOpen={setIsDragOpen}
              individualphotoGraphList={individualList}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Photograph
