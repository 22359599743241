import type { FC } from "react"
import * as React from "react"
import { useEffect } from "react"
import { Drawer, Stack, Button, Box } from "@mui/material"
import { ToggleButtonGroup } from "@mui/material"
import { NavToggleButton, NavLabel } from "./NavCustom"

import NewIcon from "@mui/icons-material/AddCircleOutline"
import ModIcon from "@mui/icons-material/TuneOutlined"
import SetupIcon from "@mui/icons-material/SettingsOutlined"
import StagingIcon from "@mui/icons-material/FastForwardOutlined"
import FeaturesIcon from "@mui/icons-material/AttachmentOutlined"

import UFlowQuickPlan from "../UFlowPages/QuickPlan/UFlowQuickPlan"
import UFlowSimpleMod from "../UFlowPages/SimpleMod/UFlowSimpleMod"
import WeFlowSetup from "../WeFlowPages/Setup/WeFlowSetup"
import WeFlowFeatures from "../WeFlowPages/Features/WeFlowFeatures"
import WeFlowStaging from "../WeFlowPages/Staging/WeFlowStaging"

//import UFlowNew from "../Navs/RightNavChildren/UFlow/UFlowNew/UFlowNew"
//import UFlowSetup from '../Navs/RightNavChildren/UFlow/UFlowSetup/UFlowSetup';
//import UFlowFeatures from '../Navs/RightNavChildren/UFlow/UFlowFeatures/UFlowFeatures';
//import UFlowStaging from '../Navs/RightNavChildren/UFlow/UFlowStaging/UFlowStaging';

//import WeFlowStaging from './WeFlowPages/AutoStaging/WeFlowStaging';
//import WeFlowFeatures from './WeFlowPages/AutoFeatures/WeFlowFeatures';
//import WeFlowAutoSetup from '../Navs/RightNavChildren/AutoSetup/WeFlowAutoSetup';

import { RootState } from "../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"

import { setHideRightNavPage } from "../Wasm3DServiceSlice"

const RightNavAllFlow = (props) => {
  const dispatch = useAppDispatch()
  const { hideRightNavPage } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  useEffect(() => {
    console.log("mount NewRightNav")
    // props.dispatch({ type: 'autosetup/updateState' });
    return () => {
      console.log("unmount NewRightNav")
    }
  }, [])

  const [drawerState, setDrawerState] = React.useState(false)
  const [drawerName, setDrawerName] = React.useState<string | null>(null)

  const handleDrawerName = (
    event: React.MouseEvent<HTMLElement>,
    newDrawerName: string | null,
  ) => {
    setDrawerState(newDrawerName != null)
    setDrawerName(newDrawerName)
  }

  const switchContent = (itemName: string | null) => {
    switch (itemName) {
      case "UFlowQuickPlan":
        return <UFlowQuickPlan />
      case "UFlowSimpleMod":
        return <UFlowSimpleMod />
      case "WeFlowStaging":
        return <WeFlowStaging />
      case "WeFlowFeatures":
        return <WeFlowFeatures />
      case "WeFlowSetup":
        return <WeFlowSetup />
      /*
      case "UFlowSetup":
        return <UFlowSetup />
      case "UFlowStaging":
        return <UFlowStaging />
      case "UFlowFeatures":
        return <UFlowFeatures />
      */
      default:
        return <></>
    }
  }

  useEffect(() => {
    if (hideRightNavPage == "start") {
      switchContent(null)
      dispatch(setHideRightNavPage("end"))
    }
  }, [hideRightNavPage])

  const WeFlowNavs = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={drawerName}
        exclusive
        onChange={handleDrawerName}
        aria-label="text alignment"
      >
        <NavToggleButton value="UFlowQuickPlan">
          <NewIcon />
          <NavLabel>Quick Plan</NavLabel>
        </NavToggleButton>
        <NavToggleButton value="UFlowSimpleMod">
          <ModIcon />
          <NavLabel>Simple Mod</NavLabel>
        </NavToggleButton>
        <NavToggleButton value="WeFlowSetup">
          <SetupIcon />
          <NavLabel>We Setup</NavLabel>
        </NavToggleButton>
        <NavToggleButton value="WeFlowStaging">
          <StagingIcon />
          <NavLabel>We Staging</NavLabel>
        </NavToggleButton>
        <NavToggleButton value="WeFlowFeatures">
          <FeaturesIcon />
          <NavLabel>We Features</NavLabel>
        </NavToggleButton>
      </ToggleButtonGroup>
    )
  }
  return (
    <div>
      <Box
        sx={{
          position: "absolute",
          right: "10px",
          top: "75px",
          borderRadius: "8px",
          backgroundColor: "white",
          boxShadow: 3,
        }}
      >
        <WeFlowNavs />
      </Box>
      <Drawer
        PaperProps={{
          sx: {
            width: "400px",
            height: "600px",
            marginTop: "135px",
            marginRight: "80px",
            marginBottom: "40px",
            borderRadius: "5px",
          },
        }}
        // For non-modal drawer, disableEnforceFocus should be true
        // And the  drawer width should be 0, so other element in screen can get focus
        disableEnforceFocus={true}
        sx={{ width: "0px" }}
        hideBackdrop
        anchor="right"
        open={drawerState}
        onClose={() => {
          setDrawerState(false)
          setDrawerName(null)
        }}
        elevation={2}
      >
        <Stack direction="column" spacing={1} ml={1} mr={1}>
          <Button
            onClick={() => {
              setDrawerState(false)
              setDrawerName(null)
            }}
          >
            Close
          </Button>
          {switchContent(drawerName)}
        </Stack>
      </Drawer>
    </div>
  )
}

export default RightNavAllFlow
