import { wasmModule } from "../wasm/wasmModule";
import { stagingManager } from "../stagingdatamanager/stagingmanager";

class Report {
  UpReportArrays: Map<number, any> = new Map<number, any>();
  LowReportArrays: Map<number, any> = new Map<number, any>();
  /**
   * Attachment+Auxilliaries
   */
  showAttachmentAuxilliaries() {
    const attachAuxil: Record<number, any> = {};
    // const dataAttachmentUp = casedataParser.getCaseData().datAttachments?.dataAttachmentUp;
    // const dataAttachmentDown = casedataParser.getCaseData().datAttachments?.dataAttachmentDown;
    // const teethData = casedataParser.getCaseData().teethData.teethgroup;
    // for (let i = 1; i < 33; i++) {
    //   if (!teethData[i]) {
    //     attachAuxil[i] = {
    //       toothId: i,
    //       property: 3,
    //       attachment: [],
    //     };
    //   }
    // }
    // Object.keys(teethData).forEach((p) => {
    //   const item: ToothData = teethData[p];
    //   let attachmentInfo = [];
    //   let proper: number = 0;
    //   if (isMissingTeethPullOut(item)) proper = 3;
    //   if (isPullOut(item)) proper = 6;
    //   if (isImplant(item)) proper = 2;
    //   if (isPontic(item)) proper = 1;
    //   if (item.attribute.isBridge) proper = 4;
    //   if (item.attribute.isCrown) proper = 5;
    //   if (dataAttachmentUp) attachmentInfo = this.AttachmentAuxilliaries(dataAttachmentUp, item, attachmentInfo);
    //   if (dataAttachmentDown) attachmentInfo = this.AttachmentAuxilliaries(dataAttachmentDown, item, attachmentInfo);
    //   attachAuxil[item.toothId] = {
    //     toothId: item.toothId,
    //     property: proper,
    //     attachment: attachmentInfo,
    //   };
    // });
    return attachAuxil;
  }
  update() {
    const toothupIds = wasmModule.mouthModel
      .GetArchModel(wasmModule.module.ArchType.UpArch)
      .GetToothIds();
    for (
      let i = 0;
      i < stagingManager.wasmStageData.jointUpKeypoints.length;
      i++
    ) {
      const values = wasmModule.ulabwinIns.GetReportResult(
        wasmModule.module.ArchType.UpArch,
        i
      );
      this.computeReport(i, toothupIds, values, 0);
    }
    const toothlowIds = wasmModule.mouthModel
      .GetArchModel(wasmModule.module.ArchType.DownArch)
      .GetToothIds();
    for (
      let i = 0;
      i < stagingManager.wasmStageData.jointLowerKeypoints.length;
      i++
    ) {
      const values = wasmModule.ulabwinIns.GetReportResult(
        wasmModule.module.ArchType.DownArch,
        i
      );
      this.computeReport(i, toothlowIds, values, 1);
    }
  }
  computeReport(num: number, toothIds, values, arch: number) {
    const size = toothIds.size();
    const allReportArrays: any[] = new Array<any>();
    for (let i = 0; i < size; i++) {
      const value: any = {};
      value.id = values.get(i * 7);
      const distanceRotate: number[] = [];
      for (let l = 1; l < 7; l++) {
        distanceRotate[l - 1] = values.get(i * 7 + l);
      }
      value.distanceRotate = distanceRotate;
      allReportArrays[value.id] = value;
    }
    if (arch === 0) this.UpReportArrays.set(num, allReportArrays);
    else if (arch === 1) this.LowReportArrays.set(num, allReportArrays);
  }
  /**
   * Getreport
   * @param jaw
   * @param num
   * @returns
   */
  toothReport(jaw: "upper" | "lower", num: number, rplus: boolean = true) {
    if (jaw === "upper") return this.UpReportArrays.get(num);
    return this.LowReportArrays.get(num);
  }
}
export const report = new Report();
