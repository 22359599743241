import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IAlertState {
  alertMsg: string
}

const initialState: IAlertState = {
  alertMsg: "",
}

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<string>) => {
      state.alertMsg = action.payload
    },
  },
})

export const { setAlert } = alertSlice.actions
export default alertSlice.reducer
