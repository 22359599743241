import React from "react"
import { FC, useEffect, useState } from "react"

import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  InputAdornment,
} from "@mui/material"

import { AccountCircle, Search, Clear } from "@mui/icons-material"
import { UButton } from "../../components"
import { RootState } from "../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { fetchPatientList } from "../../core/app/slices/patients"

const UFlowSearchCase = ({ onClickSearch }) => {
  const [seartText, setSerchText] = useState("")
  return (
    <>
      <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
        <InputLabel htmlFor="search-case">Search Case</InputLabel>
        <Input
          id="search-case"
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  onClickSearch(seartText)
                }}
              >
                <Search />
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => setSerchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(seartText)
            }
          }}
        />
      </FormControl>
    </>
  )
}

export const UFlowPatientList = () => {
  const { patients, loading, paging } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const dispatch = useAppDispatch()
  const onChangePage = (newPage: number) => {
    dispatch(
      fetchPatientList({
        patientQueryparams: {
          page: newPage,
          page_size: 20,
        },
      }),
    )
  }
  function onClickSearch(searchText: string) {
    console.log("Search text ", searchText)
    dispatch(
      fetchPatientList({
        patientQueryparams: {
          page: 1,
          page_size: 20,
          search: searchText,
        },
      }),
    )
  }
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={2}>
        <UButton
          variant={"contained"}
          btnType={"button"}
          btnText={"Prev Page"}
          onClickHandler={() => {
            onChangePage(Math.max(1, paging.currentPage - 1))
          }}
        />
      </Grid>
      <Grid item xs={2}>
        Current Page: {paging.currentPage}
      </Grid>{" "}
      <Grid item xs={2}>
        <UButton
          variant={"contained"}
          btnType={"button"}
          btnText={"Next Page"}
          onClickHandler={() => {
            onChangePage(paging.currentPage + 1)
          }}
        />
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={4}>
        <UFlowSearchCase onClickSearch={onClickSearch} />
      </Grid>
    </Grid>
  )
}
