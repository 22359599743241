import { FC, useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"

import DentalChart from "../../../components/DentalChart/DentalChart"
import { UText } from "../../../ui-component"

import Menu from "./component/Menu"

const DentalPortrait: FC = () => {
  const [menuProps, setMenuProps] = useState<{
    open: boolean
    position: number[]
  }>({ open: false, position: [0, 0] })
  const [selectedItem, setSelectedItem] = useState("")
  const [appliedTeeth, setappliedTeeth] = useState<
    { teeth: string; checked: string }[]
  >([])
  const [selectedTeethId, setselectedTeethId] = useState<string>("")
  useEffect(() => {
    return () => {
      setMenuProps({ open: false, position: [0, 0] })
    }
  }, [])

  return (
    <Grid
      sx={{
        width: "100%",
        padding: 5,

        borderRadius: 4,
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <UText
          sxProp={{
            color: "rgba(0, 0, 0, 0.87)",
            width: "100%",
            display: "block",
          }}
          variant={"h4"}
        >
          Dental Chart
        </UText>
        <UText
          sxProp={{
            color: "rgba(0, 0, 0, 0.6)",
            display: "block",
          }}
          variant={"subtitle1"}
        >
          Select tooth indication if needed
        </UText>
        <UText
          variant={"subtitle2"}
          sxProp={{
            color: "#616161",
            fontSize: 12,
            fontStyle: "italic",
            fontWeight: 400,
            letterSpacing: 0.4,
            display: "block",
          }}
        >
          For example: Crown, Implant or Extract
        </UText>
      </Box>
      <DentalChart
        setMenuProps={setMenuProps}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        appliedTeeth={appliedTeeth}
        setappliedTeeth={setappliedTeeth}
        selectedTeethId={selectedTeethId}
        setselectedTeethId={setselectedTeethId}
      ></DentalChart>
      <Menu
        menuProps={menuProps}
        setMenuProps={setMenuProps}
        setSelectedItem={setSelectedItem}
        appliedTeeth={appliedTeeth}
        selectedTeethId={selectedTeethId}
      ></Menu>
    </Grid>
  )
}

export default DentalPortrait
