import { wasmModule } from "../wasm/wasmModule";

import { resourceManager, blobToFile } from "./resourcemgr";
import JSZip from "jszip";

export const FS_MKDIR = [
  "/test",
  `/pre`,
  `/pre/orien`,
  `/test/pre`,
  `/test/resource`,
  `/test/resource/FeatureAdjButton`,
  `/test/resource/AttachmentLib`,
  `/test/resource/buttons`,
  `/test/resource/buttons/AutoRemovalPngs`,
  `/test/resource/toothModels`,
  `/test/resource/AppParameterSettings`,
  `/test/resource/BiteRampLib`,
  `/test/resource/Pictures`,
  `/test/case`,
  `/test/case/Setting Data2`,
  `/test/case/Setting Data2/LogDir`,
  `/test/case/Setting Data1`,
  `/test/case/Setting Data10`,
  `/test/case/Setting Data0`,
  `/test/case/Raw`,
  `/test/case/Photo`,
];
export const SCANS_PHOTO = [
  `/pre/orien/snap_both.png`,
  `/pre/orien/snap_l.png`,
  `/pre/orien/snap_u.png`,
];
export const SCREEN_SHOT = [
  `/test/case/Setting Data2/initStageActor.png`,
  `/test/case/Setting Data2/finalStageActor.png`,
];
export const FS_FILEZIP = ["bulk0", `bulk1`, `bulk2`, `bulk10`, `raw`, `photo`];
export const DOWNLOAD_FILEZIP = ["bulk0", `bulk1`, `bulk2`, `bulk10`];
export const FS_RESOURCE_TO_CASE = [
  "resource/Setting Data0",
  `resource/Setting Data1`,
  `resource/Setting Data2`,
  `resource/Setting Data10`,
  `resource/Raw`,
  `resource/Photo`,
];
export const FS_RESOURCE_TO_CASEFILE = ["/test/resource/storage.version.json"];

class ResourcesSynchronization {
  ismkdirdone = false;
  ishismkdirdone = false;
  /**
   * 将文件写入相应虚拟目录
   * @param writeFileList Record<string, Uint8Array> key 为文件路径名
   */
  async moduleFS(writeFileList: Record<string, any>) {
    console.log("make virtual directory:", writeFileList);
    if (!this.ismkdirdone) await this._fsMkdir();
    // 构建wasm需要的目录字典
    // 写入数据
    const keys = Object.keys(writeFileList);
    if (!this.ishismkdirdone) await this._historyMkdir(keys);
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];

      try {
        // 过滤非resource中的文件
        let num = 0;
        for (let i = 0; i < FS_RESOURCE_TO_CASE.length; i++) {
          const caseresource: string = FS_RESOURCE_TO_CASE[i];
          num = key.indexOf(caseresource);
          if (num !== -1) break;
        }
        if (num !== -1) continue;
        const ab = await writeFileList[key].arrayBuffer();
        const arrayBuffer = new Uint8Array(ab);
        wasmModule.module.FS.writeFile(key, arrayBuffer);
        console.log("\t\t correct!");
      } catch (excp) {
        console.warn("write virtual file false::", key);
      }
    }
    return true;
  }

  /**
   * get file information list from a Zip File
   * @param zipfile
   * @param outFiles
   * @param path
   * @returns
   */
  private getFilesFromZip(
    zipfile: File,
    outFiles: Record<string, any>,
    path?: RegExp
  ) {
    return JSZip.loadAsync(zipfile)
      .then(async (zip: any) => {
        return Promise.all(this.loadFiles(zip, outFiles, path));
      })
      .catch(reason => {
        console.warn("uncompress zip false:", reason);
      });
  }
  /**
   * get files from zip
   * @param zip
   * @param out
   * @param path
   * @returns
   */
  private loadFiles(
    zip,
    out: Record<string, Blob>,
    path?: RegExp
  ): Promise<void>[] {
    const promises: any[] = [];
    path = path || new RegExp("");
    const files = path ? zip.file(path) : zip;
    // console.log('>>>@@@', path, zip, files);
    files.forEach(fileProto => {
      if (!fileProto.dir) {
        promises.push(
          zip
            .file(fileProto.name)
            .async("blob")
            .then(blob => {
              if (fileProto.name === "undefined") {
                return;
              }
              fileProto.name.sub();

              const startIndex = fileProto.name.search(path);
              let fileName = fileProto.name;
              if (startIndex > 0) {
                fileName = (fileProto.name as string).substring(startIndex);
              }
              const fileblob = new File([blob], fileName);
              out[fileName] = fileblob;
            })
        );
      }
    });
    return promises;
  }

  /**
   * write case files to virtual FS in WASM
   * @param caseZipFiles
   */
  async writeCaseFilesToVirtualFS(caseZipFiles: Record<string, Blob>) {
    const filelist = await this.turnZipFilesIntoFileDirectory(caseZipFiles);
    const out = await this.makeVirtualDirectory(filelist);
    await this.moduleFS(out);
  }

  /**
   * Create a file directory from files in Map, it's used to make file dir in WASM .
   */
  async turnZipFilesIntoFileDirectory(fileBlobRecord: Record<string, Blob>) {
    const outFiles: Record<string, Record<string, any>> = {};
    const keys = Object.keys(fileBlobRecord);
    for (const key of keys) {
      console.log("uncompress zip >>>", key);
      const fileList: Record<string, any> = {};
      const res = fileBlobRecord[key];
      await this.getFilesFromZip(blobToFile(res, key), fileList);
      outFiles[key] = fileList;
    }
    console.log("uncompress out >>>", outFiles);
    return outFiles;
  }

  /**
   * Get static resource directory for make directory in WASM
   * @returns
   */
  async getStaticResourceDirectory() {
    const fileDirMap = resourceManager.getResourceStatic();
    const filelist = await resourceManager.turnBlobMapIntoFileDirectory(
      fileDirMap
    );
    const out = await this.makeVirtualDirectory(filelist);
    return out;
  }

  /**
   * Get case resource directory for make directory in WASM
   * @returns
   */
  async getCaseResourceDirectory() {
    const fileDirMap = resourceManager.getCaseResource();
    const filelist = await resourceManager.turnBlobMapIntoFileDirectory(
      fileDirMap
    );
    const out = await this.makeVirtualDirectory(filelist);
    return out;
  }

  /**
   * Remove files from the WASM virtual filesystem
   */
  async fsUnlink() {
    if (!wasmModule.module) return;

    const keys = FS_FILEZIP;
    for (let i = 0; i < keys.length; i++) {
      const setting = "/case/";
      let name = "";
      if (keys[i] === "bulk0") name = "Setting Data0";
      if (keys[i] === "bulk2") name = "Setting Data2";
      if (keys[i] === "bulk1") name = "Setting Data1";
      if (keys[i] === "bulk10") name = "Setting Data10";
      if (keys[i] === "raw") name = "Raw";
      if (keys[i] === "photo") name = "Photo";
      const dir = wasmModule.module.FS.readdir(`${`/test${setting}`}${name}`);
      for (let l = 0; l < dir.length; l++) {
        if (dir[l] === "." || dir[l] === ".." || dir[l] === "LogDir") continue;
        const r2 = `${`/test${setting}`}${`${name}/`}${dir[l]}`;
        await wasmModule.module.FS.unlink(r2);
      }
    }
  }

  async makeVirtualDirectory(fileList: Record<string, Record<string, Blob>>) {
    const writeFileList: Record<string, File> = {};
    const keys = Object.keys(fileList);
    for (let i = 0; i < keys.length; i++) {
      const files = fileList[keys[i]];
      let setting = "";
      switch (keys[i]) {
        case "resource":
          setting = "/";
          break;
        default:
          setting = "/case/";
          break;
      }
      const itemkeys = Object.keys(files);
      for (let l = 0; l < itemkeys.length; l++) {
        const res = blobToFile(files[itemkeys[l]], itemkeys[l]);
        // const ab = await res.arrayBuffer();
        // res = new Uint8Array(ab);
        writeFileList[`${`/test${setting}`}${itemkeys[l]}`] = res;
      }
    }
    return writeFileList;
  }

  private async _fsMkdir() {
    for (let i = 0; i < FS_MKDIR.length; i++) {
      await wasmModule.module.FS.mkdir(FS_MKDIR[i]);
    }
    this.ismkdirdone = true;
  }
  /**
   *  获取wasm保存的照片
   * @returns
   */
  getScansPhoto() {
    const photo: Map<string, File> = new Map<string, File>();
    for (let i = 0; i < SCANS_PHOTO.length; i++) {
      if (wasmModule.module.FS.analyzePath(SCANS_PHOTO[i]).exists) {
        const dir: Blob = wasmModule.module.FS.readFile(SCANS_PHOTO[i]);
        const scansNames: string[] = SCANS_PHOTO[i].split("/");
        const file: File = new File([dir], scansNames[scansNames.length - 1], {
          type: "image/png",
          lastModified: Date.now(),
        });
        photo.set(scansNames[scansNames.length - 1], file);
      }
    }
    return photo;
  }

  /**
   * 获取RAW文件夹下的mtc文件
   */
  getRawMtcs() {
    const MTC_PATH = [
      `/test/case/Raw/arch_o_u.mtc`,
      `/test/case/Raw/arch_o_l.mtc`,
    ];
    const mtcs: Map<string, File> = new Map<string, File>();
    for (let i = 0; i < MTC_PATH.length; i++) {
      if (wasmModule.module.FS.analyzePath(MTC_PATH[i]).exists) {
        const dir: Blob = wasmModule.module.FS.readFile(MTC_PATH[i]);
        const mtcNames: string[] = MTC_PATH[i].split("/");
        const file: File = new File([dir], mtcNames[mtcNames.length - 1], {
          type: "Blob",
          lastModified: Date.now(),
        });
        mtcs.set(mtcNames[mtcNames.length - 1], file);
      } else {
        const mtcNames: string[] = MTC_PATH[i].split("/");
        mtcs.set(mtcNames[mtcNames.length - 1], null);
      }
    }
    return mtcs;
  }
  getInitialFinalScreenshot() {
    const photo: Map<string, File> = new Map<string, File>();
    wasmModule.stagingcontoler.GetInitAndFinalStagePNG(true);
    wasmModule.stagingcontoler.GetInitAndFinalStagePNG(false);
    for (let i = 0; i < SCREEN_SHOT.length; i++) {
      if (wasmModule.module.FS.analyzePath(SCREEN_SHOT[i]).exists) {
        const dir: Blob = wasmModule.module.FS.readFile(SCREEN_SHOT[i]);
        const scansNames: string[] = SCREEN_SHOT[i].split("/");
        const file: File = new File([dir], scansNames[scansNames.length - 1], {
          type: "image/png",
          lastModified: Date.now(),
        });
        photo.set(scansNames[scansNames.length - 1], file);
      }
    }
    return photo;
  }
  /**
   * 前端调用后触发wasm数据保存，保存数据后js获取数据文件，并通过zip压缩返回给前端
   * @returns
   */
  async getFileZip(fileName?: string) {
    // 先保存文件
    if (wasmModule.stagingcontoler) {
      wasmModule.stagingcontoler.SaveSetUpResult();
    }
    // 补齐UD需要的json文件
    const staticFileList = await this.getStaticResourceDirectory();
    const fileListkeys = Object.keys(staticFileList);
    for (let i = 0; i < fileListkeys.length; i++) {
      for (let j = 0; j < FS_RESOURCE_TO_CASE.length; j++) {
        const fileDir: string = FS_RESOURCE_TO_CASE[j];
        const indexof = fileListkeys[i].indexOf(fileDir);
        if (indexof !== -1) {
          const path = fileListkeys[i].replace("resource", "case");
          const ab = await staticFileList[fileListkeys[i]].arrayBuffer();
          const arrayBuffer = new Uint8Array(ab);
          wasmModule.module.FS.writeFile(path, arrayBuffer);
          break;
        }
      }
    }
    // end

    let zipFileKeys = FS_FILEZIP;

    // 指定读某一个ZIP文件
    if (fileName) {
      const index = FS_FILEZIP.findIndex(val => {
        return val === fileName;
      });
      if (index !== -1) {
        zipFileKeys = [fileName];
      }
    }

    const files: Record<string, any> = {};
    for (let i = 0; i < zipFileKeys.length; i++) {
      const fileItems: Record<string, any> = {};
      const setting = "/case/";
      let name = "";
      if (zipFileKeys[i] === "bulk0") name = "Setting Data0";
      if (zipFileKeys[i] === "bulk2") name = "Setting Data2";
      if (zipFileKeys[i] === "bulk1") name = "Setting Data1";
      if (zipFileKeys[i] === "bulk10") name = "Setting Data10";
      if (zipFileKeys[i] === "raw") name = "Raw";
      if (zipFileKeys[i] === "photo") name = "Photo";
      const dir = wasmModule.module.FS.readdir(`${`/test${setting}`}${name}`);
      for (let l = 0; l < dir.length; l++) {
        if (dir[l] === "." || dir[l] === ".." || dir[l] === "LogDir") continue;
        const fullFilePath = `${`/test${setting}`}${`${name}/`}${dir[l]}`;
        const tempkey = `${`${name}/`}${dir[l]}`;
        fileItems[tempkey] = await wasmModule.module.FS.readFile(fullFilePath);
      }
      files[zipFileKeys[i]] = fileItems;
    }

    const ziplist: Record<string, File> = {};
    const list: Promise<void>[] = [];

    for (let i = 0; i < zipFileKeys.length; i++) {
      const zip = new JSZip();
      const zipKeys = Object.keys(files[zipFileKeys[i]]);
      // const promises = [] as any;
      for (let l = 0; l < zipKeys.length; l++) {
        zip.file(zipKeys[l], files[zipFileKeys[i]][zipKeys[l]], {
          binary: true,
        });
        // promises.push(promise);
      }
      //  Promise.all(promises).then(() => {
      const zipdata: Promise<void> = zip
        .generateAsync({ type: "blob" })
        .then(content => {
          // zip.generateAsync({ type: 'arraybuffer', compression: 'DEFLATE' }).then((content) => {
          // FileSaver.saveAs(content, `${keys[i]}.zip`) // 利用file-saver保存文件  自定义文件名
          // console.log('content', content, `${keys[i]}.zip`);
          const file: File = new File([content], `${zipFileKeys[i]}.zip`, {
            type: content.type,
            lastModified: Date.now(),
          });
          ziplist[`${zipFileKeys[i]}.zip`] = file;
          // this.download(`${zipFileKeys[i]}.zip`, content);
        });
      list.push(zipdata);
      // });
    }

    // 如果指定了某文件则只返回此指定文件
    if (fileName && zipFileKeys.length === 1) {
      return Promise.all(list).then(res => {
        return ziplist;
      });
    }

    // 补齐UD需要的storage.version.json文件
    // const promises = [] as any;
    const zip = new JSZip();
    for (let l = 0; l < FS_RESOURCE_TO_CASEFILE.length; l++) {
      const path = FS_RESOURCE_TO_CASEFILE[l].replace("/test/resource/", "");
      zip.file(path, staticFileList[FS_RESOURCE_TO_CASEFILE[l]], {
        binary: true,
      });
      // promises.push(promise);
      const zipdata: Promise<void> = zip
        .generateAsync({ type: "blob" })
        .then(content => {
          const pathsplit = FS_RESOURCE_TO_CASEFILE[0].split("/");
          const name = pathsplit[pathsplit.length - 1];
          const file: File = new File([content], `${name}.zip`, {
            type: content.type,
            lastModified: Date.now(),
          });
          ziplist[`${name}.zip`] = file;
          // this.download(`${name}.zip`, content);
        });
      list.push(zipdata);
    }
    // end
    return Promise.all(list).then(res => {
      return ziplist;
    });
  }

  /**
   * 保存至本地
   * @param name
   * @param data
   */
  download(name, data) {
    const urlObject: any = window.URL || window.webkitURL || window;

    const downloadData = new Blob([data]);

    const save_link: any = document.createElementNS(
      "http://www.w3.org/1999/xhtml",
      "a"
    );
    save_link.href = urlObject.createObjectURL(downloadData);
    save_link.download = name;
    this.fake_click(save_link);
  }

  fake_click(obj) {
    const ev = document.createEvent("MouseEvents");
    ev.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    obj.dispatchEvent(ev);
  }

  private async _historyMkdir(keys: string[]) {
    const his_mkdir = [];
    const donedir = [];
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      const keySplit = key.split("/");
      if (keySplit.length > 5) {
        if (keySplit[4] === "RetouchHistory") {
          if (!this.ishismkdirdone) {
            this.ishismkdirdone = true;
            his_mkdir.push(`/test/case/Setting Data10/RetouchHistory`);
          }

          if (keySplit.length > 6) {
            let donekey = false;
            for (let i = 0; i < donedir.length; i++) {
              if (donedir[i] === keySplit[5]) {
                donekey = true;
                break;
              }
            }
            if (!donekey) {
              donedir.push(keySplit[5]);
              his_mkdir.push(his_mkdir[0] + "/" + keySplit[5]);
              his_mkdir.push(his_mkdir[0] + "/" + keySplit[5] + "/PonticLib");
              his_mkdir.push(
                his_mkdir[0] + "/" + keySplit[5] + "/PonticLib/MirrorToothLib"
              );
              his_mkdir.push(
                his_mkdir[0] + "/" + keySplit[5] + "/PonticLib/StandardToothLib"
              );
              his_mkdir.push(
                his_mkdir[0] + "/" + keySplit[5] + "/Setting Data0"
              );
              his_mkdir.push(
                his_mkdir[0] + "/" + keySplit[5] + "/Setting Data1"
              );
              his_mkdir.push(
                his_mkdir[0] + "/" + keySplit[5] + "/Setting Data10"
              );
            }
          }
        }
      }
    }
    for (let i = 0; i < his_mkdir.length; i++) {
      console.log("_historyMkdir his_mkdir:", his_mkdir[i]);
      await wasmModule.module.FS.mkdir(his_mkdir[i]);
    }
  }
}
export const resourcesSynchronization = new ResourcesSynchronization();
