import { FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { Box } from "@mui/material"
import { caseManagement, viewEdit } from "@ulab/wesmileclinical"

import WasmCanves from "../../../components/WasmView/wasmCanves"
import {
  clinicalRequestBasicType,
  getZipsType,
} from "../../../core/app/slices/clinical/clinical.types"
import {
  fetchFileList,
  fetchFileZips,
} from "../../../core/app/slices/clinical/clinicalThunkApi"
import BoltonPanel from "../components/BoltonPanel/boltonPanel"
import Report from "../components/Report/report"
import RightPanel from "../components/RightPanel/rightPanel"
import Toggle from "../components/Toggle/toggle"
import ToolBar from "../components/ToolBar/toolBar"
import Transition from "../components/Transition/transition"
const { setcalsetupfinishCallback, getBoltonReport, getAttachmentIPRReport } =
  caseManagement
const { switchViewToEdit } = viewEdit

const Treat: FC = ({ zipNames, zipList, getFileName, getFileZips }) => {
  // Includes download zip and loading model completion
  const [preparation, setPreparation] = useState<boolean>(false)
  // bolton show or hidden
  const [boltonPanelShow, setBoltonPanelShow] = useState<boolean>(false)
  // bolton data
  const [boltonData, setBoltonData] = useState(null)
  // Report show or hidden
  const [reportShow, setReportShow] = useState<boolean>(false)
  const { caseId, patientId } = useParams()
  useEffect(() => {
    getFileName({
      patientId,
      caseId,
    })
    // 加载完模型的回调 Callback after loading the model
    setcalsetupfinishCallback(() => {
      // close loading
      setPreparation(true)
      // get bolton data
      const bolton = getBoltonReport() || null
      setBoltonData(bolton)
      //The test phase is set to editable mode by default 测试阶段默认可编辑状态
      switchViewToEdit(true)
      // get report data
      const data = getAttachmentIPRReport()
      console.log("reportData:", data)
    })
    return () => {
      setPreparation(false)
    }
  }, [])

  useEffect(() => {
    if (zipNames.length) {
      getFileZips({
        patientId,
        caseId,
        zipNames,
      })
    }
  }, [zipNames])
  useEffect(() => {
    if (Object.keys(zipList).length > 0) {
      caseManagement.openCase(
        document.getElementById("canvas") as HTMLCanvasElement,
        zipList,
      )
    }
  }, [zipList])

  return (
    <Box
      className="treatMain"
      id="treatMain"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexFlow: "column",
      }}
    >
      {!preparation && <Transition />}

      <ToolBar
        preparation={preparation}
        setBoltonPanelShow={setBoltonPanelShow}
        boltonPanelShow={boltonPanelShow}
      />
      <Box
        component={"div"}
        sx={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          id="left"
          component={"div"}
          sx={{
            width: "200px",
            height: "100%",
            background: "#37474F",
            borderRight: "1px solid #455A64",
          }}
        />
        <Box
          id="center"
          component={"div"}
          sx={{
            flex: "auto",
            display: "flex",
            flexDirection: "column",
            maxWidth: `calc(100% - 528px)`,
          }}
        >
          <Box
            component={"div"}
            sx={{
              flexGrow: "1",
              position: "relative",
            }}
          >
            <WasmCanves
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: "0",
                top: "0",
              }}
            />
          </Box>
          <Toggle setReportShow={setReportShow} reportShow={reportShow} />
        </Box>
        <Box
          id="right"
          component={"div"}
          sx={{
            width: "328px",
            height: "100%",
            background: "#37474F",
            borderLeft: "1px solid #455A64",
            borderTop: "1px solid #455A64",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <RightPanel />
        </Box>
      </Box>
      {boltonPanelShow && (
        <BoltonPanel
          data={boltonData}
          setBoltonPanelShow={setBoltonPanelShow}
        />
      )}
      {reportShow && <Report setReportShow={setReportShow} />}
    </Box>
  )
}

export default connect(
  ({ clinicalService }) => {
    const { zipList, zipNames } = clinicalService
    return {
      zipList,
      zipNames,
    }
  },
  (dispatch) => {
    return {
      getFileName: ({ patientId, caseId }: clinicalRequestBasicType) => {
        dispatch(
          fetchFileList({
            patientId,
            caseId,
          }),
        )
      },
      getFileZips: ({ patientId, caseId, zipNames }: getZipsType) => {
        dispatch(
          fetchFileZips({
            patientId,
            caseId,
            zipNames,
          }),
        )
      },
    }
  },
)(Treat)
