import { wasmModule } from "./modules/wasm/wasmModule";
import { resourcesSynchronization } from "./modules/resourcemanager/resourcessynchronization";
import {
  setToothMoveForbidCB,
  setShowModificationButtonCB,
  onModificationClicked,
  setMoveToothLimitCB,
  modificationLogic,
  toothMoveEvent,
  clearUndoOrRedoState,
  getCaseType,
  setShowToothInfoDialogCB,
  setToothClickedInViewModeCB,
  EToothMoveEventType,
  resetCaseType,
  setReadonly,
  disPatchPanelDataCallback,
  disPatchButton,
  setRootControl,
  TypeInNumber,
  moveWithInputValue,
} from "./modules/toothmanager/toothmovement";
import {
  resourceManager,
  ResourceManagerEventType,
} from "./modules/resourcemanager/resourcemgr";
import {
  doPresetupWorkflow,
  redoPresetupFlow,
} from "./modules/sagemaker/presetup";
import { displayModule } from "./modules/displaymodule/displaymodule";
import { stagingManager } from "./modules/stagingdatamanager/stagingmanager";
import {
  attachmentModule,
  IAttachmentIPRVisible,
} from "./modules/attachment/attachmentModule";
import { initialfinalModule } from "./modules/initialfinal/initialfinalmodule";
import {
  drawSTL,
  changeArchMode,
  zoomWithValue,
  setZoomCallback,
  clearPreview,
  saveStlToMtc,
  isInitPreview,
  drawMtcFromZips,
} from "./modules/preview/preview";
import { treatmentPlan } from "./modules/treatmentplan";
import { boltonModule } from "./modules/bolton";
import { ICaseExtInfo } from "./common/types";
import { refinementModule } from "./modules/refinement";

import type {
  IInitialFinal,
  ICaseManagement,
  IStageControl,
  IPreView,
  ITreatmentViewportControl,
  IToothMovement,
  Ihistory,
  PanelDataProps,
  IViewEdit,
  CaseFileName,
  IRefinement,
} from "./typesofinterface";
import { ESetupType } from "./typesofinterface";
import { gdsPlusSetup } from "./modules/setupmode/gdsPlus";
import { sagemakerModule } from "./modules/sagemaker/sagemakerModule";
import { iprModule } from "./modules/ipr/iprModule";
import { superimposeModule } from "./modules/superimpose/superimposemodule";
import { EToothDirection } from "./modules/toothmanager/toothdirection";
import { globalEvents, GlobalEvents } from "../utils";

export * from "./typesofinterface";

export const caseManagement: ICaseManagement = {
  async openCase(canvas: HTMLCanvasElement, zipFiles: Record<string, Blob>) {
    if (!canvas) {
      console.warn("cant opencase, canvas is null ");
      return;
    }

    await new Promise((resolve, reject) => {
      wasmModule.initWASM(canvas, async module => {
        console.log("wasm init over!!!!", canvas, module);
        resolve(true);
      });
    });

    await resourcesSynchronization.writeCaseFilesToVirtualFS(zipFiles);
    wasmModule.module.cancel_em_loop();
    wasmModule.isPresetup = false;
    wasmModule.module._setup();
  },
  async openCaseFromZip(canvas: HTMLCanvasElement, zipfile: File) {
    if (!canvas) {
      console.warn("cant opencase, canvas is null ");
      return;
    }

    try {
      await new Promise((resolve, reject) => {
        wasmModule.initWASM(canvas, async module => {
          console.log("wasm init over!!!!", canvas, module);
          resolve(true);
        });
      });
      const resFileList = await resourceManager.parseZipFilesFromAZipPacket(
        zipfile
      );
      const writeFileList = await resourcesSynchronization.makeVirtualDirectory(
        resFileList
      );
      await resourcesSynchronization.moduleFS(writeFileList);
      wasmModule.module._setup();
    } catch (excp) {
      console.warn("openCaseFromZip::", excp);
    }
  },
  async openCaseFromLocalZipFileUrl(canvas: HTMLCanvasElement, fileUrl: string) {
    const fetchRes = async (url) => {
      const resp: Response = await fetch(url, {
        method: "get",
        responseType: "arraybuffer",
      } as any);

      const blob = await resp.blob();
      return blob;
    };
    const zipBlob = await fetchRes(fileUrl);
    Object.assign(zipBlob, { lastModifiedDate: new Date(), name: "zipfile" });

    await caseManagement.openCaseFromZip(canvas, zipBlob as File);
  },
  setCallbackAfterOpencase(callback: () => void) {
    const cb = () => {
      if (callback) callback();
      globalEvents.fire(GlobalEvents.OPENCASE_DONE);
    };
    wasmModule.moduleOpenCaseReady = cb;
  },
  closeCase() {
    console.log("close case.... ");
    disPatchPanelDataCallback(false, true);
    wasmModule.delete();
  },
  presetupCase(presetupOps) {
    doPresetupWorkflow(
      presetupOps.upperArch,
      presetupOps.lowerArch,
      presetupOps.canvas,
      () => {},
      presetupOps.donecallback
    );
  },
  redoPresetup(canvas, donecallback) {
    redoPresetupFlow(canvas, () => {}, donecallback);
  },
  getScansPhoto() {
    return resourcesSynchronization.getScansPhoto();
  },
  getInitialFinalScreenshot() {
    return resourcesSynchronization.getInitialFinalScreenshot();
  },
  async getCaseFiles(fileName?: CaseFileName) {
    return await resourcesSynchronization.getFileZip(fileName);
  },
  getAttachmentIPRReport() {
    const attachment = attachmentModule.getAttachmentReport();
    const iprReport = iprModule.getIPRReport();
    const reportData = {
      attachment: attachment.attachmentReport,
      toothlist: attachment.toothList,
      ipr: iprReport,
    };
    return reportData;
  },
  getBoltonReport() {
    boltonModule.computeBolton();
    return boltonModule.getBoltonReport();
  },
  getAttachmentIPRData() {
    const visible: IAttachmentIPRVisible = {
      isHasAttachmentData: wasmModule.statusController.IsAddAttachment(),
      isHasIPRData: wasmModule.statusController.HasCollision(),
    };
    // console.log("getAttachmentIPRData>>>",visible);
    return visible;
  },
  getCaseSetupType() {
    return wasmModule.caseSetupType;
  },
  setCaseSetupType(type: ESetupType) {
    wasmModule.caseSetupType = type;
  },
  setcalsetupfinishCallback(callback: () => void) {
    wasmModule.calsetupfinishCallback = callback;
  },

  //------------------ Treatment plan ---------------------
  setCaseExtInfo(caseExtInfo: ICaseExtInfo) {
    treatmentPlan.setCaseExtInfo(caseExtInfo);
  },
  getCaseExtInfo() {
    return treatmentPlan.caseExtInfo;
  },
  getTreatmentPlanList() {
    return treatmentPlan.getTxNameList();
  },

  changeTreatment(txName: string) {
    return treatmentPlan.changeTx(txName);
  },

  renameTreatment(txName: string, newName: string) {
    return treatmentPlan.renameTx(txName, newName);
  },

  saveCurrentTx() {
    return treatmentPlan.saveCurrentTx();
  },

  delTx(txName: string) {
    return treatmentPlan.delTx(txName);
  },
};

export const stageControl: IStageControl = {
  setStageStep(upstep, lowstep) {
    stagingManager.setStageStep(upstep, lowstep);
  },
  setStageCallback(callback) {
    stagingManager.setStageCallback = callback;
  },
  setSetupTypeCaseCallback(callback) {
    stagingManager.setupTypeAndStage = callback;
    // attachmentModule.iprVisibility = callback;
  },
  setCallbackOnStageDataChanged(callback) {
    attachmentModule.attachmentiprVisibility = callback;
  },
  setEndMessageCallback(callback) {
    attachmentModule.attachmentiprVisibility = callback;
    // attachmentModule.attachmentVisibility = callback;
    // attachmentModule.iprVisibility = callback;
  },
  getStageNodeIndex(): number {
    return stagingManager.wasmStageData.keypointsIndex;
  },
};

export const initialfinal: IInitialFinal = {
  setInitialFinalEnabled: isOpen => {
    initialfinalModule.switchInitialFinalDisplayModule(isOpen);
  },
  zoomByScale(scaleFac) {
    initialfinalModule.zoomByUI(scaleFac);
  },
  setZoomInitialFinalCallback(callback) {
    initialfinalModule.setUpdateSliderCB(callback);
  },
  openOcclusalView() {
    initialfinalModule.openOcclusalView();
  },
};

export const preview: IPreView = {
  drawSTL,
  changeArchMode,
  zoomWithValue,
  setZoomCallback,
  clearPreview,
  saveStlToMtc,
  isInitPreview,
  drawMtcFromZips,
};

export const viewControlInTreatment: ITreatmentViewportControl = {
  setAttachmentVisibility(isVisible) {
    if (!wasmModule.isInit) return;
    wasmModule.statusController.DisplayAttachment(isVisible);
    //  if (!this.wasmAttachmentModule) {
    attachmentModule.wasmAttachmentModule =
      wasmModule.moduleManager.GetAttachmentModule();
    //   }
  },
  setZoomCallbackInTreatment(callback) {
    displayModule.setZoomCallback([0.25, 2.0], callback);
  },
  zoomWithValueInTreatment(val) {
    displayModule.zoomWithValue(val);
  },
  showArchMode(mode) {
    displayModule.splitScreenDisplay(false);
    if (mode === "up") displayModule.showArchByIndex(0);
    else if (mode === "low") displayModule.showArchByIndex(1);
    else displayModule.showArchByIndex(2);
  },
  setViewType(type) {
    switch (type) {
      case "left":
        displayModule.splitScreenDisplay(false);
        displayModule.setToLeftBuccalView();
        break;
      case "right":
        displayModule.splitScreenDisplay(false);
        displayModule.setToRightBuccalView();
        break;
      case "back":
        console.log("back");
        displayModule.splitScreenDisplay(false);
        wasmModule.statusController.SetToBackView();
        break;
      case "front":
        displayModule.splitScreenDisplay(false);
        displayModule.setToAnteriorView();
        break;
      case "split":
        displayModule.splitScreenDisplay(true);
        // 处理inside视窗ipr显示问题
        iprModule.updateIPRVisibility();
        break;
      default:
        break;
    }
    displayModule.setShowType(type);
  },
  setIPRVisibility(isVisible, stageIndexForShow = 0) {
    if (!wasmModule.isInit) return;
    iprModule.setIPRVisibility(isVisible, stageIndexForShow);
  },
  /**
   * set Grid is visibile
   * @param val
   * @returns
   */
  setGridVisibility(isVisible: boolean) {
    if (!wasmModule.isInit) return;
    wasmModule.wrapInstance.ShowGridLayer(isVisible);
  },

  /**
   * set Super-impose Stage
   * @param val
   * @returns
   */
  setSuperimposeStage(isUpper: boolean, step: number) {
    if (!wasmModule.isInit) return;
    superimposeModule.SetSuperimposeStage(isUpper ? 0 : 1, step);
  },

  /**
   * set Super-impose visibility
   * @param isVisible
   * @returns
   */
  setSuperimposeVisibility(isVisible: boolean) {
    if (!wasmModule.isInit) return;
    superimposeModule.ShowSuperimpose(isVisible);
  },

  /**
   * set Super-impose Opacity
   * @param isVisible
   * @returns
   */
  setSuperimposeOpacity(opacityVal: number) {
    if (!wasmModule.isInit) return;
    superimposeModule.SetSuperimposeOpacity(opacityVal);
  },

  /**
   * set Occlusion is Visible
   * @param isVisible
   */
  setOcclusionVisibility(isVisible: boolean) {
    if (!wasmModule.isInit) return;
    if (isVisible) wasmModule.statusController.TurnOnBiteCheck();
    else wasmModule.statusController.TurnOffBiteCheck();
  },

  /**
   * set space check is Visible
   * @param isVisible
   */
  setSpacecheckVisibility(isVisible: boolean) {
    if (!wasmModule.isInit) return;
    wasmModule.wrapInstance.ShowCollision(isVisible);
  },
};

export const toothMovement: IToothMovement = {
  setCallbackForMovingMolar(callback) {
    setToothMoveForbidCB(callback);
  },

  setCallbackOutOfRange(callback: () => void) {
    setMoveToothLimitCB(callback);
  },

  setCallbackModifyTooth(callback: (isShow: boolean) => void) {
    setShowModificationButtonCB(callback);
  },

  modifyButtonClicked(callback: () => void, popupCallback: () => void) {
    if (!wasmModule.isInit) return;
    onModificationClicked(callback, popupCallback);
  },

  async modificationSchemeSimple() {
    modificationLogic(false);
    // true 变为简单
    const showType = displayModule.getShowType();
    viewControlInTreatment.showArchMode("all");
    viewControlInTreatment.showArchMode(showType);

    resetCaseType(ESetupType.COPASimple);
  },

  async modificationSchemeSecondary() {
    // false  变为中等
    modificationLogic(true);
    const showType = displayModule.getShowType();
    viewControlInTreatment.showArchMode("all");
    viewControlInTreatment.showArchMode(showType);

    resetCaseType(ESetupType.COPAModerate);
  },

  setCallbackCannotMoveTooth(callback: () => void) {
    setToothClickedInViewModeCB(callback);
  },

  setCallbackShowToothInfo(callback: (panelDataP: PanelDataProps) => void) {
    // console.log("setCallbackShowToothInfo");
    // console.log("wasmModule.ulabwinIns>>",wasmModule.ulabwinIns);
    // wasmModule.ulabwinIns.SetVersionType(wasmModule.module.VersionType.E_CopaSpecial);
    // initToothInfoDialogCB();
    setShowToothInfoDialogCB(callback);
  },
  /**
   * readonly :true  不可移  false :可以移
   */
  setToothReadonly(bReadonly: boolean) {
    setReadonly(bReadonly);
    if (!wasmModule.isInit) return false;
    const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule();
    if (moveToothModule) {
      moveToothModule.SetViewMode(bReadonly);
    }
  },
  disPatchButton(dir: EToothDirection, quick: boolean) {
    disPatchButton(dir, quick);
  },

  setRootControl(isRoot) {
    setRootControl(isRoot);
  },
  moveWithInputValue: (val: number, dir: TypeInNumber) => {
    if (!wasmModule.isInit) return false;
    moveWithInputValue(val, dir);
  },
};

export const history: Ihistory = {
  undo() {
    if (!wasmModule.isInit) return false;
    const module = wasmModule.ulabwinIns.getModuleManager();
    if (module) {
      module.GetUndoOrRedoModule().GetLastState();
    }
    // close panel
    disPatchPanelDataCallback();
    return true;
  },

  /**
   * redo one step
   * @returns
   */
  redo() {
    if (!wasmModule.isInit) return false;
    const module = wasmModule.ulabwinIns.getModuleManager();
    if (module) {
      module.GetUndoOrRedoModule().GetNextState();
    }
    // close panel
    disPatchPanelDataCallback();
    return true;
  },

  /**
   * clear all steps
   * @returns
   */
  clearAll() {
    if (!wasmModule.isInit) return false;
    const module = wasmModule.ulabwinIns.getStageContoller();
    // const undo = undefined;
    if (module) {
      caseManagement.getAttachmentIPRData();
      module.ResetAutoSetUpResult();
      clearUndoOrRedoState();

      toothMoveEvent.fire(EToothMoveEventType.RESETCLICKED_EVENT);
      // close panel
      disPatchPanelDataCallback();
    }
    return true;
  },
};

export const viewEdit: IViewEdit = {
  switchViewToEdit(isEdit: boolean) {
    if (!wasmModule.isInit) return 0;
    return wasmModule.statusController.SwitchViewAndEdit(isEdit);
  },
};

export const refinement: IRefinement = {
  openRefinementModule(isOpen: boolean) {
    return refinementModule.openRefinementModule(isOpen);
  },

  setRefinementDisplayRange(displayRange: string) {
    return refinementModule.setRefinementDisplayRange(displayRange);
  },

  getRefinementInfo(archTpye: number) {
    return refinementModule.getRefinementInfo(archTpye);
  },

  changeDisplayStageValue(index: number, archTpye: number) {
    refinementModule.changeDisplayStageValue(index, archTpye);
  },

  getNumberOfRefinement(archTpye: number) {
    return refinementModule.getNumberOfRefinement(archTpye);
  },

  undoRefinement(archTpye: number) {
    refinementModule.undoRefinement(archTpye);
  },
};
