import type { FC } from "react"
import * as React from "react"
import { useEffect, useState } from "react"

import { connect } from "react-redux"

import { Box, Stack, IconButton, Grid, Input, Typography } from "@mui/material"
import { Mark } from "@mui/base"
import Slider, {
  SliderThumb,
  SliderValueLabelProps,
} from "@mui/material/Slider"
import { styled } from "@mui/material/styles"

import InitIcon from "@mui/icons-material/SkipPreviousOutlined"
import FinalIcon from "@mui/icons-material/SkipNextOutlined"
import PlayIcon from "@mui/icons-material/PlayCircleOutlined"
import PauseIcon from "@mui/icons-material/PauseCircleOutlined"
import EditKFIcon from "@mui/icons-material/EditRoad"
import { NavIconButton, NavToggleButton, NavLabel } from "./NavCustom"
import { stagingManager, updateStage } from "@ulab/wesmileclinical"

import { RootState } from "../../core/app/store"
import { useAppSelector, useAppDispatch } from "../../core/app/hooks"
import {
  manuStagingPercentSlice,
  setIsShow,
  setCurJaw,
} from "../CommonPages/ManuStagingPercentSlice"
import { setCurStage, updateState } from "../AutoStagingSlice"

const StageSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 4,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}))

const UFlowStageBar = (props) => {
  const { uiWeFlow } = useAppSelector(
    (state: RootState) => state.autoSetupSlice,
  )
  const { isShow, curJaw } = useAppSelector(
    (state: RootState) => state.manuStagingPercentSlice,
  )
  const { curStage, stagingUpdate } = useAppSelector(
    (state: RootState) => state.autoStagingSlice,
  )
  const dispatch = useAppDispatch()

  // setCaseUDesign() // Change case type into uDesign so it canbe adjusted freely

  let maxStage = Math.max(
    stagingManager.stageUpnNumber,
    stagingManager.stageLowNumber,
  )
  const [curPlayStage, setCurPlayStage] = useState<number>(0)
  const [isPlay, setIsPlay] = useState<boolean>(false)

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setIsPlay(false)
    const newV = Math.min(Number(maxStage), Number(newValue))
    setCurPlayStage(newV)
  }
  const handleBlur = () => {
    let newStage = Math.max(0, Math.min(100, curStage))
    setCurPlayStage(newStage)
  }
  const sliderRange = maxStage
  let sliderRangeU = stagingManager.stageUpnNumber
  let sliderRangeL = stagingManager.stageLowNumber
  let widthU = sliderRangeU / maxStage
  let widthL = sliderRangeL / maxStage

  interface IMark {
    value: number
    label: string
  }

  const upMarks: IMark[] = []
  const lowMarks: IMark[] = []
  // upMarks.push({ value: 0, label: "" })
  // upMarks.push({ value: 10, label: "" })
  // lowMarks.push({ value: 0, label: "" })
  // lowMarks.push({ value: 20, label: "" })

  let upNodes = stagingManager.wasmStageData.jointUpKeypoints
  for (let i = 0; i < upNodes.length; ++i) {
    if (upNodes[i].nodeType.length != 0) {
      let idx = upNodes[i].stepindex
      upMarks.push({ value: idx, label: "" })
    }
  }

  let lowNodes = stagingManager.wasmStageData.jointLowerKeypoints
  for (let i = 0; i < lowNodes.length; ++i) {
    if (lowNodes[i].nodeType.length != 0) {
      let idx = lowNodes[i].stepindex
      lowMarks.push({ value: idx, label: "" })
    }
  }

  const MarksBox = () => {
    const step = sliderRange <= 20 ? 1 : sliderRange <= 50 ? 5 : 10
    const marks: IMark[] = []
    for (let i = step; i < sliderRange; i += step)
      marks.push({ value: i / sliderRange, label: i.toString() })
    const allMarkBoxes = marks.map((mark, index) => (
      <Box
        key={index}
        sx={{ position: "absolute", left: `${mark.value * 100}%` }}
      >
        {mark.label}
      </Box>
    ))
    return (
      <Box sx={{ position: "relative", width: 1.0, height: 18 }}>
        {allMarkBoxes}
      </Box>
    )
  }

  function onClickInit() {
    setCurPlayStage(0)
  }

  function onClickFinal() {
    setCurPlayStage(maxStage)
  }

  const onEditKF = () => {
    dispatch(setIsShow(!isShow))
  }

  useEffect(() => {
    console.log("uiWeFlow is changed")
    dispatch(setIsShow(false))
    return () => {}
  }, [uiWeFlow])

  useEffect(() => {
    let intervalId
    if (isPlay) {
      if (!intervalId) {
        intervalId = setInterval(() => {
          setCurPlayStage((prevStage) => {
            let newStage = prevStage + 1
            if (newStage >= maxStage) {
              newStage = maxStage
              setIsPlay(false)
              clearInterval(intervalId)
              intervalId = null
            }
            return newStage
          })
        }, 200)
      }
    } else {
      clearInterval(intervalId)
      intervalId = null
    }
  }, [isPlay])

  useEffect(() => {
    if (curPlayStage >= maxStage) {
      setIsPlay(false)
    }
    stagingManager.setStageStepAwait(curPlayStage, curPlayStage)
    dispatch(setCurStage(curPlayStage))
  }, [curPlayStage])

  useEffect(() => {
    console.log("Stage is changed")
    sliderRangeU = stagingManager.stageUpnNumber
    sliderRangeL = stagingManager.stageLowNumber
    maxStage = Math.max(sliderRangeU, sliderRangeL)
    widthU = sliderRangeU / maxStage
    widthL = sliderRangeL / maxStage
    // setCurStage(curNode)
    if (!uiWeFlow && !isPlay) onClickFinal()
    return () => {}
  }, [props.stageData, stagingUpdate])

  useEffect(() => {
    stagingManager.setStageCallback = (stageData) => {
      console.log("stagedata has updated :", stageData)
      setCurPlayStage(
        Math.max(stageData.curUpNodeIndex, stageData.curLowerNodeIndex),
      )
    }
  }, [])

  const onClickPlay = () => {
    setIsPlay(!isPlay)
  }

  const ShowStageSlider = (props) => {
    let toShow = true
    if (isShow) {
      if (curJaw != props.jaw) toShow = false
    }
    if (!toShow) {
      return <Box sx={{ height: 24 }} />
    } else {
      let isUp = props.jaw == "upper"
      return (
        <StageSlider
          sx={{
            width: `${isUp ? widthU * 100 : widthL * 100}%`,
            color: "lightskyblue",
          }}
          size="small"
          value={curPlayStage}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          max={isUp ? sliderRangeU : sliderRangeL}
          marks={isUp ? upMarks : lowMarks}
        />
      )
    }
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        position: "absolute",
        justifyContent: "center",
        bottom: "55px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: "8px",
          backgroundColor: "white",
          boxShadow: 3,
        }}
      >
        <Stack direction="row">
          <NavIconButton onClick={onClickInit}>
            <InitIcon />
            <NavLabel textTransform="none" align="center">
              Initial{" "}
            </NavLabel>
          </NavIconButton>
          <NavIconButton onClick={onClickPlay}>
            {isPlay ? <PauseIcon /> : <PlayIcon />}
            <NavLabel textTransform="none" align="center">
              {isPlay ? <>Pause</> : <>Play</>}
            </NavLabel>
          </NavIconButton>
          {/* 
                    <NavIconButton onClick={onClickPause}>
                        <PauseIcon />
                        <NavLabel textTransform='none' align="center">Pause </NavLabel>
        </NavIconButton> */}
          <NavIconButton onClick={onClickFinal}>
            <FinalIcon />
            <NavLabel textTransform="none" align="center">
              Final{" "}
            </NavLabel>
          </NavIconButton>
        </Stack>
        <Box sx={{ width: 20 }} />
        <Box sx={{ width: 400 }}>
          <Stack direction="column">
            <ShowStageSlider jaw="upper" isShow={isShow} curJaw={curJaw} />
            <MarksBox />
            <ShowStageSlider jaw="lower" isShow={isShow} curJaw={curJaw} />
          </Stack>
        </Box>
        <Box sx={{ width: 20 }} />
      </Box>
    </div>
  )
}
export default UFlowStageBar
