import { createSlice } from "@reduxjs/toolkit"

import {
  INewPatientData,
  IPatientCaseDetails,
  IPatientList,
} from "../../../model/interface/IPatient"
import { PatientData } from "../../../model/Patient"

import {
  addNewPatient,
  addNewCase,
  fetchCaseByCaseId,
  fetchPatientById,
  fetchPatientList,
  updateCase,
} from "./patientThunkApi"
import { isError } from "lodash"

interface IPage {
  currentPage: number
  pageSize: number
  totalRecords: number
  count: number
}

interface IPatientServiceState {
  patients: IPatientList[]
  paging: IPage
  loading: "idle" | "pending" | "succeeded" | "failed"
  newPatientAPI: "idle" | "pending" | "succeeded" | "failed"
  patientData: INewPatientData
  caseDetails: IPatientCaseDetails
  caseId: string
  assigned_to: string
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
}

const initialState: IPatientServiceState = {
  patients: [],
  paging: {} as IPage,
  loading: "idle",
  newPatientAPI: "idle",
  patientData: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
  },
  caseDetails: {} as IPatientCaseDetails,
  caseId: "",
  assigned_to: "",
  isFetching: false,
  isError: false,
  isSuccess: false,
}

export const patientServiceSlice = createSlice({
  name: "patientService",
  initialState,
  reducers: {
    setData(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPatientList.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchPatientList.fulfilled, (state, action) => {
      const { result, paging, count } = action.payload
      state.patients = result || []
      state.paging = {
        currentPage: paging.current_page,
        pageSize: paging.page_size,
        totalRecords: paging.total_count,
        count: count,
      }
      state.loading = "succeeded"
    })
    builder.addCase(fetchPatientList.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
    builder.addCase(addNewPatient.pending, (state) => {
      state.newPatientAPI = "pending"
    })
    builder.addCase(addNewPatient.fulfilled, (state, action) => {
      const { data } = action.payload
      if (data.status.toUpperCase() === "SUCCESS") {
        console.log(data, "sanjay daataa")
        state.newPatientAPI = "succeeded"
        state.patientData = new PatientData().Deserialize(data.result.patient)
        state.caseDetails = { ...data.result.case }
      }
    })
    builder.addCase(addNewPatient.rejected, (state) => {
      state.newPatientAPI = "failed"
    })
    builder.addCase(addNewCase.pending, (state) => {
      state.newPatientAPI = "pending"
    })
    builder.addCase(addNewCase.fulfilled, (state, action) => {
      const { data } = action.payload
      if (data.status.toUpperCase() === "SUCCESS") {
        console.log(data, "sanjay daataa")
        state.newPatientAPI = "succeeded"
        state.patientData = new PatientData().Deserialize(data.result.patient)
        state.caseDetails = { ...data.result.case }
      }
    })
    builder.addCase(addNewCase.rejected, (state) => {
      state.newPatientAPI = "failed"
    })
    builder.addCase(updateCase.pending, (state) => {
      console.log("pending")

      state.isFetching = false
      state.isError = true
    })
    builder.addCase(updateCase.fulfilled, (state, action) => {
      console.log("..update case", action.payload)
      const { result } = action.payload
      state.caseDetails = { ...result }
      state.isSuccess = true
      state.isFetching = false
    })
    builder.addCase(updateCase.rejected, (state) => {
      console.log("rejected")

      state.isFetching = false
      state.isError = true
    })
    builder.addCase(fetchPatientById.pending, (state) => {
      console.log("pending")

      state.isFetching = false
      state.isError = true
    })
    builder.addCase(fetchPatientById.fulfilled, (state, action) => {
      const { result } = action.payload
      state.patientData = new PatientData().Deserialize(result)
      state.isSuccess = true
      state.isFetching = false
    })
    builder.addCase(fetchPatientById.rejected, (state) => {
      console.log("rejected")

      state.isFetching = false
      state.isError = true
    })
    builder.addCase(fetchCaseByCaseId.pending, (state) => {
      console.log("pending")

      state.isFetching = false
      state.isError = true
    })
    builder.addCase(fetchCaseByCaseId.fulfilled, (state, action) => {
      const { result } = action.payload
      state.caseDetails = { ...result }
      state.isSuccess = true
      state.isFetching = false
    })
    builder.addCase(fetchCaseByCaseId.rejected, (state) => {
      console.log("rejected")

      state.isFetching = false
      state.isError = true
    })
  },
})

export const { setData } = patientServiceSlice.actions
