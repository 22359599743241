import { FC } from "react"
import { I18nextProvider, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import { Security } from "@okta/okta-react"

import packageJson from "../package.json"

import { useAnalytics } from "./core/app/customHooks"
import i18nConfig from "./core/config/i18n.config"
import oktaAuthClient from "./core/config/okta.config"
import AnalyticsWrapper from "./AnalyticsWrapper"
import AppRoutes from "./routes"
import { UsmileThemeProvider } from "./ui-component"

import "./App.scss"
const App: FC = () => {
  const initialized = useAnalytics()
  const navigate = useNavigate()
  const { ready } = useTranslation("common")

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin))
  }
  const onAuthRequired = () => {
    navigate("auth/login", { replace: true })
  }

  console.log("...VERSION: ", packageJson.version)

  return (
    <UsmileThemeProvider>
      <Security
        oktaAuth={oktaAuthClient}
        restoreOriginalUri={restoreOriginalUri}
        onAuthRequired={onAuthRequired}
      >
        <div className={"App"} data-testid={"app"}>
          <AnalyticsWrapper initialized={initialized}>
            {ready && (
              <I18nextProvider i18n={i18nConfig}>
                <AppRoutes />
              </I18nextProvider>
            )}
          </AnalyticsWrapper>
        </div>
      </Security>
    </UsmileThemeProvider>
  )
}

export default App
