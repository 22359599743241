import React from "react"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { UButton, ULoading, UText } from "../../../components"
import { Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { fetchPatientList } from "../../../core/app/slices/patients"
import { RootState } from "../../../core/app/store"

import UTable from "./components/UTable/UTable"
import { tableData } from "./patientTable/table.config"
import { UFlowPatientList } from "../../../wasm3d/CommonPages/UFlowPatientList"

const PatientList: FC = () => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const { patients, loading, paging } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { t } = useTranslation("common")
  const navigate = useNavigate()

  //TODO: loader testing  we can remove later
  useEffect(() => {
    const x = setTimeout(() => {
      clearTimeout(x)
      setLoading(false)
    }, 10000)
  })

  const onRowClick = (row) => {
    navigate(`/clinical/patient/${row.id}/case/27612/treat`)
  }

  const onSearch = (value: string) => {
    dispatch(
      fetchPatientList({
        patientQueryparams: {
          page: 1,
          page_size: 20,
          search: value,
        },
      }),
    )
  }

  return (
    <>
      <ULoading isLoading={isLoading} />
      <Grid container item sx={{ mt: 3 }}>
        <Grid item md={6}>
          <UText variant={"h4"}>{"Patients"}</UText>
        </Grid>
        <Grid item md={6} display={"flex"} justifyContent={"end"}>
          <UButton
            variant={"contained"}
            btnType={"button"}
            btnText={t("records.button.addNewPatient")}
            onClickHandler={() => {
              navigate("/patients/new")
            }}
          />
        </Grid>
      </Grid>
      <UFlowPatientList />
      <Grid item container justifyContent={"center"}>
        <UTable
          columnData={patients}
          columnDef={tableData.columnDef}
          name={"Patient List"}
          onRowClick={onRowClick}
          isFetching={loading}
          paging={paging}
          enableSearch={true}
          onSearch={onSearch}
        ></UTable>
      </Grid>
    </>
  )
}

export default PatientList
