import OktaAuth, {
  AuthState,
  EVENT_EXPIRED,
  OktaAuthCoreInterface,
} from "@okta/okta-auth-js"

import { updateLoggedInUser } from "../app/slices/user/userSlice"
import store from "../app/store"
import { setAccessToken } from "../global/interceptors"

const REDIRECT_URI = `${window.location.origin}/login/callback`

const { VITE_OKTA_ISSUER, VITE_OKTA_CLIENT_ID } = import.meta.env

// eslint-disable-next-line import/no-anonymous-default-export
const oktaConfig = {
  clientId: VITE_OKTA_CLIENT_ID,
  issuer: VITE_OKTA_ISSUER, // REACT_APP_OKTA_BASE_URL,
  redirectUri: REDIRECT_URI,
  postLogoutRedirectUri: window.location.origin,
  scopes: ["openid", "profile", "email"],
  pkce: true,
  maxAge: 10,
  autoRemove: true,
  cookies: {
    secure: false,
  },
  tokenManager: {
    autoRenew: true,
    autoRemove: true,
  },
  transformAuthState: async (
    oktaAuth: OktaAuthCoreInterface,
    authState: AuthState,
  ) => {
    if (!authState.isAuthenticated) {
      console.log("transformAuthState", authState.isAuthenticated)
    }
    authState.isAuthenticated = !!authState.accessToken
    return authState
  },
}

const oktaAuthClient = new OktaAuth(oktaConfig)

oktaAuthClient.tokenManager.on("renewed", function (key, newToken) {
  console.log(`Renewed token with key ${key}`, newToken)
})

oktaAuthClient.authStateManager.subscribe((authState) => {
  console.debug("auth.js authStateManager subscription", authState)
  setAccessToken(() => authState?.accessToken?.accessToken || "")
  store.dispatch(
    updateLoggedInUser({
      ...authState?.accessToken,
    }),
  )
})

if (!oktaAuthClient.isLoginRedirect()) {
  // Trigger an initial authState change event when the app startup
  oktaAuthClient.authStateManager.updateAuthState()
}

oktaAuthClient.tokenManager.on(EVENT_EXPIRED, (key) => {
  const existingToken = oktaAuthClient.tokenManager.get(key)
  //for debugging purposes
  console.log("auth.js: authClient expiration event", existingToken)
})

export default oktaAuthClient
