import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button } from "@mui/material"

import Drag from "../../../../components/Drag/Drag"
import { number } from "../../../Uassist/DentalPortrait/toothObject"

import { emptyData, lowTmp, topConfig, upTmp } from "./columns"

import "./boltonPanel.scss"
const BoltonPanel: FC = ({ data, setBoltonPanelShow }) => {
  const { t } = useTranslation("common")
  const [topData, setTopData] = useState([
    { percent: "", size: "" },
    { percent: "", size: "" },
  ])
  const [upData, setUpData] = useState(emptyData)
  const [downData, setDownData] = useState(emptyData)
  useEffect(() => {
    if (data) {
      const {
        boltonIndex3_3,
        diffience3_3,
        boltonIndex6_6,
        diffience6_6,
        boltonData,
      } = data
      const percent_3 = `${boltonIndex3_3}%`
      const percent_6 = `${boltonIndex6_6}%`
      const size_3 = `${diffience3_3} mm`
      const size_6 = `${diffience6_6} mm`
      setTopData([
        { percent: percent_3, size: size_3 },
        { percent: percent_6, size: size_6 },
      ])
      // The id corresponds to the name of the relationship, to facilitate the later debugger
      // const tmp = []
      // for (let i = 1; i < 33; i++) {
      //   const obj = {}
      //   const name = getName(i)
      //   obj.name = name
      //   obj.id = i
      //   tmp.push(obj)
      // }
      //up
      const UR1 = boltonData.get(8) ? boltonData.get(8) : 0
      const UL1 = boltonData.get(9) ? boltonData.get(9) : 0
      const UR2 = boltonData.get(7) ? boltonData.get(7) : 0
      const UL2 = boltonData.get(10) ? boltonData.get(10) : 0
      const UR3 = boltonData.get(6) ? boltonData.get(6) : 0
      const UL3 = boltonData.get(11) ? boltonData.get(11) : 0
      const UR4 = boltonData.get(5) ? boltonData.get(5) : 0
      const UL4 = boltonData.get(12) ? boltonData.get(12) : 0
      const UR5 = boltonData.get(4) ? boltonData.get(4) : 0
      const UL5 = boltonData.get(13) ? boltonData.get(13) : 0
      const UR6 = boltonData.get(3) ? boltonData.get(3) : 0
      const UL6 = boltonData.get(14) ? boltonData.get(14) : 0
      //down
      const LR1 = boltonData.get(25) ? boltonData.get(25) : 0
      const LL1 = boltonData.get(24) ? boltonData.get(24) : 0
      const LR2 = boltonData.get(26) ? boltonData.get(26) : 0
      const LL2 = boltonData.get(23) ? boltonData.get(23) : 0
      const LR3 = boltonData.get(27) ? boltonData.get(27) : 0
      const LL3 = boltonData.get(22) ? boltonData.get(22) : 0
      const LR4 = boltonData.get(28) ? boltonData.get(28) : 0
      const LL4 = boltonData.get(21) ? boltonData.get(21) : 0
      const LR5 = boltonData.get(29) ? boltonData.get(29) : 0
      const LL5 = boltonData.get(20) ? boltonData.get(20) : 0
      const LR6 = boltonData.get(30) ? boltonData.get(30) : 0
      const LL6 = boltonData.get(19) ? boltonData.get(19) : 0
      const upTmp = [
        [UR1, UL1],
        [UR2, UL2],
        [UR3, UL3],
        [UR4, UL4],
        [UR5, UL5],
        [UR6, UL6],
      ]
      const downTmp = [
        [LR1, LL1],
        [LR2, LL2],
        [LR3, LL3],
        [LR4, LL4],
        [LR5, LL5],
        [LR6, LL6],
      ]
      setUpData(upTmp)
      setDownData(downTmp)
    }
  }, [data])
  return (
    <Drag
      DragSize={{ width: 390, height: 630 }}
      DragPosition={{
        x: document.body.clientWidth - 390,
        y: 0,
      }}
      // bounds={".treatMain"}
    >
      <Box className="boltonContainer">
        <div className="toolbar">
          <Button
            onClick={() => {
              setBoltonPanelShow(false)
            }}
            className="close"
          />
        </div>
        <div className={"boltonTop"}>
          <div className={"lineBox"}>
            <div className={"left"}></div>
            <div className={"right"}>
              <div className={"percent"}>{t("treat.boltonAnalysis")}</div>
              <div className={"size"}>{t("treat.boltonSize")}</div>
            </div>
          </div>
          {topConfig.map((i, j) => {
            return (
              <div key={i.key} className={"lineBox"}>
                <div className={"left"}>{i.titleRow}</div>
                <div className={"right"}>
                  <div className={"percent percentV"}>{topData[j].percent}</div>
                  <div className={"size sizeV"}>{topData[j].size}</div>
                </div>
              </div>
            )
          })}
        </div>
        <div className={"titles"}>
          <div className={"label"}>{t("treat.toothID")}</div>
          <div className={"label"}>{t("treat.R")}</div>
          <div className={"label"}>{t("treat.L")}</div>
        </div>
        <div className={"boltonBox"}>
          {upTmp.map((i, j) => {
            return (
              <div key={i.key} className={"lineWrap"}>
                <div className={"lineValue"}>{i.label}</div>
                <div className={"lineValue"}>{upData[j][0]}</div>
                <div className={"lineValue"}>{upData[j][1]}</div>
              </div>
            )
          })}
        </div>
        <div className={"driver"}></div>
        <div className={"boltonBox"}>
          {lowTmp.map((i, j) => {
            return (
              <div key={i.key} className={"lineWrap"}>
                <div className={"lineValue"}>{i.label}</div>
                <div className={"lineValue"}>{downData[j][0]}</div>
                <div className={"lineValue"}>{downData[j][1]}</div>
              </div>
            )
          })}
        </div>
      </Box>
    </Drag>
  )
}
export default BoltonPanel
