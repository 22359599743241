import { FC, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import { FormAutoComplete, UButton, UText } from "../../components"
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "../../components/mui.components"
import { useAppSelector } from "../../core/app/hooks"
import { useAppDispatch } from "../../core/app/hooks"
import { updateCase } from "../../core/app/slices/patients/patientThunkApi"
import { RootState } from "../../core/app/store"
import { CENETER_COLUMN_ALIGN } from "../../theme/theme.util"

import Photograph from "./Photograph/Photograph"
import Scans from "./Scans/Scans"
import Xrays from "./Xrays/Xray"

export interface IRecordRoutesProps {
  showError: boolean
  xrayErrors: boolean
  scanErrors: boolean
  photographErrors: boolean
  setScanErrors: (value: boolean) => void
  setPhotographErrors: (value: boolean) => void
  setXrayErrors: (value: boolean) => void
  flagNextBtn: boolean
  steps: { id: string; lable: string; to: string }[]
}

const RecordRoutes: FC<IRecordRoutesProps> = ({
  showError,
  xrayErrors,
  scanErrors,
  photographErrors,
  setScanErrors,
  setPhotographErrors,
  setXrayErrors,
  flagNextBtn,
  steps,
}) => {
  return (
    <Routes>
      <Route
        path={"scans"}
        element={
          <Scans
            setScanErrors={setScanErrors}
            flagClickNext={flagNextBtn}
            steps={steps}
          />
        }
      />
      <Route
        path={"photograph"}
        element={<Photograph setPhotographErrors={setPhotographErrors} />}
      />
      <Route
        path={"xray"}
        element={
          <Xrays
            showError={showError}
            scanErrors={scanErrors}
            photographErrors={photographErrors}
            xrayErrors={xrayErrors}
            setXrayErrors={setXrayErrors}
          />
        }
      />
      <Route path={"*"} element={<Navigate to={"scans"} replace />} />
    </Routes>
  )
}

const RecordModule: FC = () => {
  const { patientId, caseId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()

  const steps = [
    {
      id: "scans",
      label: "Scans",
      to: `/records/patient/${patientId}/case/${caseId}/scans`,
    },
    {
      id: "photographs",
      label: "Photographs",
      to: `/records/patient/${patientId}/case/${caseId}/photograph`,
    },
    {
      id: "x-rays",
      label: "X-Rays",
      to: `/records/patient/${patientId}/case/${caseId}/xray`,
    },
  ]
  const [activeStep, setActiveStep] = useState<number>(0)
  const { loading } = useAppSelector((state: RootState) => state.PhotosService)
  const { loading: xrayLoading } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const [completed, setCompleted] = useState<{
    [k: number]: boolean
  }>({})
  const [skipped, setSkipped] = useState(new Set<number>())

  const [flagNextBtn, setFlagNextBtn] = useState()

  const location = useLocation()
  useEffect(() => {
    setFlagNextBtn(undefined)
    location?.pathname.includes("/scans")
      ? setActiveStep(0)
      : location?.pathname.includes("/photograph")
      ? setActiveStep(1)
      : location?.pathname.includes("/xray")
      ? setActiveStep(2)
      : setActiveStep(0)
  }, [location])

  const [scanErrors, setScanErrors] = useState<boolean>(false)
  const [photographErrors, setPhotographErrors] = useState<boolean>(true)
  const [xrayErrors, setXrayErrors] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  const {
    control,
    formState: { errors },
    watch,
  } = useForm()

  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )

  const { caseDetails, patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const formValue = watch("doctor", caseDetails.assigned_to)

  const formLabel = doctorList?.find((list) => list.id === formValue)

  useEffect(() => {
    if (caseDetails.assigned_to === formValue) return
    dispatch(
      updateCase({
        patientId: patientId,
        caseId: caseId,
        payload: {
          assigned_to: formValue,
        },
      }),
    )
  }, [formValue])

  const isStepOptional = (step: number) => {
    if (scanErrors && step === 0) {
      return true
    }
    if (photographErrors && step === 1) {
      return true
    }
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    if (activeStep === 2) {
      handleError()
    } else {
      let newSkipped = skipped
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values())
        newSkipped.delete(activeStep)
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      setSkipped(newSkipped)
      navigate(steps[activeStep + 1].to)
    }
  }

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/patients/new")
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
      navigate(steps[activeStep - 1].to)
    }
  }

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    navigate(steps[activeStep + 1].to)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const isStepFailed = (step: number) => {
    if (showError) {
      if (photographErrors && step === 1) {
        return true
      }
      if (scanErrors && step === 0) {
        return true
      }
      if (xrayErrors && step === 2) {
        return true
      }
    }
  }

  const handleError = () => {
    if (photographErrors || scanErrors || xrayErrors) {
      setShowError(true)
    }
  }

  useEffect(() => {
    if (!photographErrors && !scanErrors && !xrayErrors) {
      setShowError(false)
    }
  }, [photographErrors, scanErrors, xrayErrors])

  return (
    <>
      <Grid container sx={{ mt: 2, py: 2 }}>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginBottom: "8px",
          }}
        >
          <Grid item sm={6} display={"flex"}>
            <UText variant={"h5"}>
              {patientData?.firstName + " " + patientData?.lastName}
            </UText>
          </Grid>
          <Grid item sm={6} display={"flex"} justifyContent={"end"}>
            <UButton
              variant={"shade"}
              btnType={"button"}
              btnText={t("button.cancel")}
              onClickHandler={() => {
                navigate("/patients")
              }}
              sxProp={{
                fontSize: "13px",
                padding: "4px 10px",
                height: "unset",
              }}
            />
          </Grid>
        </Box>
        <Box
          component={"div"}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Chip
            label={t("records.description")}
            sx={{
              background: "#0288D1",
              fontSize: "13px",
              fontWeight: "400px",
              color: "primary.contrastText",
              padding: " 2px 4px",
              borderRadius: "50px",
              lineHeight: "18px",
              letterSpacing: "0.16px",
              whiteSpace: "nowrap",
              height: "unset",
            }}
          />
          <Box
            component="div"
            sx={{
              borderLeft: "1px solid rgba(0, 0, 0, 0.12);",
              height: "20px",
              mx: 1,
            }}
          ></Box>
          {formLabel?.label && (
            <>
              <Box
                component={"div"}
                sx={{
                  ...CENETER_COLUMN_ALIGN,
                  height: "24px",
                  width: "50px",
                  backgroundColor: "success.dark",
                  borderRadius: "50px",
                  marginRight: "8px",
                  fontSize: "10px",
                  justifyContent: "center",
                  fontWeight: 500,
                  color: "#ffffff",
                }}
              >
                {formLabel?.label
                  .charAt(0)
                  .concat(formLabel?.label.split(" ")[1].charAt(0))
                  .toUpperCase()}
              </Box>
              <FormAutoComplete
                inputLabel={"formfields.alldoctors"}
                fieldName={"doctor"}
                rules={{
                  required: "formfieldErrors.assigntorequired",
                }}
                defaultValue={
                  doctorList?.find(
                    (list) => list.id === caseDetails.assigned_to,
                  )?.label
                }
                options={doctorList}
                errors={false}
                control={control}
                formSxprops={{ my: 0, display: "block" }}
                customPopupIcon={<KeyboardArrowDownIcon fontSize={"medium"} />}
                sxProp={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0 !important",
                    width: "200px",
                  },
                  "& .MuiOutlinedInput-root:hover:not(.Mui-focused )": {
                    "& fieldset": {
                      border: "none !important",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      display: "inline-flex",
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      marginRight: "-10px",
                    },
                  },
                  "& .MuiOutlinedInput-root:focus-within": {
                    "& .MuiAutocomplete-popupIndicator": {
                      display: "inline-flex !important",
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                      marginRight: "-10px",
                    },
                  },
                  "& input": {
                    padding: "0 !important",
                    fontSize: "14px",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    display: "none",
                  },
                  "& svg": {
                    width: "18px",
                    height: "18px",
                  },
                  "& fieldset": {
                    border: "none !important",
                    padding: "0 !important",
                  },
                  "& .MuiFormControl-root": {
                    display: "block",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    display: "none",
                  },
                }}
              />
            </>
          )}
        </Box>
      </Grid>
      <Container component="main" maxWidth={"lg"} sx={{ mb: 4 }}>
        <UText
          sxProp={{ display: "flex", justifyContent: "center" }}
          variant={"h4"}
        >
          {t("records.title")}
        </UText>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ pt: 2, pb: 3 }}>
          {steps.map((s, index) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
              error?: boolean
            } = {}
            if (showError && isStepFailed(index)) {
              labelProps.error = true
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }

            return (
              <Step key={s.label} {...stepProps}>
                <StepLabel
                  icon={
                    labelProps.error && (
                      <ErrorOutlinedIcon sx={{ color: "error.main" }} />
                    )
                  }
                  {...labelProps}
                >
                  {s.label}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Paper
          elevation={0}
          sx={{
            my: { xs: 3, md: 6 },
            p: "40px",
            borderRadius: "16px",
            marginTop: "10px !important",
            margin: "0 auto",
            width: "max-content",
          }}
        >
          <RecordRoutes
            xrayErrors={xrayErrors}
            scanErrors={scanErrors}
            photographErrors={photographErrors}
            showError={showError}
            setScanErrors={setScanErrors}
            setPhotographErrors={setPhotographErrors}
            setXrayErrors={setXrayErrors}
            flagNextBtn={flagNextBtn}
            steps={steps}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 1,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleBack}
              sx={{ width: "96px", height: "36px" }}
            >
              <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={() => {
                if (activeStep === 0) setFlagNextBtn(!flagNextBtn)
                else {
                  if (isStepOptional(activeStep)) handleSkip()
                  else handleNext()
                }
              }}
              variant="contained"
              sx={{ width: "92px", height: "36px" }}
              disabled={loading === "pending" || xrayLoading === "pending"}
            >
              Next
              <ChevronRightIcon sx={{ marginLeft: "3px" }} fontSize={"small"} />
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default RecordModule
