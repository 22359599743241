import { attachmentModule } from "../attachment/attachmentModule";
import { report } from "../report/report";
import { stagingManager } from "../stagingdatamanager/stagingmanager";
import { trimToolModule } from "../trimtools/trimtool";

import * as popupHelper from "../../common/popuphelper";
import { globalEvents, GlobalEvents } from "../../../utils/globalevents";

import { faccAndEdgeEditor } from "../../modules/toothcorrections/faccedge";
import { IAttachmentIPRVisible } from "../../modules/attachment/attachmentModule";
import "../../modules/toothcorrections/toothcorrectiondispatcher";
import { resourceManager } from "../resourcemanager/resourcemgr";
import { resourcesSynchronization } from "../resourcemanager/resourcessynchronization";
import { sagemakerModule } from "../sagemaker/sagemakerModule";
import type { ESetupType } from "../../typesofinterface";
import { iprModule } from "../ipr/iprModule";
import { Event } from "../../../utils/event";
import { gdsPlusSetup } from "../setupmode/gdsPlus";
// import { TemporaryTest } from "../../temporarytest";
import { IWASMStateController, IWASMStageController } from "../../common/types";

/**
 * init function for WASM
 * return the wasm instance module
 */
declare let datamodel: (wasmoptions: any) => Promise<void>;

export const enum WASMLifeCircleEventType {
  LoadWasmFinished = "LoadWasmFinished",
  InitFinished = "InitFinished",
  CloseCase = "CloseCase",
  SetUpFinished = "SetUpFinished",
  BeforeSetUpFinish = "BeforeSetUpFinish",
  NonePresetUp = "NonePresetUp",
}

export const enum StageEventType {
  UPDATESTAGE_EVENT = "UpdateStage",
}

class WasmModule {
  private _wrapInstance: any = {};
  private _module: any = undefined;
  private _ulabwinIns: any = undefined;
  private _statusController;
  private _stagingcontoler;
  private _mouthModel;
  private _moduleManager;
  private _isWasmInit = false;
  private _isOpenCaseReady = false;
  moduleOpenCaseReady?: () => void;
  calsetupfinishCallback?: () => void;
  private _event: Event = new Event();

  get event() {
    return this._event;
  }

  public isPresetup = false;
  public caseType: ESetupType = 8;
  public versionType = 0;

  get caseSetupType() {
    return this.caseType;
  }
  set caseSetupType(type) {
    this.caseType = type;
  }
  get mouthModel() {
    return this._mouthModel;
  }

  get stagingcontoler(): IWASMStageController {
    return this._stagingcontoler;
  }

  get ulabwinIns() {
    return this._ulabwinIns;
  }

  get wrapInstance() {
    return this._wrapInstance;
  }

  get statusController(): IWASMStateController {
    return this._statusController;
  }

  get module() {
    return this._module;
  }

  get isInit() {
    return this._isOpenCaseReady;
  }

  get moduleManager() {
    return this._moduleManager;
  }

  get isWASMInit() {
    return this._isWasmInit;
  }

  delete() {
    if (!this._isOpenCaseReady) return;
    resourcesSynchronization.fsUnlink();

    if (this.module && this.module.cancel_em_loop) {
      this.module.cancel_em_loop();
    }

    // this._module.getULabWinIns().delete();
    this._module.getWrapperIns().delete();

    console.log("canvas :", this.module.canvas);
    this.module.canvas = null;
    // this._module = null;
    this._isOpenCaseReady = false;
    this.event.fire(WASMLifeCircleEventType.CloseCase);
    // resourcesSynchronization.ismkdirdone = false;
  }

  setWasmModule(inst: any) {
    this._module = inst;
    this._isWasmInit = true;
  }

  initCallback() {
    (window as any).initFinishedCallback = this.initFinishedCallback.bind(this);
    (window as any).updateStagesCallback = this.updateStagesCallback.bind(this);
    (window as any).dialogCallback = this.dialogCallback.bind(this);
    (window as any).onSelectAttachment =
      this.onSelectAttachmentCallback.bind(this);
    (window as any).onDeleteAttachment =
      this.onDeleteAttachmentCallback.bind(this);
    (window as any).toothNotAllowMoveCallback =
      this.toothNotAllowMoveCallback.bind(this);
    (window as any).onTipPopup = this.onTipPopup.bind(this);
    (window as any).changeArchType = this.changeArchType.bind(this);
    (window as any).faccBtnStateCallback =
      faccAndEdgeEditor.showEditBtn.bind(faccAndEdgeEditor);
    (window as any).onResetAttachmentSelectButton =
      this.onResetAttachmentButtonCallback.bind(this);
  }

  /**
   * only way of init WASM
   * @param canvas
   * @param callback
   */
  async initWASM(canvas, callback) {
    // if not init module do it else change canvas only
    if (!this.module) {
      await this.wasmInit(canvas, async () => {
        if (resourceManager.getResourceStatic().size === 0) {
          await resourceManager.downloadStaticResource();
        }
        const writestaticFileList =
          await resourcesSynchronization.getStaticResourceDirectory();
        await resourcesSynchronization.moduleFS(writestaticFileList);
        this.initCallback();
        sagemakerModule.initCallback();
        if (callback) {
          await callback(this.module);
        }
      });
    } else if (this._module) {
      this._module.canvas = this.webglcontextlost(canvas);
      if (callback) {
        await callback(this.module);
      }
    }
  }

  async wasmInit(canvasRef, action) {
    const modell = {
      canvas: this.webglcontextlost(canvasRef), // canvas and
      arguments: ["/test"], // All udesign files are stored in test folder.
      mainScriptUrlOrBlob: "UDesignCommonDataModel.js", // Set correct js file.
      // print: () => {}, // disable printf and std::cout for C++
      printErr: this.logErr.bind(this), // show only error
      onRuntimeInitialized: function () {
        console.log("initialized");
      },
    };

    if (this._module) {
      this._module.canvas = modell.canvas;
      await action();
    } else {
      this._module = await datamodel(modell); // await datamodel initialized
      this._isWasmInit = true;
      await action();
    }
  }

  onTipPopup(title: string, content: string) {
    popupHelper.alertPopup("info", content, [
      {
        name: "ok",
        func: () => {},
      },
    ]);
  }

  bindEvents() {
    globalEvents.on(GlobalEvents.ON_ATTACHMENT_BUTTON_CLICKED, isOpen => {
      // 增加attachment之前得开启功能 结束关闭功能
      console.log(">>>ON_ATTACHMENT_BUTTON_CLICKED", isOpen);
      attachmentModule.openAttachmentModule(isOpen);
    });
    globalEvents.on(GlobalEvents.ON_TRIMING_CLICKED, isOpen => {
      console.log(">>>ON_TRIMING_BUTTON_CLICKED", isOpen);
      let ret;
      if (isOpen) {
        ret = trimToolModule?.start(isOpen);
      } else {
        ret = trimToolModule.close();
      }

      console.log("ret:::1", ret);
    });

    globalEvents.on(GlobalEvents.ON_TRIMING_MODE_CHANGED, isTrimLine => {
      // console.log('>>>ON_TRIMING_MODE_CHANGED', isTrimLine);
      let ret;
      if (isTrimLine) {
        trimToolModule.close();
        ret = trimToolModule?.start(true);
      } else {
        trimToolModule.close();
        ret = trimToolModule?.startSurface(true);
      }

      console.log("ret:::2", ret);
    });
  }

  /**
   * WASM 控制上下颌显示状态
   */
  changeArchType() {}

  /**
   * for connect wasm err function
   * @param msg
   */
  logErr(msg: string) {
    console.warn(msg);
  }

  onSelectAttachmentCallback() {
    attachmentModule.onSelectedAttachment();
  }

  onDeleteAttachmentCallback(title, info) {
    console.log("onDeleteAttachmentCallback");
    attachmentModule.deleteSelectedAttachment();
  }

  onResetAttachmentButtonCallback(title, info) {
    attachmentModule.onResetAttachmentSelectButton();
  }
  getWasmObj() {
    return {
      _ulabwinIns: this._ulabwinIns,
      _wrapInstance: this._wrapInstance,
    };
  }

  /**
   * MouthModel模块初始化
   */
  initFinishedCallback() {
    this._wrapInstance = this._module.getWrapperIns();
    this._ulabwinIns = this._module.getULabWinIns();
    this._mouthModel = this._ulabwinIns.getMouthModelIns();
    this._statusController = this._ulabwinIns.getStatusController();
    this._stagingcontoler = this._ulabwinIns.getStageContoller();
    this._moduleManager = this._ulabwinIns.getModuleManager();
    this._isOpenCaseReady = true;
    console.log("1111 _module :", this._module);
    console.log("1111 _wrapInstance :", this._wrapInstance);
    console.log("1111 _ulabwinIns :", this._ulabwinIns);
    console.log("1111 _mouthModel :", this._mouthModel);
    console.log("1111 _statusController :", this._statusController);
    console.log("1111 _stagingcontoler :", this._stagingcontoler);
    console.log("1111 _moduleManager :", this._moduleManager);
    this._statusController.SetToAnteriorView();
    stagingManager.initFinishedupdateStageCallback();
    stagingManager.patientsSetStageDataTx();
    report.update();
    this.bindEvents();
    trimToolModule.initModule();

    attachmentModule.openAttachmentModule(true);

    if (this.moduleOpenCaseReady) this.moduleOpenCaseReady();

    this._event.fire(WASMLifeCircleEventType.InitFinished);

    if (!this.isPresetup) {
      if (this.calsetupfinishCallback) this.calsetupfinishCallback();
      this._event.fire(WASMLifeCircleEventType.NonePresetUp);
    } else {
      this.runSetup();
      if (this.calsetupfinishCallback) this.calsetupfinishCallback();
    }
  }

  // run setup process: 1.SetupCOPAAuto  2.AttachAttachmentsAutomatically
  //                  3.onEndCopaDemoCall()  4.SaveAutoSetUpResult()
  runSetup() {
    console.log("start run setup");
    // ----------1.SetupCOPAAuto--------
    gdsPlusSetup.SwitchAutoSetupModule(true);
    const autosetup = gdsPlusSetup.getAutoSetupModule();
    this.caseType = autosetup.COPAAuto();
    gdsPlusSetup.SwitchAutoSetupModule(false);
    this._event.fire(WASMLifeCircleEventType.BeforeSetUpFinish);
    let isHasAttachment = false;
    if (this.caseType === 9) {
      // ----------2.AttachAttachmentsAutomatically--------
      this.moduleManager.SwitchAttachmentModule(true);
      const moduelAttachment = this.moduleManager.GetAttachmentModule();
      if (moduelAttachment) {
        moduelAttachment.CleanAllAttachment();
        for (let i = 0; i < 2; i++) {
          moduelAttachment.AddAllAttachment(i);
        }

        this.moduleManager.SwitchAttachmentModule(false);
      }
      isHasAttachment = true;
    }

    this._event.fire(WASMLifeCircleEventType.SetUpFinished);
    // ----------3.SaveAutoSetUpResult()--------
    this._stagingcontoler.SaveAutoSetUpResult();
    console.log("setup finished");
    // ----------4.onEndCopaDemoCall()--------
    this.setEndMessageCallback(isHasAttachment ? 1 : 0, this.caseType); // (old,Replace：setCompleteAttachmentsAutomaticallyCallback)
    // 仅用于QA临时测试
    // const temporaryTest = new TemporaryTest();
  }
  webglcontextlost(canvasRef) {
    canvasRef.addEventListener(
      "webglcontextlost",
      this.contextlostprevent,
      false
    );
    canvasRef.addEventListener(
      "mouseover",
      this.webglMouseOver(canvasRef),
      false
    );
    return canvasRef;
  }
  webglMouseOver(canvasRef) {
    canvasRef.focus();
  }
  contextlostprevent(e) {
    console.error("WebGL context lost. You will need to reload the page.");
    e.preventDefault();
  }
  /**
   *
   * 销毁
   */
  remove() {
    console.log("delete: ");
    // disPatchPanelDataCallback(false, true);
    if (this._module) this._module.cancel_em_loop();
    resourcesSynchronization.fsUnlink();
    if (this._module.canvas)
      this._module.canvas.removeEventListener(
        "webglcontextlost",
        this.contextlostprevent,
        false
      );
    if (this._module.canvas)
      this._module.canvas.removeEventListener(
        "mouseover",
        this.webglMouseOver,
        false
      );
    this._mouthModel = null;
    this._module = null;
    this._isOpenCaseReady = false;
  }

  dialogCallback(show: boolean) {
    // disPatchPanelDataCallback(show, true);
  }
  // async playStageCallback(curStep) {
  //   console.log('curStep=' + curStep);
  // }
  /**
   * 获取wasm内当前Stage数据
   * @param steps0
   * @param steps1
   *   */
  async updateStagesCallback(arch: number, index: number) {
    // const toothIds = this._mouthModel.GetArchModel(this._module.ArchType.UpArch).GetToothIds();
    // const values = this._ulabwinIns.GetReportResult(this._module.ArchType.UpArch, 0);
    // const size = toothIds.size();
    // const allReportArrays: any[] = new Array<any>();
    // for (let i = 0; i < size; i++) {
    //   const value: any = {};
    //   value.id = toothIds.get(i);
    //   const distanceRotate: number[] = [];
    //   for (let l = 0; l < 6; l++) {
    //     distanceRotate[l] = values.get(i * 6 + l);
    //   }
    //   value.distanceRotate = distanceRotate;
    //   console.log('arch0: ', value.id, distanceRotate);
    //   allReportArrays[value.id] = value;
    // }
    console.log("updateStagesCallback", arch, index);
    stagingManager.updateStageCallback(arch, index);
    report.update();
    // disPatchPanelDataCallback();
    iprModule.updateIPRVisibility();
    this._event.fire(StageEventType.UPDATESTAGE_EVENT);
  }

  toothNotAllowMoveCallback() {
    // toothMovementwarningHelpSinTips();
  }

  // setEndallCallback() {
  //   (window as any).setupReady = {};
  //   (window as any).setupReady.endAllCallback = this.setEndMessageCallback.bind(this);
  //   if (this.module.getUNewPreSetUpWin()) this.module.getUNewPreSetUpWin().EndCopaDemoCallCB('setupReady.endAllCallback');
  // }
  setEndMessageCallback(show: number, type: number) {
    let isVisible = false;
    if (show === 1) isVisible = true;

    if (stagingManager.setupTypeAndStage)
      stagingManager.setupTypeAndStage(
        type,
        stagingManager.wasmStageData.keypointsIndex
      );
    const visible: IAttachmentIPRVisible = {
      isHasAttachmentData: isVisible,
      isHasIPRData: this._statusController.HasCollision(),
    };
    if (attachmentModule.attachmentiprVisibility)
      attachmentModule.attachmentiprVisibility(visible);
  }

  // toothMoveTypesCallback() {
  //   this._stagingcontoler.SetCanMoveTooth(stagingManager.isStageIndexToothMoveTypes(isUpTooth(panelData.toothId) ? 0 : 1));
  // }

  cancelemloop() {
    if (this.module && this.module.cancel_em_loop) {
      this.module.cancel_em_loop();
    }
  }
}
export const wasmModule = new WasmModule();
