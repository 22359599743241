import * as React from "react"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  Container,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material"

import downSvg from "../../../../assets/images/clinical/downDirect.svg"
import initalSvg from "../../../../assets/images/clinical/initalBtn.svg"
import movementSvg from "../../../../assets/images/clinical/movementBtn.svg"
import nextSvg from "../../../../assets/images/clinical/next.svg"
import playSvg from "../../../../assets/images/clinical/play.svg"
import previousSvg from "../../../../assets/images/clinical/previous.svg"
import reportSvg from "../../../../assets/images/clinical/reportBtn.svg"
import sideSvg from "../../../../assets/images/clinical/sideBySide.svg"
import stopSvg from "../../../../assets/images/clinical/stop.svg"
import tabSvg from "../../../../assets/images/clinical/tabBtn.svg"
import upSvg from "../../../../assets/images/clinical/upDirect.svg"
import StageBar from "../StageBar/stageBar"

import { toggleProps } from "./type"

import "./toggle.scss"
// timer
let timer: string | number | NodeJS.Timeout | undefined
interface BtnObj {
  isBool: boolean
  type: string
}
const Toggle: FC<toggleProps> = ({ setReportShow, reportShow }) => {
  const { t } = useTranslation("common")
  // 是否折叠,默认折叠状态(Control whether to fold or not)
  const [isFold, setIsFold] = useState<boolean>(true)
  // current tx
  const [currentTx, setCurrentTx] = useState<number>(0)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  // control btn status
  const [btnObj, setBtnObj] = useState<BtnObj>({
    isBool: true,
    type: "stop",
  })
  // click tab
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget.parentNode?.parentNode)
  }
  // click btns
  const clickToPlay = (type: string) => {
    if (type === "play") {
      setBtnObj({
        type: btnObj.type !== "play" ? "play" : "stop",
        isBool: !btnObj.isBool,
      })
    } else {
      setBtnObj({
        type,
        isBool: !btnObj.isBool,
      })
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const popoverId = open ? "simple-popover" : undefined
  // click fold
  const foldClick = () => {
    setIsFold(!isFold)
  }
  // 点击折叠按钮(Click the collapse button)
  const startMove = (target: number) => {
    if (!document.getElementsByClassName(`toggle`)[0]) {
      clearInterval(timer)
      return
    }
    clearInterval(timer)
    timer = setInterval(() => {
      let speed = 0
      if (!document.getElementsByClassName(`toggle`)[0]) {
        clearInterval(timer)
        return
      }
      const toggleElement = getComputedStyle(
        document.getElementsByClassName(`toggle`)[0],
      )
      const height = Math.ceil(+toggleElement.height.split("px")[0])
      if (height < target) {
        speed = 1
      } else {
        speed = -1
      }
      if (target === height) {
        clearInterval(timer)
      } else {
        const toggleElement1 = document.getElementsByClassName(
          `toggle`,
        )[0] as HTMLElement
        if (speed > 0) {
          if (height + speed > target) {
            toggleElement1.style.height = `${target}px`
          } else {
            toggleElement1.style.height = `${height + speed}px`
          }
        } else {
          if (height + speed < target) {
            toggleElement1.style.height = `${target}px`
          } else {
            toggleElement1.style.height = `${height + speed}px`
          }
        }
      }
    }, 3)
  }
  useEffect(() => {
    if (!isFold) {
      startMove(212)
    } else {
      startMove(48)
    }
  }, [isFold])
  const menuList = () => {
    return (
      <>
        {/* <ListItemButton
          key={1}
          sx={{
            py: 0,
            minHeight: 32,
            "&:hover": {
              background: "rgba(0,0,0,0.4)",
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: "unset", marginRight: "18px" }}>
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.75 0.833984H1.75004C0.833374 0.833984 0.083374 1.58398 0.083374 2.50065V14.1673H1.75004V2.50065H11.75V0.833984ZM14.25 4.16732H5.08337C4.16671 4.16732 3.41671 4.91732 3.41671 5.83398V17.5006C3.41671 18.4173 4.16671 19.1673 5.08337 19.1673H14.25C15.1667 19.1673 15.9167 18.4173 15.9167 17.5006V5.83398C15.9167 4.91732 15.1667 4.16732 14.25 4.16732ZM14.25 17.5006H5.08337V5.83398H14.25V17.5006Z"
                fill="white"
                fillOpacity="0.56"
              />
            </svg>
          </ListItemIcon>
          <ListItemText
            primary={t("treat.copyText")}
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton> */}
        <ListItemButton
          key={2}
          sx={{
            py: 0,
            minHeight: 32,
            "&:hover": {
              background: "rgba(0,0,0,0.4)",
            },
          }}
        >
          <ListItemIcon
            sx={{ minWidth: "unset", marginRight: "18px", marginLeft: "4px" }}
          >
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.999959 13.8333C0.999959 14.75 1.74996 15.5 2.66663 15.5H9.33329C10.25 15.5 11 14.75 11 13.8333V3.83333H0.999959V13.8333ZM11.8333 1.33333H8.91663L8.08329 0.5H3.91663L3.08329 1.33333H0.166626V3H11.8333V1.33333Z"
                fill="white"
                fillOpacity="0.56"
              />
            </svg>
          </ListItemIcon>
          <ListItemText
            primary={t("treat.deleteText")}
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
        <ListItemButton
          key={3}
          sx={{
            py: 0,
            minHeight: 32,
            "&:hover": {
              background: "rgba(0,0,0,0.4)",
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: "unset", marginRight: "18px" }}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.498901 12.3751V15.5001H3.6239L12.8406 6.28346L9.71557 3.15846L0.498901 12.3751ZM15.2572 3.8668C15.5822 3.5418 15.5822 3.0168 15.2572 2.6918L13.3072 0.741797C12.9822 0.416797 12.4572 0.416797 12.1322 0.741797L10.6072 2.2668L13.7322 5.3918L15.2572 3.8668Z"
                fill="white"
                fillOpacity="0.56"
              />
            </svg>
          </ListItemIcon>
          <ListItemText
            primary={t("treat.renameText")}
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </>
    )
  }
  return (
    <Box id="toggle" className={`toggle ${isFold}`}>
      <Container
        className={"toggleTopWrap"}
        style={{ borderBottom: isFold ? "unset" : "1px solid #455A64" }}
      >
        <div id="left" className={"topLeft"}>
          <Tabs
            value={currentTx}
            onChange={(event, value) => {
              setCurrentTx(value)
            }}
            sx={{
              "& .MuiButtonBase-root": {
                width: "96px",
                padding: "0",
              },
              "& .MuiTab-textColorPrimary": {
                color: "#fff",
                fontSize: "14px",
                fontWeight: "500",
                position: "relative",
              },
              "& .Mui-selected": {
                color: "#90CAF9 !important",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#90CAF9",
              },
            }}
          >
            <Tab
              value={0}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "4px" }}>{"TRMT 1"}</span>
                  <Button
                    className={"tabBtn"}
                    sx={{
                      backgroundImage: `url(${tabSvg})`,
                    }}
                    onClick={handleClick}
                  />
                  <div className={"driver"}></div>
                </div>
              }
            />

            <Tab
              value={1}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "4px" }}>{"TRMT 2"}</span>
                  <Button
                    className={"tabBtn"}
                    sx={{
                      backgroundImage: `url(${tabSvg})`,
                    }}
                    onClick={handleClick}
                  />
                  <div className={"driver"}></div>
                </div>
              }
            />
          </Tabs>
        </div>
        <div id="center" className={"topCenter"}>
          <Button
            data-testid="Backwards"
            sx={{
              backgroundImage: `url(${previousSvg})`,
            }}
            className={"btnCommon"}
            onClick={() => {
              clickToPlay("icStart")
            }}
          />
          <Button
            data-testid="Play"
            className={"btnCommon"}
            sx={{
              backgroundImage:
                btnObj.type !== "play" ? `url(${stopSvg})` : `url(${playSvg})`,
            }}
            onClick={() => {
              clickToPlay("play")
            }}
          ></Button>
          <Button
            data-testid="Forward"
            sx={{
              backgroundImage: `url(${nextSvg})`,
            }}
            className={"btnCommon"}
            onClick={() => {
              clickToPlay("icEnd")
            }}
          />
        </div>
        <Box className={"topRight"}>
          <Button
            data-testid="reportSvg"
            sx={{
              backgroundImage: `url(${reportSvg})`,
            }}
            className={"btnCommon"}
            onClick={() => {
              setReportShow(!reportShow)
            }}
          />
          <Button
            data-testid="movementSvg"
            className={"btnCommon"}
            sx={{
              backgroundImage: `url(${movementSvg})`,
            }}
          ></Button>
          <Button
            data-testid="initalSvg"
            sx={{
              backgroundImage: `url(${initalSvg})`,
            }}
            className={"btnCommon"}
          />
          <Button
            data-testid="sideSvg"
            sx={{
              backgroundImage: `url(${sideSvg})`,
              marginRight: "16px",
            }}
            className={"btnCommon"}
          />
          <div
            className={"toggleBtn"}
            data-testid="fold"
            onClick={() => {
              foldClick()
            }}
            style={{
              backgroundImage: isFold ? `url(${upSvg})` : `url(${downSvg})`,
            }}
          >
            Staging
          </div>
        </Box>
      </Container>
      <Container className={"toggleBottomWrap"}>
        <StageBar btnObj={btnObj} setBtnObj={setBtnObj} isFold={isFold} />
      </Container>
      <Popover
        id={popoverId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            marginLeft: "-20px",
            marginTop: "18px",
            background: "#263238",
          },
        }}
      >
        <div
          style={{
            background: "#263238",
            padding: "12px 0",
            borderRadius: "4px",
          }}
        >
          {menuList()}
        </div>
      </Popover>
    </Box>
  )
}
export default Toggle
