import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { AxiosProgressEvent } from "axios"
import { v4 } from "uuid"

import {
  UButton,
  UFileBox,
  UFileProcessingCard,
  UModalPopup,
} from "../../components"
import { Box, Grid } from "../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { uploadPhotographs } from "../../core/app/slices/records/photograph"
import { uploadXray } from "../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../core/app/store"
import { convertMBtoKB } from "../../core/utils/formatters"

const FILE_ALLOWED_TYPES = ["png", "tiff", "jpg", "jpeg", "bmp"]
const FILE_ALLOWED_SIZE = 10 //in MB
const FILE_ALLOWED_LIMIT = 4

const UploadPhotographs: FC<{
  file: File
  onRemove: (f: any) => void
  sequence: number
  isXray?: boolean
}> = ({ file, onRemove, sequence, isXray }) => {
  const [progressValue, setProgressValue] = useState<number>(0)
  const [progressstatus, setProgresssStatus] = useState<
    "loading" | "complete" | "failed"
  >("loading")

  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()

  useEffect(() => {
    const uniqueId = v4()
    const newFile = new File(
      [file],
      `others-${uniqueId}.${file.type.split("/")[1]}`,
      { type: file.type },
    )
    const formPayload = new FormData()
    formPayload.append("attachment", newFile)
    formPayload.append("original_file_name", file.name)
    formPayload.append("sequence", sequence.toString())
    console.log(isXray)
    const apiFunction = isXray ? uploadXray : uploadPhotographs
    console.log(apiFunction)
    dispatch(
      apiFunction({
        patientId: patientId,
        caseId: caseId,
        formData: formPayload,
        fileName: newFile.name,
        orgId: "",
        onFileProcesscallback: function (progress: AxiosProgressEvent): void {
          const { loaded, total } = progress
          setProgresssStatus("loading")

          const percentageProgress = Math.floor((loaded / total) * 100)
          setProgressValue(percentageProgress)
          console.log(
            "...onFileProcesscallback....",
            loaded,
            total,
            percentageProgress,
          )
        },
      }),
    )
      .then((action) => {
        if (action.payload.status === 200) {
          setProgresssStatus("complete")
        } else {
          setProgresssStatus("failed")
        }
      })
      .catch((error) => {
        setProgresssStatus("failed")
      })
  }, [file])

  return (
    <>
      <Grid item sm={12}>
        <UFileProcessingCard
          fileName={file.name}
          fileSize={file.size}
          progressValue={progressValue}
          progressstatus={progressstatus}
          onRemove={() => onRemove(file)}
        />
      </Grid>
    </>
  )
}

const AdditionalPhotograph: FC<{
  title: string
  openModal: boolean
  setOpenModal: (value: boolean) => void
  showAdditionalFilesSection: boolean
  linkText?: string
  completedFiles?: File[]
  setCompletedFiles: (value: File[]) => void
  isXray?: boolean
}> = ({
  title,
  openModal,
  setOpenModal,
  showAdditionalFilesSection,
  linkText,
  completedFiles,
  setCompletedFiles,
  isXray,
}) => {
  const { t } = useTranslation("common")
  const { loading } = useAppSelector((state: RootState) => state.PhotosService)
  const [additionalFiles, setAdditionalFiles] = useState<File[]>([])
  const selectedAdditionalFiles = (files: FileList): void => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      setAdditionalFiles((o) => [file, ...o])
    }
  }

  return (
    showAdditionalFilesSection && (
      <>
        {completedFiles && completedFiles.length > 0 && (
          <Grid
            container
            gap={"10px"}
            sx={{
              width: 400,
              margin: "auto",
              pb: "6px",
              mt: "18px",
            }}
          >
            {completedFiles.length !== 0 &&
              completedFiles.map((file, index) => (
                <Grid item sm={12} key={index}>
                  <UFileProcessingCard
                    fileName={file.name}
                    fileSize={file.size}
                    progressstatus={"complete"}
                    onRemove={() => {
                      const updatedArray = [...completedFiles] // Create a copy of the array
                      updatedArray.splice(index, 1)
                      setCompletedFiles([...updatedArray])
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        )}

        {linkText && (
          <UButton
            variant="text"
            disableRipple
            btnText={linkText}
            sxProp={{
              textDecoration: "underline",
              textTransform: "initial",
              fontWeight: 400,
              lineHeight: 1.43,
              letterSpacing: "0.17px",
              width: "100%",
              padding: "6px 0",
              height: "inherit",
              ":hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
                textDecorationColor: "rgb(33, 94, 205, .4)",
                cursor: "pointer",
              },
            }}
            onClickHandler={() => setOpenModal(true)}
          />
        )}

        <UModalPopup
          title={title}
          sxModalProps={{
            ".MuiDialog-paper": {
              padding: 5,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: 563,
              borderRadius: 4,
              maxHeight: "80vh",
            },
            "#titleCntr": { justifyContent: "center" },
            "#contentCntr": {
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            },
            "#btnListCntr": { gap: 1 },
          }}
          isAlert={openModal}
          content={
            <>
              <Grid container flexDirection={"column"}>
                <UFileBox
                  boxSize={{ height: "216px", mt: "0 !important" }}
                  allowedFileExtensions={FILE_ALLOWED_TYPES}
                  allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE)}
                  fileAcceptType={"image/*"}
                  id="composite"
                  selectedFile={selectedAdditionalFiles}
                  fileLimit={FILE_ALLOWED_LIMIT}
                  isRequired={false}
                  isMultiple
                  messages={{
                    fileNote: t("records.file.filenote", {
                      fileTypes: "JPG, PNG, TIFF, BMP",
                      fileSize: FILE_ALLOWED_SIZE,
                    }),
                    uploadButton: t("records.file.btnclicktoupload"),
                    uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                    invalidfileFormat: (
                      <Trans components={{ newLine: <br /> }}>
                        {"records.photograph.invalidfileformat"}
                      </Trans>
                    ),
                    invalidFileSize: t("records.file.invalidfilesize", {
                      fileSize: FILE_ALLOWED_SIZE,
                    }),
                    invalidFileLimit: t(
                      "records.file.invalidfilelimitComposite",
                      {
                        noOfFiles: FILE_ALLOWED_LIMIT,
                      },
                    ),
                  }}
                />
              </Grid>
              {additionalFiles.length !== 0 && (
                <Grid
                  container
                  flexDirection={"column"}
                  gap={"10px"}
                  flexWrap={"nowrap"}
                  overflow={"scroll"}
                  sx={{
                    padding: "0 2px 1px",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {additionalFiles.length !== 0 &&
                    additionalFiles.map((file, index) => (
                      <UploadPhotographs
                        isXray={isXray}
                        file={file}
                        sequence={index + 1}
                        onRemove={() => {
                          const updatedArray = [...additionalFiles] // Create a copy of the array
                          updatedArray.splice(index, 1)
                          setAdditionalFiles([...updatedArray])
                        }}
                        key={index}
                      />
                    ))}
                </Grid>
              )}
            </>
          }
          btnList={[
            <UButton
              key={"Cancel"}
              btnText="Cancel"
              variant="outlined"
              onClickHandler={() => {
                setAdditionalFiles([])
                setOpenModal(false)
              }}
              sxProp={{ width: 90, height: 36 }}
            ></UButton>,
            <UButton
              key={"Done"}
              btnText="Done"
              variant="contained"
              disabled={loading === "pending"}
              onClickHandler={() => {
                setCompletedFiles(
                  completedFiles
                    ? [...completedFiles, ...additionalFiles]
                    : [...additionalFiles],
                )
                setOpenModal(false)
                setAdditionalFiles([])
              }}
              sxProp={{ width: 72, height: 36 }}
            ></UButton>,
          ]}
        ></UModalPopup>
      </>
    )
  )
}

export default AdditionalPhotograph
