import { FC, isValidElement, ReactNode, useEffect, useState } from "react"
import React from "react"
import { SxProps } from "@mui/system"

import {
  Autocomplete,
  Box,
  FormHelperText,
  styled,
  TextField,
} from "../../mui.components"

interface items {
  label: string
  key: string
}

export interface IAutoCompleteProps {
  /**
   * Unique identifier for select
   */
  id: string

  /**
   *  label for select
   */
  label: string

  /**
   * value holding the selected value
   */
  defaultValue?: string
  /**
   * To get selected value
   * @param value
   * @returns
   */
  onChangeHandler?: (value: string) => void

  /**
   * We can pass the optiona as two ways
   * option 1:
   *  - value as array of [{key: string, value: string}]
   * option 2:
   *  - value as react element
   */
  options: ReactNode | items[]
  hasError: boolean
  sxProp: SxProps
  customPopupIcon?: ReactNode
}

const UAutoComplete: FC<IAutoCompleteProps> = ({
  id,
  label,
  options,
  hasError,
  onChangeHandler,
  sxProp,
  defaultValue,
  customPopupIcon,
}) => {
  const [option, setOption] = useState<string>("")
  const [inputValue, setInputValue] = useState("")

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    padding: "4px 10px",
    color: "#000000",
    fontSize: "12px",
    fontWeight: "400",
    opacity: "0.6",
  }))

  const GroupItems = styled("ul")({
    padding: 0,
  })

  const Lists = options.map((option) => {
    const category = option.category
    return {
      category: category,
      ...option,
    }
  })

  return (
    <>
      <Autocomplete
        sx={{
          ...sxProp,
          "& input::placeholder": {
            color: hasError ? "error.main" : "",
            opacity: hasError ? "100%" : "",
          },
        }}
        popupIcon={customPopupIcon && customPopupIcon}
        onChange={(event: any, newValue: items) => {
          setOption(newValue?.label)
          onChangeHandler && onChangeHandler(newValue?.id)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        id={id}
        defaultValue={defaultValue}
        options={Lists}
        isOptionEqualToValue={(list, value) =>
          list.first_name === value.first_name
        }
        groupBy={(list) => list.category}
        renderInput={(params: any) => (
          <TextField
            {...params}
            name={id}
            placeholder={label}
            error={hasError}
          />
        )}
        renderGroup={(params) => (
          <Box
            component={"li"}
            key={params.key}
            sx={{
              position: "relative",
              borderBottom: "1px solid #e0e0e0",
              "&:last-child": {
                borderBottom: "none",
              },
            }}
          >
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </Box>
        )}
      />
    </>
  )
}

export default UAutoComplete
