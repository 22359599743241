import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material"

import { useRef } from "react"
import { useParams } from "react-router-dom"
import { RootState } from "../core/app/store"
import { useAppDispatch, useAppSelector } from "../core/app/hooks"
import { resetCase } from "../core/app/slices/clinical/clinicalSlice"
import { addNewCase } from "../core/app/slices/patients"
import { getTreatmentInfos } from "../core/app/slices/clinical/clinicalThunkApi"
import { setSelCaseID } from "./Wasm3DServiceSlice"

interface CaseData {
  id: string
  orgName: string
  createDate: string
}
const TableComponent = ({ rows, selected, handleSelect }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Org Name</TableCell>
            <TableCell>Create Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Checkbox
                  checked={selected.includes(row.id)}
                  onChange={() => handleSelect(row.id)}
                />
              </TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.orgName}</TableCell>
              <TableCell>{row.createDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const OpenCaseDialog = (props) => {
  const { selCaseId } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  const dispatch = useAppDispatch()

  var caseData: CaseData[] = []
  if (props.txList.length > 0) {
    props.txList.map((tx) => {
      caseData.push({
        id: tx.id,
        orgName: tx.org_name,
        createDate: tx.create_date,
      })
    })
  }

  const { treatmentList } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const [selected, setSelected] = useState([])
  const handleSelect = (id) => {
    console.log("Selected case id=" + id)
    dispatch(setSelCaseID(id))
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  const handleClose = () => {
    setSelected([])
    props.onClose()
  }

  /*
  useEffect(() => {}, [])

  useEffect(() => {
    caseData = [{ id: "10", name: "case" }]
    if (treatmentList.length > 0) {
      treatmentList.map((tx) => {
        caseData.push({ id: tx.id, name: "case" })
      })
    }
  }, [treatmentList]) */

  const clickAddddNewCase = () => {
    const payload = {
      description: "",
      upper_retainer_stage: "",
      lower_retainer_stage: "",
      phase: 0,
      is_doctor_phase: 0,
      is_lab_phase: 0,
      is_team_phase: 0,
      case_category_custom: "",
      case_number_custom: "",
      treatment_plan: 0,
      planned_upper: "",
      planned_lower: "",
      left_treatment_type: "",
      right_treatment_type: "",
      notes: "",
      case_extra_attrs: "",
    }
    dispatch(addNewCase({ patientId: props.patientId, payload }))
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>Select case</DialogTitle>
      <DialogContent>
        <Button onClick={clickAddddNewCase}> Add New Case </Button>
        <TableComponent
          rows={caseData}
          selected={selected}
          handleSelect={handleSelect}
        />
      </DialogContent>
    </Dialog>
  )
}

export default OpenCaseDialog
