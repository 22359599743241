import { createSlice } from "@reduxjs/toolkit"

import { uploadXray } from "./xraysThunkApi"

interface XrayUploadState {
  loading: "idle" | "pending" | "succeeded" | "failed"
}

const initialState: XrayUploadState = {
  loading: "idle",
}

export const xrayServiceSlice = createSlice({
  name: "XrayService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadXray.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(uploadXray.fulfilled, (state, action) => {
      console.log("..uploadxrays.fulfilled.", action.payload) // TODO: for testing purposes will remove this later
      state.loading = "succeeded"
    })
    builder.addCase(uploadXray.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
  },
})
