import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import ipr1 from "../../../../../../assets/images/clinical/report/ipr/ipr1.svg"
import ipr2 from "../../../../../../assets/images/clinical/report/ipr/ipr2.svg"
import ipr3 from "../../../../../../assets/images/clinical/report/ipr/ipr3.svg"
import ipr4 from "../../../../../../assets/images/clinical/report/ipr/ipr4.svg"
import ipr5 from "../../../../../../assets/images/clinical/report/ipr/ipr5.svg"
import ipr6 from "../../../../../../assets/images/clinical/report/ipr/ipr6.svg"
import ipr7 from "../../../../../../assets/images/clinical/report/ipr/ipr7.svg"
import ipr8 from "../../../../../../assets/images/clinical/report/ipr/ipr8.svg"
import ipr9 from "../../../../../../assets/images/clinical/report/ipr/ipr9.svg"
import ipr10 from "../../../../../../assets/images/clinical/report/ipr/ipr10.svg"
import ipr11 from "../../../../../../assets/images/clinical/report/ipr/ipr11.svg"
import ipr12 from "../../../../../../assets/images/clinical/report/ipr/ipr12.svg"
import ipr13 from "../../../../../../assets/images/clinical/report/ipr/ipr13.svg"
import ipr14 from "../../../../../../assets/images/clinical/report/ipr/ipr14.svg"
import ipr15 from "../../../../../../assets/images/clinical/report/ipr/ipr15.svg"
import ipr17 from "../../../../../../assets/images/clinical/report/ipr/ipr17.svg"
import ipr18 from "../../../../../../assets/images/clinical/report/ipr/ipr18.svg"
import ipr19 from "../../../../../../assets/images/clinical/report/ipr/ipr19.svg"
import ipr20 from "../../../../../../assets/images/clinical/report/ipr/ipr20.svg"
import ipr21 from "../../../../../../assets/images/clinical/report/ipr/ipr21.svg"
import ipr22 from "../../../../../../assets/images/clinical/report/ipr/ipr22.svg"
import ipr23 from "../../../../../../assets/images/clinical/report/ipr/ipr23.svg"
import ipr24 from "../../../../../../assets/images/clinical/report/ipr/ipr24.svg"
import ipr25 from "../../../../../../assets/images/clinical/report/ipr/ipr25.svg"
import ipr26 from "../../../../../../assets/images/clinical/report/ipr/ipr26.svg"
import ipr27 from "../../../../../../assets/images/clinical/report/ipr/ipr27.svg"
import ipr28 from "../../../../../../assets/images/clinical/report/ipr/ipr28.svg"
import ipr29 from "../../../../../../assets/images/clinical/report/ipr/ipr29.svg"
import ipr30 from "../../../../../../assets/images/clinical/report/ipr/ipr30.svg"
import ipr31 from "../../../../../../assets/images/clinical/report/ipr/ipr31.svg"
import iprMiss from "../../../../../../assets/images/clinical/report/ipr/miss.svg"

import "./ipr.scss"

const IPR: FC = () => {
  const { t } = useTranslation("common")
  // text render function
  const renderData = (arr) => {
    const box: any[] = []
    arr.map((i, j) => {
      if (j === 1) {
        box.push(
          <p style={{ color: "#0080aa" }} key={j} className={styles.iprText}>
            {i.str}
          </p>,
        )
      } else {
        box.push(
          <p style={{ color: "#333" }} key={j} className={styles.iprText}>
            {i.str}
          </p>,
        )
      }
    })
    return box
  }

  return (
    <div className="iprWrap">
      <div className={"tips"}>{t("treat.reportIPR.tip")}</div>
      <div className={"iprBox"}>
        <div className={"iprBg"}></div>
        {/* 缺牙图片定位及数据1-32 */}
        {/* 1-8 */}
        <img
          src={iprMiss}
          style={{ left: "220px", top: "260px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "224px", top: "228px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "230px", top: "194px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "239px", top: "162px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "249px", top: "137px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "264px", top: "114px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "286px", top: "98px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "312px", top: "89px" }}
          className={"missTooth"}
        />
        {/* 9-16 */}

        <img
          src={iprMiss}
          style={{ left: "344px", top: "89px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "372px", top: "97px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "394px", top: "114px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "409px", top: "137px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "419px", top: "163px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "427px", top: "195px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "433px", top: "229px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "436px", top: "261px" }}
          className={"missTooth"}
        />

        {/* 17-24 */}
        <img
          src={iprMiss}
          style={{ left: "435px", top: "318px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "430px", top: "348px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "425px", top: "382px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "413px", top: "410px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "401px", top: "435px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "384px", top: "456px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "363px", top: "467px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "342px", top: "474px" }}
          className={"missTooth"}
        />
        {/* 25-32 */}
        <img
          src={iprMiss}
          style={{ left: "318px", top: "474px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "296px", top: "467px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "273px", top: "455px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "257px", top: "435px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "245px", top: "410px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "232px", top: "381px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "227px", top: "350px" }}
          className={"missTooth"}
        />
        <img
          src={iprMiss}
          style={{ left: "222px", top: "318px" }}
          className={"missTooth"}
        />
        {/* 1 */}
        <>
          <img
            src={ipr1}
            style={{ top: "253px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "242px", marginLeft: "-289px" }}
          >
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 2 */}
        <>
          <img
            src={ipr2}
            style={{ top: "216px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "195px", marginLeft: "-289px" }}
          >
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 3 */}
        <>
          <img
            src={ipr3}
            style={{ top: "180px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "148px", marginLeft: "-289px" }}
          >
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 4 */}
        <>
          <img
            src={ipr4}
            style={{ top: "148px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "100px", marginLeft: "-289px" }}
          >
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 5 */}
        <>
          <img
            src={ipr5}
            style={{ top: "99px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "52px", marginLeft: "-289px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 6 */}
        <>
          <img
            src={ipr6}
            style={{ top: "51px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "3px", marginLeft: "-289px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 7 */}
        <>
          <img
            src={ipr7}
            style={{ top: "43px", marginLeft: "-167px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "-5px", marginLeft: "-168px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 8 */}
        <>
          <img
            src={ipr8}
            style={{ top: "49px", marginLeft: "-54px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "1px", marginLeft: "-55px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 9 */}
        <>
          <img
            src={ipr9}
            style={{ top: "44px", marginLeft: "33px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "-3px", marginLeft: "58px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 10 */}
        <>
          <img
            src={ipr10}
            style={{ top: "51px", marginLeft: "56px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "4px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 11 */}
        <>
          <img
            src={ipr11}
            style={{ top: "101px", marginLeft: "80px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "53px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 12 */}
        <>
          <img
            src={ipr12}
            style={{ top: "149px", marginLeft: "94px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "102px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 13 */}
        <>
          <img
            src={ipr13}
            style={{ top: "178px", marginLeft: "102px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "150px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 14 */}
        <>
          <img
            src={ipr14}
            style={{ top: "218px", marginLeft: "113px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "196px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 15 */}
        <>
          <img
            src={ipr15}
            style={{ top: "254px", marginLeft: "120px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "244px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>

        {/* 17 */}
        <>
          <img
            src={ipr17}
            style={{ top: "342px", marginLeft: "112px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "295px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 18 */}
        <>
          <img
            src={ipr18}
            style={{ top: "375px", marginLeft: "108px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "344px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 19 */}
        <>
          <img
            src={ipr19}
            style={{ top: "410px", marginLeft: "96px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "391px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 20 */}
        <>
          <img
            src={ipr20}
            style={{ top: "435px", marginLeft: "88px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "438px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 21 */}
        <>
          <img
            src={ipr21}
            style={{ top: "458px", marginLeft: "65px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "485px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 22 */}
        <>
          <img
            src={ipr22}
            style={{ top: "476px", marginLeft: "47px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "530px", marginLeft: "170px" }}>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 23 */}
        <>
          <img
            src={ipr23}
            style={{ top: "491px", marginLeft: "21px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "549px", marginLeft: "62px" }}>
            <p className={"iprLine iprLine1"} style={{ marginLeft: "-15px" }}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"} style={{ marginLeft: "-8px" }}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 24 */}
        <>
          <img
            src={ipr24}
            style={{ top: "491px", marginLeft: "-48px" }}
            className={"iprImg"}
          />
          <div className={"wrap"} style={{ top: "549px", marginLeft: "-53px" }}>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>

        {/* 25 */}
        <>
          <img
            src={ipr25}
            style={{ top: "491px", marginLeft: "-163px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "549px", marginLeft: "-172px" }}
          >
            <p className={"iprLine iprLine1"} style={{ marginLeft: "14px" }}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"} style={{ marginLeft: "7px" }}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 26 */}
        <>
          <img
            src={ipr26}
            style={{ top: "478px", marginLeft: "-283px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "531px", marginLeft: "-289px" }}
          >
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 27 */}
        <>
          <img
            src={ipr27}
            style={{ top: "459px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "484px", marginLeft: "-289px" }}
          >
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 28 */}
        <>
          <img
            src={ipr28}
            style={{ top: "434px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "437px", marginLeft: "-289px" }}
          >
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 29 */}
        <>
          <img
            src={ipr29}
            style={{ top: "411px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "391px", marginLeft: "-289px" }}
          >
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 30 */}
        <>
          <img
            src={ipr30}
            style={{ top: "375px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "343px", marginLeft: "-289px" }}
          >
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
        {/* 31 */}
        <>
          <img
            src={ipr31}
            style={{ top: "341px", marginLeft: "-284px" }}
            className={"iprImg"}
          />
          <div
            className={"wrap"}
            style={{ top: "294px", marginLeft: "-289px" }}
          >
            <p className={"iprLine iprLine1"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine2"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
            <p className={"iprLine iprLine3"}>
              <span className={"iprValue"}>1.22mm</span>
              <span className={"iprStage"}>| stage12</span>
            </p>
          </div>
        </>
      </div>
    </div>
  )
}
export default IPR
