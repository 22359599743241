import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import { UText } from "../../../components"
import { Button, Grid } from "../../../components/mui.components"

const Spacing: FC = () => {
  const { t } = useTranslation("common")
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <Grid
      container
      flexDirection={"column"}
      sx={{
        backgroundColor: "background.default",
        borderRadius: 2,
        padding: 5,
        height: 2000,
        justifyContent: "space-between",
      }}
    >
      <UText variant={"h4"} component={"div"} sxProp={{ textAlign: "center" }}>
        {t("uassist.uAssistForm.spacing")}
      </UText>
      <Button
        variant="outlined"
        onClick={() => {
          navigate("/uassist/patient/1/case/1/prescription")
        }}
        sx={{ width: "96px", height: "36px" }}
      >
        <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
        {t("button.back")}
      </Button>
    </Grid>
  )
}

export default Spacing
