import { FC, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Navigate, Route, Routes, useParams } from "react-router-dom"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import CloseIcon from "../../assets/svgs/close.svg"
import { FormAutoComplete, UButton, UText } from "../../components"
import {
  Backdrop,
  Box,
  Chip,
  Grid,
  IconButton,
} from "../../components/mui.components"
import { useAppSelector } from "../../core/app/hooks"
import { useAppDispatch } from "../../core/app/hooks"
import { getZipsType } from "../../core/app/slices/clinical/clinical.types"
import { DOWNLOAD_FILEZIP } from "../../core/app/slices/clinical/clinicalSlice"
import { fetchFileZips } from "../../core/app/slices/clinical/clinicalThunkApi"
import { updateCase } from "../../core/app/slices/patients/patientThunkApi"
import { RootState } from "../../core/app/store"
import { CENETER_COLUMN_ALIGN } from "../../theme/theme.util"

import DentalPortrait from "./DentalPortrait/DentalPortrait"
import Crowding from "./USmartForms/Crowding"
import DeepBite from "./USmartForms/Deepbite"
import Limited from "./USmartForms/LimitedTreatment"
import OpenBite from "./USmartForms/Openbite"
import Prescription from "./USmartForms/Prescription"
import Spacing from "./USmartForms/Spacing"
import PhotoList from "./PhotoList"

const UassistRoutes: FC = () => {
  const { patientId, caseId } = useParams()
  return (
    <Routes>
      <Route path={"prescription"} element={<Prescription />} />
      <Route path={"crowding"} element={<Crowding />} />
      <Route path={"deepbite"} element={<DeepBite />} />
      <Route path={"openbite"} element={<OpenBite />} />
      <Route path={"limitedtreatment"} element={<Limited />} />
      <Route path={"spacing"} element={<Spacing />} />
      <Route path={"dentalchart"} element={<DentalPortrait />} />
      <Route path={"*"} element={<Navigate to={"dentalchart"} replace />} />
    </Routes>
  )
}

const UassistModule: FC = ({ zipList, downLoadZips, current_orgId }) => {
  const { t } = useTranslation()
  const { patientId, caseId } = useParams()
  const {
    control,
    formState: { errors },
    watch,
  } = useForm()
  const dispatch = useAppDispatch()
  const preview = useRef<HTMLInputElement>(null)
  const [selectedPreview, setSelectedPreview] = useState("")

  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )

  const { caseDetails, patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const formValue = watch("doctor", caseDetails.assigned_to)

  const formLabel = doctorList?.find((list) => list.id === formValue)

  useEffect(() => {
    if (caseDetails.assigned_to === formValue) return
    dispatch(
      updateCase({
        patientId: patientId,
        caseId: caseId,
        payload: {
          assigned_to: formValue,
        },
      }),
    )
  }, [formValue])

  useEffect(() => {
    downLoadZips({
      orgId: current_orgId,
      patientId,
      caseId,
    })
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (preview && preview.current) {
        const { top, height } = preview.current.getBoundingClientRect()
        //To determine if the columns have wrapped
        const parent = preview.current.parentElement?.offsetWidth
        const form = preview.current.nextSibling?.offsetWidth
        if (parent && form) {
          const isSticky =
            top <= 0 &&
            top + height > 0 &&
            parent >= form + preview.current.offsetWidth + 45
          preview.current.classList.toggle("preview-sticky", isSticky)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [preview])

  return (
    <>
      <Grid
        container
        gap={"45px"}
        marginBottom={"48px"}
        paddingTop={2}
        justifyContent={"center"}
      >
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          minWidth={"466px"}
          ref={preview}
          height={"720px"}
        >
          <Grid sx={{ my: 2 }}>
            <Box component={"div"} sx={{ mb: 1 }}>
              <UText variant={"h5"}>
                {patientData?.firstName + " " + patientData?.lastName}
              </UText>
            </Box>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Chip
                label={t("uassist.description")}
                sx={{
                  background: "#0288D1",
                  fontSize: "13px",
                  fontWeight: "400px",
                  color: "primary.contrastText",
                  padding: " 2px 4px",
                  borderRadius: "50px",
                  lineHeight: "18px",
                  letterSpacing: "0.16px",
                  whiteSpace: "nowrap",
                  height: "unset",
                }}
              />
              <Box
                component="div"
                sx={{
                  borderLeft: "1px solid rgba(0, 0, 0, 0.12);",
                  height: "20px",
                  mx: 1,
                }}
              ></Box>
              {formLabel?.label && (
                <>
                  <Box
                    component={"div"}
                    sx={{
                      ...CENETER_COLUMN_ALIGN,
                      height: "24px",
                      width: "50px",
                      backgroundColor: "success.dark",
                      borderRadius: "50px",
                      marginRight: "8px",
                      fontSize: "10px",
                      justifyContent: "center",
                      fontWeight: 500,
                      color: "#ffffff",
                    }}
                  >
                    {formLabel?.label
                      .charAt(0)
                      .concat(formLabel?.label.split(" ")[1].charAt(0))
                      .toUpperCase()}
                  </Box>
                  <FormAutoComplete
                    inputLabel={"formfields.alldoctors"}
                    fieldName={"doctor"}
                    rules={{
                      required: "formfieldErrors.assigntorequired",
                    }}
                    defaultValue={
                      doctorList?.find(
                        (list) => list.id === caseDetails.assigned_to,
                      )?.label
                    }
                    options={doctorList}
                    errors={false}
                    control={control}
                    formSxprops={{ my: 0, display: "block" }}
                    customPopupIcon={
                      <KeyboardArrowDownIcon fontSize={"medium"} />
                    }
                    sxProp={{
                      "& .MuiOutlinedInput-root": {
                        padding: "0 !important",
                        width: "200px",
                      },
                      "& .MuiOutlinedInput-root:hover:not(.Mui-focused )": {
                        "& fieldset": {
                          border: "none !important",
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "inline-flex",
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                          marginRight: "-10px",
                        },
                      },
                      "& .MuiOutlinedInput-root:focus-within": {
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "inline-flex !important",
                          backgroundColor: "rgba(0, 0, 0, 0.12)",
                          marginRight: "-10px",
                        },
                      },
                      "& input": {
                        padding: "0 !important",
                        fontSize: "14px",
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        display: "none",
                      },
                      "& svg": {
                        width: "18px",
                        height: "18px",
                      },
                      "& fieldset": {
                        border: "none !important",
                        padding: "0 !important",
                      },
                      "& .MuiFormControl-root": {
                        display: "block",
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        display: "none",
                      },
                    }}
                  />
                </>
              )}
            </Box>
          </Grid>
          <PhotoList zips={zipList} setSelectedPreview={setSelectedPreview} />
        </Grid>
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          width={"640px"}
        >
          <Box
            component={"div"}
            display={"flex"}
            justifyContent={"flex-end"}
            height={82}
            padding={"16px 0"}
          >
            <UButton
              sxProp={{ height: 30, width: 63 }}
              variant={"shade"}
              btnType={"button"}
              btnText={t("button.close")}
              size={"small"}
            />
          </Box>
          <UassistRoutes />
        </Grid>
      </Grid>
      {selectedPreview && (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <Box
              display="flex"
              justifyContent="center"
              position="fixed"
              top="120px"
              bottom="120px"
              left="120px"
              right="120px"
              overflow="hidden"
            >
              <Box
                id="backdrop"
                position="relative"
                width="inherit"
                height="inherit"
              >
                <img
                  src={selectedPreview}
                  alt={"fullscreenimg"}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  loading="lazy"
                />
                <Box
                  component={"div"}
                  id="closeicon"
                  position="absolute"
                  top="0px"
                  right="0px"
                  sx={{ padding: "8px" }}
                >
                  <IconButton
                    size="medium"
                    sx={{
                      fontSize: "large",
                      backgroundColor: "divider",
                      padding: "0px",
                      ":hover": { backgroundColor: "divider" },
                    }}
                    onClick={() => setSelectedPreview("")}
                  >
                    <Box
                      component={"img"}
                      src={CloseIcon}
                      alt={"Logo"}
                      loading="lazy"
                      sx={{ cursor: "pointer", padding: "12px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Backdrop>
        </>
      )}
    </>
  )
}
export default connect(
  ({ clinicalService, userService }) => {
    const { zipList } = clinicalService
    const {
      user: { current_orgId },
    } = userService
    return { zipList, current_orgId }
  },
  (dispatch) => {
    return {
      downLoadZips: ({
        orgId,
        patientId,
        caseId,
        zipNames = DOWNLOAD_FILEZIP,
      }: getZipsType) => {
        dispatch(
          fetchFileZips({
            orgId,
            patientId,
            caseId,
            zipNames,
          }),
        )
      },
    }
  },
)(UassistModule)
