import React from "react"
import { useEffect, useState, PureComponent } from "react"
import {
  Card,
  CardContent,
  IconButton,
  CardHeader,
  Grid,
  Divider,
} from "@mui/material"
import {
  Typography,
  Stack,
  ToggleButtonGroup,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import { Rnd } from "react-rnd"
import { setShowTMChart } from "../Wasm3DServiceSlice"

import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"
import { setIsShow, setData, setNewState } from "./ToothInfoSlice"
import { toothMovement } from "@ulab/wesmileclinical"
import { FlowToggleButton } from "../Custom/FlowCustom"
import { stagingPercent } from "@ulab/wesmileclinical"
import { wasmModule } from "@ulab/wesmileclinical"
import { IToothPercentInfo } from "@ulab/wesmileclinical"
import { GlobalEvents, globalEvents } from "@ulab/wesmileclinical"
import {
  FormControl,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"
import { setCurJaw } from "./ManuStagingPercentSlice"
import { SelectChangeEvent } from "@mui/material/Select"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"

const FlowBarChart = (props) => {
  return (
    <BarChart
      width={550}
      height={300}
      data={props.data}
      margin={{
        top: 10,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      {props.type == "simple" ? (
        <>
          <YAxis type="number" domain={props.ydomain} />
          <Tooltip />
          <Bar dataKey="Dist" fill="#8884d8" />
        </>
      ) : (
        <>
          <YAxis
            type="number"
            yAxisId="left"
            orientation="left"
            stroke="#8884d8"
            domain={props.ydomain}
          />
          <YAxis
            type="number"
            yAxisId="right"
            orientation="right"
            stroke="#82ca9d"
            domain={props.ydomain2}
          />
          <Tooltip />
          <Legend />
          <Bar yAxisId="left" dataKey="Dist" fill="#8884d8" />
          <Bar yAxisId="right" dataKey="Angle" fill="#82ca9d" />
        </>
      )}
    </BarChart>
  )
}

const UFlowTMChart = (props) => {
  const [chartData, setChartData] = useState([])
  const [barChartType, setBarChartType] = useState("simple") // simple and biaxial
  const [selParam, setSelParam] = useState(0)
  const [ydomain, setYdomain] = useState([0, 4])
  const [ydomain2, setYdomain2] = useState([0, 20])
  const { curJaw } = useAppSelector(
    (state: RootState) => state.manuStagingPercentSlice,
  )

  const dispatch = useAppDispatch()
  const handleChange = (event: SelectChangeEvent) => {
    let param = parseInt(event.target.value)
    console.log(param)
    setSelParam(param)
  }
  const Top = (props) => {
    return (
      <Grid
        container
        direction="row"
        spacing={1}
        columns={12}
        alignItems="center"
      >
        <Grid item xs={4}>
          <FormControl size="small">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={curJaw}
              value={curJaw}
              onChange={(_, value) => {
                dispatch(setCurJaw(value))
              }}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="upper"
                control={<Radio size="small" />}
                label={<Typography variant="body2">Upper</Typography>}
              />
              <FormControlLabel
                value="lower"
                control={<Radio size="small" />}
                label={<Typography variant="body2">Lower</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Param</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label="Age"
              value={selParam.toString()}
              onChange={handleChange}
            >
              <MenuItem value={-1}>Trans & Angle</MenuItem>
              <MenuItem value={0}>Total Trans</MenuItem>
              <MenuItem value={1}>Total Angle</MenuItem>
              <MenuItem value={2}>Mesial/Distal</MenuItem>
              <MenuItem value={3}>Buccal/Lingual</MenuItem>
              <MenuItem value={4}>Intrusion/Extrusion</MenuItem>
              <MenuItem value={5}>Tip</MenuItem>
              <MenuItem value={6}>Torque</MenuItem>
              <MenuItem value={7}>Rotation</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  const updateData = () => {
    if (!wasmModule.isInit) return
    const percentData = stagingPercent.getTeethPercentInfo(curJaw == "upper")
    if (!percentData) return
    let data = []
    console.log("Cur select param is " + selParam)
    if (selParam == -1) {
      setBarChartType("biaxial")
      setYdomain([0, 4])
      setYdomain2([0, 40])
    } else {
      setBarChartType("simple")
      let ydomain = [0, 4]
      if (selParam == 0) ydomain = [0, 4]
      else if (selParam == 1) ydomain = [0, 40]
      else if (selParam < 5) ydomain = [-4, 4]
      else ydomain = [-40, 40]
      setYdomain(ydomain)
    }
    for (let i = 1; i <= 16; i++) {
      const tID = i + (curJaw == "upper" ? 0 : 16)
      const pD = percentData[tID]
      let dist = 0.0
      let angle = 0.0
      if (!pD.enable) {
        dist = 0.0
        angle = 0.0
      } else if (selParam == -1) {
        let sum2 = 0.0
        let sum21 = 0.0
        for (let i = 0; i < 3; i++) {
          sum2 += pD.moveVec[i] * pD.moveVec[i]
          sum21 += pD.moveVec[i + 3] * pD.moveVec[i + 3]
        }
        dist = Math.sqrt(sum2)
        angle = Math.sqrt(sum21)
      } else if (selParam == 0) {
        let sum2 = 0.0
        for (let i = 0; i < 3; i++) {
          sum2 += pD.moveVec[i] * pD.moveVec[i]
        }
        dist = Math.sqrt(sum2)
      } else if (selParam == 1) {
        let sum2 = 0.0
        for (let i = 0; i < 3; i++) {
          sum2 += pD.moveVec[i + 3] * pD.moveVec[i + 3]
        }
        dist = Math.sqrt(sum2)
      } else if (pD.moveVec.length > 0) {
        dist = pD.moveVec[selParam - 2]
      }
      data.push({
        name: tID,
        Dist: dist.toFixed(2),
        Angle: angle.toFixed(2),
      })
    }
    setChartData(data)
  }
  useEffect(() => {
    updateData()
  }, [curJaw, selParam])

  useEffect(() => {
    globalEvents.on(GlobalEvents.ON_STAGESTEP_CHANGED, () => {
      updateData()
    })
  }, [])

  return (
    <div>
      {props.open && (
        <Rnd
          default={{
            x: 20,
            y: 305,
            width: 600,
            height: 300,
          }}
          enableResizing={{
            top: false,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          bounds="window"
          dragHandleClassName="my-drag-handle"
        >
          <Card sx={{ width: 600 }}>
            <CardHeader
              className="my-drag-handle"
              sx={{ backgroundColor: "lightskyblue", height: 30 }}
              action={
                <IconButton
                  aria-label="settings"
                  sx={{ width: 30, height: 30, top: -10 }}
                  onClick={() => {
                    dispatch(setShowTMChart(false))
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
              titleTypographyProps={{ variant: "body1" }}
              title="Tooth Movement Chart"
            />
            <CardContent>
              <Stack>
                <Top />
                <FlowBarChart
                  data={chartData}
                  type={barChartType}
                  ydomain={ydomain}
                  ydomain2={ydomain2}
                />
              </Stack>
            </CardContent>
          </Card>
        </Rnd>
      )}
    </div>
  )
}
export default UFlowTMChart
