import { createSlice } from "@reduxjs/toolkit"

import { PhotoObject } from "./photograph.type"
import {
  downloadPhotographs,
  fetchFilesList,
  uploadPhotographs,
} from "./photographThunkApi"

interface IPhotographState {
  loading: "idle" | "pending" | "succeeded" | "failed"
  fileList: any[]
  compositePhoto: PhotoObject[]
  individualPhotos: PhotoObject[]
  additionalPhotos: PhotoObject[]
}

const initialState: IPhotographState = {
  loading: "idle",
  fileList: [],
  compositePhoto: [],
  individualPhotos: [],
  additionalPhotos: [],
}

export const photographServiceSlice = createSlice({
  name: "photographService",
  initialState,
  reducers: {
    resetPhotoLists: (state) => {
      state.compositePhoto = []
      state.individualPhotos = []
      state.additionalPhotos = []
      state.fileList = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadPhotographs.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(uploadPhotographs.fulfilled, (state, action) => {
      console.log("..uploadPhotographs.fulfilled.", action.payload) // TODO: for testing purposes will remove this later
      state.loading = "succeeded"
    })
    builder.addCase(uploadPhotographs.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })

    builder.addCase(downloadPhotographs.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(downloadPhotographs.fulfilled, (state, action) => {
      console.log("download fulfilled.", action.meta.arg)
      const photographType = action.meta.arg.photographType
      console.log(photographType)
      const result = action.payload.data
      if (result) {
        const file = new File(
          [result],
          `${action.meta.arg.original_file_name}`,
          { type: "image/jpg" },
        )
        const obj: PhotoObject = {
          file: file,
          sequence: action.meta.arg.sequence,
          position: action.meta.arg.position,
          name: action.meta.arg.original_file_name,
        }
        switch (photographType) {
          case "composite":
            state.compositePhoto.push(obj)
            break
          case "individual":
            state.individualPhotos.push(obj)
            break
          case "others":
            state.additionalPhotos.push(obj)
            break
          default:
            break
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(downloadPhotographs.rejected, (state, action) => {
      console.log("rejected")

      state.loading = "failed"
    })

    builder.addCase(fetchFilesList.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchFilesList.fulfilled, (state, action) => {
      console.log("file list fetch fulfilled.", action.payload)
      const { result } = action.payload.data
      if (result && result.length > 0) {
        state.fileList = [...result]
      }
      state.loading = "succeeded"
    })
    builder.addCase(fetchFilesList.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
  },
})

export const { resetPhotoLists } = photographServiceSlice.actions
