import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { AxiosProgressEvent } from "axios"

import {
  UFileBox,
  UFileProcessingCard,
  UImagePreview,
  UText,
} from "../../../components"
import { Container, Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  downloadPhotographs,
  uploadPhotographs,
} from "../../../core/app/slices/records/photograph"
import { RootState } from "../../../core/app/store"
import { convertMBtoKB } from "../../../core/utils/formatters"
import AdditionalPhotograph from "../AdditionalPhotograph"

const FILE_ALLOWED_TYPES = ["png", "tiff", "jpg", "jpeg", "bmp"]
const FILE_ALLOWED_SIZE = 10 //in MB
const FILE_ALLOWED_LIMIT = 1

const CompositePhotgraph: FC<{
  selectedFileSection: (str: "composite" | "both") => void
  compositeList: any[]
}> = ({ selectedFileSection, compositeList }) => {
  const { t } = useTranslation("common")
  const [files, setFiles] = useState<File[]>([])
  const [progressValue, setProgressValue] = useState<number>(0)
  const [progressstatus, setProgresssStatus] = useState<
    "loading" | "complete" | "failed"
  >("loading")
  const selectedCompositeFile = (files: FileList): void => {
    setFiles([...files])
    selectedFileSection("composite")
  }
  const [completedFiles, setCompletedFiles] = useState<File[]>([])
  const [showAdditionalFilesSection, setShowAdditionalFilesSection] =
    useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()

  const { compositePhoto, additionalPhotos } =
    useAppSelector((state: RootState) => state.PhotosService) || {}

  useEffect(() => {
    const otherimgs = []
    if (additionalPhotos.length > 0) {
      additionalPhotos.map((imgFile) => {
        otherimgs.push(imgFile.file)
      })
    }
    setCompletedFiles(otherimgs)
    setShowAdditionalFilesSection(true)
  }, [additionalPhotos])

  useEffect(() => {
    if (compositeList && compositeList.length && compositePhoto.length === 0) {
      console.log(compositeList, "compositeList")
      dispatch(
        downloadPhotographs({
          patientId: patientId,
          caseId: caseId,
          fileName: compositeList[0].fileName,
          photographType: "composite",
          original_file_name: compositeList[0].original_name,
          sequence: "",
          position: "",
        }),
      ).then((response) => {
        if (response.payload.status === 200) {
          const file = new File(
            [response.payload.data],
            `${response.meta.arg.original_file_name}`,
            { type: "image/jpg" },
          )
          setFiles([file])
        }
      })
    }
  }, [compositeList])

  useEffect(() => {
    if (files.length === 0) return
    if (compositeList.length == 0) {
      const newFile = new File(
        [files[0]],
        `composite.${files[0].type.split("/")[1]}`,
        { type: files[0].type },
      )
      const formPayload = new FormData()
      formPayload.append("attachment", newFile)
      formPayload.append("original_file_name", files[0].name)
      dispatch(
        uploadPhotographs({
          patientId: patientId,
          caseId: caseId,
          formData: formPayload,
          fileName: newFile.name,
          orgId: "",
          onFileProcesscallback: function (progress: AxiosProgressEvent): void {
            const { loaded, total } = progress
            setProgresssStatus("loading")
            const percentageProgress = Math.floor((loaded / total) * 100)
            setProgressValue(percentageProgress)
          },
        }),
      )
        .then((action) => {
          if (action.payload.status === 200) {
            setProgresssStatus("complete")
            setShowAdditionalFilesSection(true)
          } else {
            setProgresssStatus("failed")
          }
        })
        .catch((error) => {
          setProgresssStatus("failed")
        })
    }
  }, [files])

  useEffect(() => {
    if (!completedFiles.length && !files.length) {
      selectedFileSection("both")
      setShowAdditionalFilesSection(false)
    }
  }, [completedFiles])

  return (
    <>
      <UText
        color={"text.primary"}
        sxProp={{ display: "flex", justifyContent: "center" }}
        variant={"h6"}
      >
        {t("records.photograph.composite")}
      </UText>
      <Grid container justifyContent={"center"}>
        {files.length === 1 || compositePhoto.length === 1 ? (
          <>
            {(progressstatus === "loading" || progressstatus === "failed") &&
              compositePhoto.length === 0 && (
                <Container
                  sx={{
                    padding: "0 !important",
                  }}
                >
                  <UFileProcessingCard
                    fileName={files[0].name}
                    fileSize={files[0].size}
                    progressValue={progressValue}
                    onRemove={() => {
                      if (!completedFiles.length) {
                        selectedFileSection("both")
                        setShowAdditionalFilesSection(false)
                      }
                      setFiles([])
                    }}
                    progressstatus={progressstatus}
                  ></UFileProcessingCard>
                </Container>
              )}
            {(progressstatus === "complete" || compositePhoto.length === 1) && (
              <UImagePreview
                imageHeight={400}
                imageWidth={400}
                files={files}
                deleteImage={() => {
                  if (!completedFiles.length) {
                    selectedFileSection("both")
                    setShowAdditionalFilesSection(false)
                  }
                  setFiles([])
                }}
                showFileName
                sxImageProp={{ mt: 0 }}
              />
            )}
          </>
        ) : (
          <Container
            maxWidth={"md"}
            sx={{
              padding: "0 !important",
            }}
          >
            <UFileBox
              boxSize={{ height: "216px", mt: "0 !important" }}
              allowedFileExtensions={FILE_ALLOWED_TYPES}
              allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE)}
              fileAcceptType={"image/*"}
              id="composite"
              selectedFile={selectedCompositeFile}
              fileLimit={FILE_ALLOWED_LIMIT}
              isRequired={false}
              messages={{
                fileNote: t("records.file.filenote", {
                  fileTypes: "JPG, PNG, TIFF, BMP",
                  fileSize: FILE_ALLOWED_SIZE,
                }),
                uploadButton: t("records.file.btnclicktoupload"),
                uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                invalidfileFormat: (
                  <Trans components={{ newLine: <br /> }}>
                    {"records.photograph.invalidfileformat"}
                  </Trans>
                ),
                invalidFileSize: t("records.file.invalidfilesize", {
                  fileSize: FILE_ALLOWED_SIZE,
                }),
                invalidFileLimit: t("records.file.invalidfilelimitComposite", {
                  noOfFiles: FILE_ALLOWED_LIMIT,
                }),
              }}
            />
          </Container>
        )}
      </Grid>
      <AdditionalPhotograph
        title={t("records.photograph.modalPopupTitle")}
        openModal={openModal}
        setOpenModal={setOpenModal}
        linkText={t("records.photograph.additionalPhotos") || ""}
        showAdditionalFilesSection={showAdditionalFilesSection}
        completedFiles={completedFiles}
        setCompletedFiles={setCompletedFiles}
      />
    </>
  )
}

export default CompositePhotgraph
