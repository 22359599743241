import { createAsyncThunk } from "@reduxjs/toolkit"

import { uploadXrayFiles } from "./xray.service"
import { fileUploadParams } from "./xrays.type"

export const uploadXray = createAsyncThunk(
  "XrayService/uploadXray",
  async (
    {
      patientId,
      caseId,
      formData,
      fileName,
      onFileProcesscallback,
    }: fileUploadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadXrayFiles(
        orgId,
        patientId,
        caseId,
        fileName,
        formData,
        onFileProcesscallback,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
