import React from "react"
import { useEffect } from "react"
import { Stack } from "@mui/material"

import { FlowTabs } from "../../Custom/FlowCustom"
import { gdsPlusSetup, SetupType, stagingManager } from "@ulab/wesmileclinical"
import DrawNewSetup from "./newSetup"
import DrawArchform from "./archform"
import DrawAnterior from "./anterior"

import { useAppDispatch } from "../../../core/app/hooks"
import DrawPosterior from "./posterior"
import DrawIPRSpace from "./iprSpace"

const WeFlowSetup = (props) => {
  const dispatch = useAppDispatch()

  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    console.log("Start WeflowSetup")
    gdsPlusSetup.SwitchAutoSetupModule(true)
    return () => {
      console.log("End WeflowSetup")
      gdsPlusSetup.SwitchAutoSetupModule(false)
      const payload = true
      dispatch({ type: "autosetup/setDisable", payload })
    }
  }, [])

  return (
    <Stack direction="column" spacing={1}>
      <FlowTabs
        labels={["New", "Archform", "Anterior", "Posterior", "IPR/Space"]}
      >
        <DrawNewSetup />
        <DrawArchform />
        <DrawAnterior />
        <DrawPosterior />
        <DrawIPRSpace />
      </FlowTabs>
    </Stack>
  )
}
export default WeFlowSetup

/*
function mapStateToProps(state) {
  const { disabled } = state.autosetup
  return { disabled }
}
export default connect(mapStateToProps)(UFlowSetup)
*/
