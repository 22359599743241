import * as React from "react"
import { FC } from "react"
import { Outlet } from "react-router-dom"

import { Grid } from "../components/mui.components"

const Patient: FC = () => {
  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      sx={{ minHeight: "85vh" }}
      minWidth={"lg"}
    >
      <Grid container minWidth={"lg"} sx={{ m: 0 }}>
        <Outlet />
      </Grid>
    </Grid>
  )
}

export default Patient
