import { FC, useEffect, useRef, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import ExternalLink from "../../../assets/images/clinical/ExternalLink.svg"
import {
  FormAutoComplete,
  FormInput,
  UButton,
  UModalPopup,
  UText,
} from "../../../components"
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
} from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import { addNewPatient } from "../../../core/app/slices/patients"
import { AddNewPatientParams } from "../../../core/app/slices/patients/patient.types"
import { setData } from "../../../core/app/slices/patients/patientSlice"
import { RootState } from "../../../core/app/store"
import store from "../../../core/app/store"
import {
  IPatientForm,
  IPatientList,
} from "../../../core/model/interface/IPatient"
import { validations } from "../../../core/utils/validations"
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt"
import { CENETER_COLUMN_ALIGN } from "../../../theme/theme.util"
import { resetPhotoLists} from "../../../core/app/slices/records/photograph/photographSlice"

const PatientForm: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isShowCancel, setIsShowCancel] = useState(false)
  const [duplicatePatientList, setDuplicatePatientList] = useState<
    IPatientList[]
  >([]) //TODO will change once API for finding duplicate patients is ready
  const [isRouteChangeBlocked, setIsRouteChangeBlocked] = useState(false)
  const [dontShowAgain, setDontShowAgain] = useState(
    !!localStorage.getItem(
      `${store.getState().userService.user.uid}-cancelModal-preference`,
    ),
  )
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )

  const { newPatientAPI, patientData, caseDetails } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const { t } = useTranslation("common")

  const {
    control,
    formState: { errors },
    getFieldState,
    trigger,
    setValue,
    watch,
    handleSubmit,
    getValues,
  } = useForm<IPatientForm>({ mode: "onSubmit" })
  const formValues = watch()
  const dayRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  const { patients } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const onSubmit: SubmitHandler<IPatientForm> = (data) => {
    setFormSubmitted(true)
    const payload: AddNewPatientParams = {
      firstname: data.firstName,
      lastname: data.lastName,
      dob: data.year + "-" + data.month + "-" + data.day,
      assigned_to: data.doctor,
    }
    console.log("...submited patient form submission", data)
    dispatch(addNewPatient(payload))
  }

  const validateDate = (day: string) => {
    const month =
      getFieldState("month").isDirty && !getFieldState("month").invalid
        ? parseInt(watch("month"), 10)
        : new Date().getMonth()
    const year =
      getFieldState("year").isDirty && !getFieldState("year").invalid
        ? parseInt(watch("year"), 10)
        : 0
    const date = new Date(year, month - 1, parseInt(day, 10))
    return date.getMonth() === month - 1 && !(date > new Date())
  }

  const CancelModalContent = () => {
    return (
      <>
        <UText variant={"body1"}>{t("newpatient.cancelModal.text")}</UText>
        <Grid sx={{ ml: "10px", mt: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={dontShowAgain}
                onChange={() => setDontShowAgain(!dontShowAgain)}
              />
            }
            label={
              <UText
                variant={"body1"}
                sxProp={{ pt: 1, letterSpacing: "0.15px" }}
              >
                {t("newpatient.cancelModal.userPreference")}
              </UText>
            }
          />
        </Grid>
      </>
    )
  }

  const checkForDuplicatePatient = () => {
    //TODO will change once API for finding duplicate patients is ready
    setDuplicatePatientList([])
    const data = getValues()
    const allKeyhaveValue = Object.keys(data).every(
      (key) => key === "doctor" || data[key],
    )
    if (allKeyhaveValue) {
      const dob = data.year + "-" + data.month + "-" + data.day
      patients.map((patient: IPatientList) => {
        if (
          data.firstName === patient.firstName &&
          data.lastName === patient.lastName &&
          dob === patient.dob
        ) {
          setDuplicatePatientList([...duplicatePatientList, patient])
          return
        }
      })
    }
  }

  useEffect(() => {
    if (formSubmitted) {
      setIsRouteChangeBlocked(false)
      return
    }
    !Object.keys(getValues()).some((key) => !!getValues()[key])
      ? setIsRouteChangeBlocked(false)
      : setIsRouteChangeBlocked(true)
  }, [formValues, formSubmitted])

  useEffect(() => {
    if (newPatientAPI === "pending") {
      //TODO set loader to true
    } else if (newPatientAPI === "succeeded") {
      dispatch(setData({ newPatientAPI: "idle" }))
      dispatch(setAlert(t("newpatient.createSuccess")))
      dispatch(resetPhotoLists())
      if (patientData && caseDetails) {
        navigate(
          `/records/patient/${patientData.id}/case/${caseDetails.id}/scans`,
        )
      }
      //TODO should set loader to false
    }
  }, [newPatientAPI])

  useEffect(() => {
    const handleStorageChange = () => {
      setDontShowAgain(
        !!localStorage.getItem(
          `${store.getState().userService.user.uid}-cancelModal-preference`,
        ),
      )
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  return (
    <>
      <Grid container item sx={{ mt: 3 }}>
        <Grid item sm={6} display={"flex"}>
          <UText variant={"h4"}>{t("newpatient.title")}</UText>
        </Grid>
        <Grid item sm={6} display={"flex"} justifyContent={"end"}>
          <UButton
            variant={"shade"}
            btnType={"button"}
            btnText={t("button.cancel")}
            onClickHandler={() => {
              setIsRouteChangeBlocked(false)
              !Object.keys(getValues()).some((key) => !!getValues()[key]) ||
              dontShowAgain
                ? navigate("/patients")
                : setIsShowCancel(true)
            }}
            size={"small"}
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent={"center"}>
        <Paper
          elevation={0}
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 5 }, borderRadius: 4 }}
        >
          <UText variant={"caption"}>{t(t("newpatient.caption"))}</UText>
          <Box
            component={"form"}
            data-testid={"patient-form"}
            sx={{ ...CENETER_COLUMN_ALIGN, width: "100%", mt: 2 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container maxWidth={"sm"}>
              <UText variant={"h6"}>{t("formfields.name")}</UText>
              <Grid container rowSpacing={0} columnSpacing={1} marginBottom={2}>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    formSxprops={{ my: 1 }}
                    inputLabel={"formfields.firstname"}
                    fieldName={"firstName"}
                    rules={{
                      required: "formfieldErrors.firstnamerequired",
                    }}
                    onBlurChange={() => {
                      if (watch("firstName").replace(/\s/g, "") === "") {
                        setValue("firstName", "")
                      }
                      checkForDuplicatePatient()
                    }}
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    formSxprops={{ my: 1 }}
                    inputLabel={"formfields.lastname"}
                    fieldName={"lastName"}
                    rules={{
                      required: "formfieldErrors.larstnamerequired",
                    }}
                    onBlurChange={() => {
                      if (watch("lastName").replace(/\s/g, "") === "") {
                        setValue("lastName", "")
                      }
                      checkForDuplicatePatient()
                    }}
                    errors={errors}
                    control={control}
                  />
                </Grid>
              </Grid>
              <UText variant={"h6"}>{t("formfields.dateofbirth")}</UText>
              <Grid container rowSpacing={0} columnSpacing={1} marginBottom={2}>
                <Grid item xs={4}>
                  <FormInput
                    formSxprops={{ my: 1 }}
                    inputLabel={"formfields.month"}
                    placeholder={"formfields.monthplaceholder"}
                    fieldName={"month"}
                    rules={{
                      required: "formfieldErrors.monthrequired",
                      pattern: validations.month.pattern,
                    }}
                    onBlurChange={() => {
                      trigger("month").then((valid) => {
                        if (valid) {
                          const fieldValue = watch("month")
                          fieldValue.length === 1
                            ? setValue("month", "0" + fieldValue)
                            : setValue("month", fieldValue)
                        }
                      })
                      checkForDuplicatePatient()
                    }}
                    onInputChange={(value: string, newValue: string) => {
                      if (
                        newValue === "" ||
                        validations.month.inputFormat.test(newValue)
                      ) {
                        if (newValue.length === 2) {
                          setValue("month", newValue)
                          dayRef && dayRef.current && dayRef.current.focus()
                        }
                        return newValue
                      }
                      return value
                    }}
                    onFocusChange={() => {
                      const fieldValue = watch("month")
                      setValue(
                        "month",
                        fieldValue[0] === "0" ? fieldValue[1] : fieldValue,
                      )
                    }}
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    formSxprops={{ my: 1 }}
                    inputRef={dayRef}
                    inputLabel={"formfields.day"}
                    placeholder={"formfields.dayplaceholder"}
                    fieldName={"day"}
                    rules={{
                      required: "formfieldErrors.dayrequired",
                      pattern: validations.day.pattern,
                      validate: (value) => {
                        return (
                          validateDate(value) || validations.day.pattern.message
                        )
                      },
                    }}
                    onBlurChange={() => {
                      trigger("day").then((valid) => {
                        if (valid) {
                          const fieldValue = watch("day")
                          fieldValue.length === 1
                            ? setValue("day", "0" + fieldValue)
                            : setValue("day", fieldValue)
                        }
                      })
                      checkForDuplicatePatient()
                    }}
                    onInputChange={(value: string, newValue: string) => {
                      if (
                        newValue === "" ||
                        validations.day.inputFormat.test(newValue)
                      ) {
                        if (newValue.length === 2) {
                          setValue("day", newValue)
                          yearRef.current && yearRef.current.focus()
                        }
                        return newValue
                      }
                      return value
                    }}
                    onFocusChange={() => {
                      const fieldValue = watch("day")
                      setValue(
                        "day",
                        fieldValue[0] === "0" ? fieldValue[1] : fieldValue,
                      )
                    }}
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    formSxprops={{ my: 1 }}
                    inputRef={yearRef}
                    inputLabel={"formfields.year"}
                    placeholder={"formfields.yearplaceholder"}
                    fieldName={"year"}
                    rules={{
                      required: "formfieldErrors.yearrequired",
                      pattern: validations.year.pattern,
                      validate: (value) =>
                        (Number(value) > 1900 &&
                          Number(value) <= new Date().getFullYear()) ||
                        "formfieldErrors.invalidYear",
                    }}
                    onBlurChange={() => {
                      trigger("year").then((valid) => {
                        if (valid) trigger("day")
                      })
                      checkForDuplicatePatient()
                    }}
                    onInputChange={(value: string, newValue: string) => {
                      return newValue === "" ||
                        validations.year.inputFormat.test(newValue)
                        ? newValue
                        : value
                    }}
                    errors={errors}
                    control={control}
                  />
                </Grid>
                {duplicatePatientList.length !== 0 && (
                  <Box sx={{ display: "flex", flexWrap: "wrap", ml: 3 }}>
                    <UText
                      variant={"caption"}
                      sxProp={{ mr: 1, color: "#616161" }}
                    >
                      {`This patient may already exist:`}
                    </UText>
                    {duplicatePatientList.map((patient, index) => (
                      <Box
                        key={index}
                        sx={{ cursor: "pointer", display: "flex", mr: 0.5 }}
                      >
                        <UText
                          variant={"caption"}
                          color={"primary.main"}
                          sxProp={{
                            textDecoration: "underline",
                            display: "flex",
                            position: "relative",
                            img: {
                              position: "absolute",
                              right: "-16px",
                              bottom: "0px",
                            },
                          }}
                        >
                          {`${patient.firstName} ${patient.lastName}`}
                          <img src={ExternalLink} alt="Exteranl Link"></img>
                        </UText>
                        {index + 1 !== duplicatePatientList.length && (
                          <UText variant={"caption"}>{`,`}</UText>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </Grid>
              <UText variant={"h6"}>{t("formfields.assignto")}</UText>
              <Grid container rowSpacing={0} columnSpacing={1}>
                <Grid item xs={12}>
                  <FormAutoComplete
                    inputLabel={"formfields.alldoctors"}
                    fieldName={"doctor"}
                    rules={{
                      required: "formfieldErrors.assigntorequired",
                    }}
                    options={doctorList}
                    errors={errors}
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent={"flex-end"}>
                <Grid item xs={12} sm={2.5}>
                  <UButton
                    sxProp={{ mt: 1, width: "100%", height: "36px" }}
                    variant={"contained"}
                    btnType={"submit"}
                    btnText={t("button.addPatient")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <UModalPopup
        isAlert={isShowCancel}
        title={
          <UText variant={"h6"}>{t("newpatient.cancelModal.title")} </UText>
        }
        content={<CancelModalContent />}
        sxModalProps={{
          "#titleCntr": {
            padding: "16px 24px",
          },
          "#contentCntr": {
            padding: "20px 24px",
          },
          "#btnListCntr": {
            padding: 1,
            gap: 1,
          },
        }}
        btnList={[
          <UButton
            key={t("newpatient.cancelModal.yes")}
            variant="contained"
            btnText={"Yes"}
            sxProp={{
              minWidth: 58,
              height: 36,
            }}
            onClickHandler={() => {
              if (dontShowAgain) {
                localStorage.setItem(
                  `${
                    store.getState().userService.user.uid
                  }-cancelModal-preference`,
                  "true",
                )
              }
              navigate("/patients")
            }}
          ></UButton>,
          <UButton
            key={t("newpatient.cancelModal.no")}
            variant="shade"
            btnText={"No"}
            sxProp={{
              minWidth: 54,
              height: 36,
              boxShadow:
                "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
            }}
            onClickHandler={() => {
              setDontShowAgain(false)
              setIsShowCancel(false)
            }}
          ></UButton>,
        ]}
      ></UModalPopup>
      <UModalPopup
        isAlert={showPrompt}
        title={
          <UText
            variant={"h6"}
            sxProp={{
              width: "396px",
            }}
          >
            {t("newpatient.backbtnModal.title")}
          </UText>
        }
        sxModalProps={{
          "#titleCntr": {
            padding: "16px 24px",
          },
          "#contentCntr": {
            padding: "20px 24px",
          },
          "#btnListCntr": {
            padding: 1,
            gap: 1,
          },
        }}
        content={
          <UText
            variant={"body1"}
            sxProp={{ display: "block", height: "32px" }}
          >
            {t("newpatient.backbtnModal.text")}
          </UText>
        }
        btnList={[
          <UButton
            key={t("newpatient.backbtnModal.leavePage")}
            variant="shade"
            btnText={t("newpatient.backbtnModal.leavePage")}
            onClickHandler={confirmNavigation}
            sxProp={{
              width: 116,
              height: 36,
              boxShadow:
                "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
            }}
          ></UButton>,
          <UButton
            key={t("newpatient.backbtnModal.stayOnPage")}
            variant="contained"
            btnText={t("newpatient.backbtnModal.stayOnPage")}
            sxProp={{
              width: 136,
              height: 36,
            }}
            onClickHandler={cancelNavigation}
          ></UButton>,
        ]}
      ></UModalPopup>
    </>
  )
}

export default PatientForm
