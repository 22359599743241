import { FC } from "react"
import { Outlet } from "react-router-dom"

import { Box, CssBaseline, Grid } from "../../components/mui.components"

import Footer from "./Footer/Footer"
// import Footer from "./Footer/Footer"
import Header from "./Header/Header"

const MainLayout: FC = () => {
  return (
    <>
      <CssBaseline />
      <Header />
      <Grid
        sx={{
          background: "#F7F8F9",
          flexGrow: 1,
        }}
        container
        component={"main"}
      >
        <Outlet />
      </Grid>
      <Footer />
    </>
  )
}

export default MainLayout
