import { useRef, useState, useEffect } from "react"
import { caseManagement } from "@ulab/wesmileclinical"

const WasmCanvas = (props) => {
  const canvasRef = useRef<any>()
  const isLoading = useRef<boolean>(false)

  const styles: any = {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    overflowY: "hidden",
  }
  useEffect(() => {
    document.oncontextmenu = () => {
      return false
    }
    if (canvasRef.current && isLoading.current === false) {
      isLoading.current = true
      // caseManagement.closeCase()
      // caseManagement.openCaseFromLocalZipFileUrl(
      //   canvasRef.current,
      //   "/testdata/shaoguangyu.zip",
      // )
    }
    caseManagement.setCallbackAfterOpencase(() => {
      isLoading.current = false
    })
  }, [])
  return (
    <canvas
      id="canvas"
      style={styles}
      ref={canvasRef}
      tabIndex={-1}
      onMouseOver={function () {
        canvasRef.current.focus()
      }}
    />
  )
}
export default WasmCanvas
