import { FC } from "react"
import { useTranslation } from "react-i18next"

import { UText } from "../../../components"
import { Grid } from "../../../components/mui.components"

import UploadPano from "./Pano"

const Xrays: FC<{
  showError: boolean
  scanErrors: boolean
  photographErrors: boolean
  xrayErrors: boolean
  setXrayErrors: (value: boolean) => void
}> = ({
  scanErrors,
  photographErrors,
  xrayErrors,
  setXrayErrors,
  showError,
}) => {
  const { t } = useTranslation("common")
  return (
    <>
      <UText
        sxProp={{ display: "flex", justifyContent: "center", mb: 1 }}
        color={"text.primary"}
        variant={"h5"}
      >
        {t("records.xray.title")}
      </UText>
      {scanErrors && showError && (
        <UText
          Text
          sxProp={{ display: "flex", justifyContent: "center", mb: 1 }}
          variant={"caption"}
          color={"error"}
        >
          {t("records.errors.scanError")}
        </UText>
      )}
      {photographErrors && showError && (
        <UText
          Text
          sxProp={{ display: "flex", justifyContent: "center", mb: 1 }}
          variant={"caption"}
          color={"error"}
        >
          {t("records.errors.photographError")}
        </UText>
      )}
      {xrayErrors && showError && (
        <UText
          Text
          sxProp={{ display: "flex", justifyContent: "center" }}
          variant={"caption"}
          color={"error"}
        >
          {t("records.errors.xrayError")}
        </UText>
      )}
      {<UploadPano setXrayErrors={setXrayErrors} />}
    </>
  )
}
export default Xrays
