import http from "../../../global/http"
import { PatientList } from "../../../model/Patient"

import { AddNewPatientParams, PatientQueryparams } from "./patient.types"

export const getPatientList = async (
  orgId: string,
  patientQueryparams: PatientQueryparams,
): Promise<any> => {
  try {
    const { data } = await http.get(`/patient-mgmt/v1/org/${orgId}/patients`, {
      params: { ...patientQueryparams },
    })
    if (data.result && data.result.length > 0) {
      data.result = data.result.map((r: unknown) =>
        new PatientList().Deserialize(r),
      )
    }
    return data
  } catch (err) {
    return err
  }
}

export const fetchPatientByIdService = async (
  orgId: string,
  patientId: string,
): Promise<any> => {
  try {
    const { data } = await http.get(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}?fields=id,first_name,last_name,patient_dob`,
    )
    return data
  } catch (err) {
    return err
  }
}

/**
 * fetchPatientInformation
 * @param orgId
 * @param patientId
 * @returns
 */
export async function fetchPatientInformationByPatientId(
  orgId: string,
  patientId: string,
) {
  try {
    const { data } = await http.get(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}?fields=id,first_name,last_name,status,phone,email,patient_dob,create_ts,sms_optin,dm_patient_id`,
    )
    return data
  } catch (err) {
    return err
  }
}

export const addNewPatientService = async (
  orgId: string,
  addNewPatientParams: AddNewPatientParams,
) => {
  try {
    return await http.post(
      `/shared/v1/org/${orgId}/orchestration/patients/`,
      addNewPatientParams,
    )
  } catch (err) {
    return err
  }
}

export const addNewCaseService = async (
  orgId: string,
  patientId: string,
  payload: any,
) => {
  try {
    return await http.post(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans`,
      payload,
    )
  } catch (err) {
    return err
  }
}

export const updateCaseService = async (
  orgId: string,
  patientId: string,
  caseId: string,
  payload: any,
) => {
  try {
    const { data } = await http.put(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}

export const fetchCaseByCaseIdService = async (
  orgId: string,
  patientId: string,
  caseId: string,
): Promise<any> => {
  console.log("inside serivcefile")
  try {
    const { data } = await http.get(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}?fields=id,create_date,org_name,uuid,assigned_to`,
    )
    return data
  } catch (err) {
    return err
  }
}
