import { FC, useEffect, useState } from "react"
import { computeLabel, ControlProps } from "@jsonforms/core"
import { withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"

const UTemplateSelect: FC<ControlProps> = (props) => {
  const { label, path, schema, errors, data, handleChange } = props
  const [value, setValue] = useState(schema.default || "")

  useEffect(() => {
    setValue(data)
  }, [data])

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setValue(event.target.value as string)
    handleChange(path, event.target.value)
  }
  return (
    <>
      <Box sx={{ my: 1 }}>
        {label && (
          <InputLabel
            sx={{ mb: 1 }}
            error={errors ? true : false}
            id={`${path}-label}`}
          >
            {label}
          </InputLabel>
        )}
        <FormControl fullWidth error={errors ? true : false}>
          <Select
            labelId={`${path}-label}`}
            id={`${path}-select}`}
            value={value}
            onChange={handleChangeSelect}
            displayEmpty
            placeholder={"select"}
          >
            <MenuItem value="">{"Select"}</MenuItem>
            {schema &&
              schema.enum &&
              schema.enum.map((e, index) => {
                return (
                  <MenuItem key={index} value={e}>
                    {e}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
        <Button
          fullWidth
          sx={{
            display: !value ? "none" : "block",
            my: 2,
            borderRadius: "4px",
            background: "#E0E0E0",
            color: "#000000",
            boxShadow:
              "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
        >
          {schema.options.buttonText}
        </Button>
      </Box>
    </>
  )
}

export default withJsonFormsControlProps(UTemplateSelect)
