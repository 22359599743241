import * as React from "react"
import { useState, useEffect } from "react"
import { FC } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import Treat from "./Treat/Treat" // For uSmileAI
import WasmMain from "../../wasm3d/WasmMain" // For WeSmileAI
import { resetCase } from "../../core/app/slices/clinical/clinicalSlice"

const ClinicalModule: FC = () => {
  return (
    <Routes>
      <Route path={"treat"} element={<SwitchSmileAI />} />
    </Routes>
  )
}

import { RootState } from "../../core/app/store"
import { useAppSelector, useAppDispatch } from "../../core/app/hooks"

const SwitchSmileAI = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(resetCase())
  }, [])
  const { weSmileUI } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  return weSmileUI ? <WasmMain /> : <Treat />
}

export default ClinicalModule
