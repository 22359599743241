import type { FC } from "react"
import * as React from "react"
import { useEffect, useState } from "react"

import { connect, useSelector } from "react-redux"

import { RootState } from "../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { setCurArch, setCurView } from "./../Wasm3DServiceSlice"

import {
  AppBar,
  Stack,
  Divider,
  Button,
  Toolbar,
  Typography,
  IconButton,
  Box,
  IconButtonProps,
} from "@mui/material"
import { ToggleButtonGroup, ToggleButton } from "@mui/material"
import { NavToggleButton, NavLabel, NavIconButton } from "./NavCustom"

import { wasmModule } from "@ulab/wesmileclinical"
import { viewControlInTreatment } from "@ulab/wesmileclinical"
import { superimposeModule } from "@ulab/wesmileclinical"

import UpperIcon from "@mui/icons-material/KeyboardArrowUpOutlined"
import LowerIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import RightViewIcon from "@mui/icons-material/KeyboardArrowRightOutlined"
import LeftViewIcon from "@mui/icons-material/KeyboardArrowLeftOutlined"
import FrontViewIcon from "@mui/icons-material/RectangleOutlined"
import BackViewIcon from "@mui/icons-material/ViewCompact"
import SplitViewIcon from "@mui/icons-material/Splitscreen"

import SuperImposeIcon from "@mui/icons-material/JoinLeftOutlined"
import IPRSpaceIcon from "@mui/icons-material/SpaceBarOutlined"
import OccContactIcon from "@mui/icons-material/CompressOutlined"
import GridIcon from "@mui/icons-material/Grid4x4Outlined"
import FeatureIcon from "@mui/icons-material/RadioButtonCheckedOutlined"

import ZoomInIcon from "@mui/icons-material/ZoomInOutlined"
import ZoomOutIcon from "@mui/icons-material/ZoomOutOutlined"

export const TopNav: FC<{}> = (props: any) => {
  const { curArch, curView } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    console.log("arch or view changed!")
    if (!wasmModule.isInit) return
    switch (curArch) {
      case "up":
        viewControlInTreatment.showArchMode("up")
        break
      case "low":
        viewControlInTreatment.showArchMode("low")
        break
      default:
        viewControlInTreatment.showArchMode("all")
    }
  }, [curArch])

  useEffect(() => {
    if (!wasmModule.isInit) return
    switch (curView) {
      case "front":
      default:
        viewControlInTreatment.setViewType("front")
        break
      case "left":
        viewControlInTreatment.setViewType("left")
        break
      case "right":
        viewControlInTreatment.setViewType("right")
        break
      case "back":
        viewControlInTreatment.setViewType("back")
        break
      case "split":
        viewControlInTreatment.setViewType("split")
        break
    }
  }, [curView])

  const handleArch = (
    event: React.MouseEvent<HTMLElement>,
    newArch: string | null,
  ) => {
    dispatch(setCurArch(newArch))
  }

  const [zoomValue, setZoomValue] = useState(1.0)
  function changeSideView(viewName: string) {
    switch (viewName) {
      case "ViewRight":
        dispatch(setCurView("right"))
        break
      case "ViewFront":
        dispatch(setCurView("front"))
        break
      case "ViewLeft":
        dispatch(setCurView("left"))
        break
      case "ViewBack":
        dispatch(setCurView("back"))
        break

      case "ViewSplit":
        dispatch(setCurView("split"))
        break
      case "ViewZoomIn":
        let newValue = Math.min(2.5, zoomValue * 1.1)
        setZoomValue(newValue)
        viewControlInTreatment.zoomWithValueInTreatment(newValue)
        break
      case "ViewZoomOut":
        let newValue1 = Math.max(0.5, zoomValue * 0.9)
        setZoomValue(newValue1)
        viewControlInTreatment.zoomWithValueInTreatment(newValue1)
        break
      default:
        break
    }
  }
  const [SupImpState, setSupImpState] = useState(false)
  const [OccCntcState, setOccCntcState] = useState(false)
  const [IPRSpcState, setIPRSpcState] = useState(false)
  const [HideFeatState, setHideFeatState] = useState(false)
  const [GridState, setGridState] = useState(false)

  function getToolState(toolName: string) {
    switch (toolName) {
      case "SupImp":
        return SupImpState
      case "OccCntc":
        return OccCntcState
      case "IPRSpc":
        return IPRSpcState
      case "HideFeat":
        return HideFeatState
      case "Grid":
        return GridState
    }
    return false
  }

  function onToolClick(toolName: string) {
    const curState = getToolState(toolName)
    const newState = !curState
    switch (toolName) {
      case "SupImp":
        setSupImpState(newState)
        if (wasmModule.isInit) superimposeModule.ShowSuperimpose(newState)
        break
      case "OccCntc":
        setOccCntcState(newState)
        if (wasmModule.isInit) {
          console.log("UI is updated byte new state is ", newState)
          if (newState) {
            wasmModule.statusController.TurnOnBiteCheck()
            wasmModule.stagingcontoler.UpdateByteButtonStatus(true)
          } else {
            wasmModule.statusController.TurnOffBiteCheck()
            wasmModule.stagingcontoler.UpdateByteButtonStatus(false)
          }
        }
        break
      case "IPRSpc":
        setIPRSpcState(newState)
        if (wasmModule.isInit) {
          if (newState) {
            wasmModule.stagingcontoler.UpdateIPRButtonStatus(true)
          } else {
            wasmModule.stagingcontoler.UpdateIPRButtonStatus(false)
          }
          wasmModule.wrapInstance.ShowCollision(newState)
        }
        break
      case "HideFeat":
        setHideFeatState(newState)
        if (wasmModule.isInit)
          wasmModule.statusController.DisplayAttachment(!newState)
        break
      case "Grid":
        setGridState(newState)
        if (wasmModule.isInit) wasmModule.wrapInstance.ShowGridLayer(newState)
        break
    }
    console.log("Tool: ", toolName, newState ? "enabled" : "disabled")
  }
  const [sideView, setSideView] = useState<string | null>(null)
  const handleSideView = (
    event: React.MouseEvent<HTMLElement>,
    newSideView: string | null,
  ) => {
    setSideView(newSideView)
  }

  useEffect(() => {
    console.log("UI is updated")
    if (wasmModule.isInit) {
      // if (IPRSpcState) {
      //   let upStep = stagingManager.wasmStageData.curUpNodeIndex;
      //   let lowStep = stagingManager.wasmStageData.curLowerNodeIndex;
      //   wasmModule.wrapInstance.ShowCollision(false);
      //   wasmModule.wrapInstance.ShowCollision(true);
      //   //wasmModule.stagingcontoler.UpdateIPRButtonStatus(true);
      // }
      // if (OccCntcState) {
      //   wasmModule.statusController.TurnOffBiteCheck();
      //   wasmModule.statusController.TurnOnBiteCheck();
      //   //wasmModule.stagingcontoler.UpdateByteButtonStatus(true);
      // }
    }
    return () => {}
  }, [props.stageData, props.panelData])

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        position: "absolute",
        justifyContent: "center",
        top: "10px",
      }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          backgroundColor: "white",
          boxShadow: 3,
        }}
      >
        <Stack direction="row" spacing={0.5}>
          <ToggleButtonGroup
            orientation="horizontal"
            value={curArch}
            exclusive
            onChange={handleArch}
            aria-label="text alignment"
          >
            <NavToggleButton value="up">
              <UpperIcon />
              <NavLabel textTransform="none" align="center">
                Upper
              </NavLabel>
            </NavToggleButton>
            <NavToggleButton value="low">
              <LowerIcon />
              <NavLabel textTransform="none" align="center">
                Lower
              </NavLabel>
            </NavToggleButton>
          </ToggleButtonGroup>

          <Divider />

          <NavIconButton
            value="ViewRight"
            onClick={() => {
              changeSideView("ViewRight")
            }}
          >
            <RightViewIcon />
            <NavLabel>Right</NavLabel>
          </NavIconButton>
          <NavIconButton
            value="ViewFront"
            onClick={() => {
              changeSideView("ViewFront")
            }}
          >
            <FrontViewIcon />
            <NavLabel>Front</NavLabel>
          </NavIconButton>
          <NavIconButton
            value="ViewBack"
            onClick={() => {
              changeSideView("ViewBack")
            }}
          >
            <BackViewIcon />
            <NavLabel>Back</NavLabel>
          </NavIconButton>
          <NavIconButton
            value="ViewLeft"
            onClick={() => {
              changeSideView("ViewLeft")
            }}
          >
            <LeftViewIcon />
            <NavLabel>Left</NavLabel>
          </NavIconButton>
          <NavIconButton
            value="ViewSplit"
            onClick={() => {
              changeSideView("ViewSplit")
            }}
          >
            <SplitViewIcon />
            <NavLabel>Split</NavLabel>
          </NavIconButton>

          <Divider />

          <NavToggleButton
            value="SupImp"
            selected={SupImpState}
            onClick={() => {
              onToolClick("SupImp")
            }}
          >
            <SuperImposeIcon />
            <NavLabel>SupImp</NavLabel>
          </NavToggleButton>
          <NavToggleButton
            value="IPRSpc"
            selected={IPRSpcState}
            onClick={() => {
              onToolClick("IPRSpc")
            }}
          >
            <IPRSpaceIcon />
            <NavLabel>IPR/Spc</NavLabel>
          </NavToggleButton>
          <NavToggleButton
            value="OccCntc"
            selected={OccCntcState}
            onClick={() => {
              onToolClick("OccCntc")
            }}
          >
            <OccContactIcon />
            <NavLabel>Occ Ctc</NavLabel>
          </NavToggleButton>
          <NavToggleButton
            value="HideFeat"
            selected={HideFeatState}
            onClick={() => {
              onToolClick("HideFeat")
            }}
          >
            <FeatureIcon />
            <NavLabel>Hide Att</NavLabel>
          </NavToggleButton>
          {/*
                    <NavToggleButton value='Grid' selected={GridState} onClick={() => {
                        onToolClick('Grid');
                    }}>
                        <GridIcon />
                        <NavLabel>Grid</NavLabel>
                    </NavToggleButton> */}

          <Divider />
          <NavIconButton
            value="ViewZoomIn"
            onClick={() => {
              changeSideView("ViewZoomIn")
            }}
          >
            <ZoomInIcon />
            <NavLabel>Zoom in</NavLabel>
          </NavIconButton>
          <NavIconButton
            value="ViewZoomOut"
            onClick={() => {
              changeSideView("ViewZoomOut")
            }}
          >
            <ZoomOutIcon />
            <NavLabel>Zoom out</NavLabel>
          </NavIconButton>
        </Stack>
      </Box>
    </div>
  )
}
export default TopNav
