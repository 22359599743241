import { useState } from "react"
import { FlowButton } from "../Custom/FlowCustom"
import { Grid, Button, Typography } from "@mui/material"
import { caseManagement, wasmModule } from "@ulab/wesmileclinical"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material"
import { sagemakerModule } from "@ulab/wesmileclinical/src/core/modules/sagemaker/sagemakerModule"
import { gdsPlusSetup } from "@ulab/wesmileclinical"
let stlFilePath: Record<string, any> = {}

export const tryPresetup = async (donecallback: () => void) => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement
  wasmModule.module.cancel_em_loop()
  // Fill holes for stl file.
  for (let i = 0; i < 2; i++) {
    let preStl = "test/pre/arch_" + (i == 0 ? "u.stl" : "l.stl")
    const pathCheck = wasmModule.module.FS.analyzePath(preStl, true)
    if (!pathCheck.exists)
      // file does not exist
      continue
    let caseMtc = "test/case/Setting Data0/arch_" + (i == 0 ? "u.mtc" : "l.mtc")
    wasmModule.module._autoFillHoles(
      wasmModule.module.allocateUTF8(preStl),
      wasmModule.module.allocateUTF8(caseMtc),
    )
  }
  caseManagement.redoPresetup(canvas, donecallback)
}
async function saveSTLtoFS(arch: string, file: File) {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement
  if (!wasmModule.isInit) {
    await new Promise((resolve, reject) => {
      wasmModule.initWASM(canvas, async (module) => {
        console.log("wasm init over!!!!", canvas, module)
        resolve(true)
      })
    })
  }
  console.log("Read STL and save to WASM: " + file.name)
  try {
    let fn = arch == "up" ? "test/pre/arch_u.stl" : "test/pre/arch_l.stl"
    const ab = await file.arrayBuffer()
    let u8ab = new Uint8Array(ab)
    wasmModule.module.FS.writeFile(fn, u8ab) // Save to WASM FS
    console.log("\t YEEEAH! " + file.name)
  } catch (excp) {
    console.warn("\t NOOOOO! ", excp)
  }
}

async function saveArchScan(arch: string, file: File) {
  console.log("Read STL and save to WASM: " + file.name)
  try {
    let fn = arch == "up" ? "test/pre/arch_u.stl" : "test/pre/arch_l.stl"
    const ab = await file.arrayBuffer()
    let u8ab = new Uint8Array(ab)
    wasmModule.module.FS.writeFile(fn, u8ab) // Save to WASM FS
    console.log("\t YEEEAH! " + file.name)
  } catch (excp) {
    console.warn("\t NOOOOO! ", excp)
  }
}

export const UFlowImportSTL = (props) => {
  const [fileSTL, setFileSTL] = useState(null)
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
    const [file] = files
    setFileSTL(file)
    if (file) {
      saveSTLtoFS(props.arch, file as File)
    }
  }
  return (
    <Grid container spacing={0.5} alignItems="center">
      <Grid item xs={4}>
        <FlowButton variant="contained" component="label">
          <input type="file" hidden onChange={handleFileChange} accept=".stl" />
          {props.children}
        </FlowButton>
      </Grid>
      <Grid item xs={4}>
        STL File for {props.arch} : {fileSTL?.name || ""}
      </Grid>
    </Grid>
  )
}

export const UFlowPresetup = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose()
      }}
    >
      <DialogTitle id="dlg-title">Presetup: Upload STLs</DialogTitle>
      <DialogContent>
        This case is empty. Please upload arch scans and do pre-setup.
        <UFlowImportSTL arch="up">Import STL Up</UFlowImportSTL>
        <UFlowImportSTL arch="low">Import STL Low</UFlowImportSTL>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onCancel()
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.onClose()
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
