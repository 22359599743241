import auxiliares from "../../../../assets/images/clinical/toolBar/auxiliares.svg"
import bolton from "../../../../assets/images/clinical/toolBar/bolton.svg"
import front from "../../../../assets/images/clinical/toolBar/front.svg"
import grid from "../../../../assets/images/clinical/toolBar/grid.svg"
import left from "../../../../assets/images/clinical/toolBar/left.svg"
import low from "../../../../assets/images/clinical/toolBar/low.svg"
import occlusal from "../../../../assets/images/clinical/toolBar/occlusal.svg"
import right from "../../../../assets/images/clinical/toolBar/right.svg"
import space from "../../../../assets/images/clinical/toolBar/spaceCheck.svg"
import split from "../../../../assets/images/clinical/toolBar/split.svg"
import superImpose from "../../../../assets/images/clinical/toolBar/superImpose.svg"
import up from "../../../../assets/images/clinical/toolBar/up.svg"

export const viewTmp = [
  {
    src: up,
    key: "up",
  },
  {
    src: low,
    key: "low",
  },
  {
    src: split,
    key: "split",
  },
]
export const viewTmp1 = [
  {
    src: left,
    key: "left",
  },

  {
    src: front,
    key: "front",
  },
  {
    src: front,
    key: "back",
  },
  {
    src: right,
    key: "right",
  },
]

export const toolsTmp = [
  {
    name: "Space",
    key: "Space",
    src: space,
  },
  {
    name: "Occlusal",
    key: "Occlusal",
    src: occlusal,
  },

  {
    name: "SuperImpose",
    key: "SuperImpose",
    src: superImpose,
  },
  {
    name: "Auxiliaries",
    key: "Auxiliaries",
    src: auxiliares,
  },
  {
    name: "Bolton",
    key: "Bolton",
    src: bolton,
  },
  {
    name: "Grid",
    key: "Grid",
    src: grid,
  },
]
export const toolValues = [
  "Space",
  "Occlusal",
  "Auxiliaries",
  "SuperImpose",
  "Bolton",
  "Grid",
]
