import React, { KeyboardEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, FormControlLabel, Switch, TextField } from "@mui/material"
import { toothMovement } from "@ulab/wesmileclinical"

import leftOne from "../../../../assets/images/clinical/leftOne.svg"
import leftTwo from "../../../../assets/images/clinical/leftTwo.svg"
import rightOne from "../../../../assets/images/clinical/rightOne.svg"
import rightTwo from "../../../../assets/images/clinical/rightTwo.svg"
import { getName } from "../../../../core/utils/commonMethod"

import { panelTmp } from "./column"
import { panelDataProps } from "./type"

import "./toothInfo.scss"
const {
  setCallbackShowToothInfo,
  disPatchButton,
  setRootControl,
  moveWithInputValue,
} = toothMovement

const ToothInfo = () => {
  const { t } = useTranslation("common")
  const [dataSource, setDataSource] = useState<panelDataProps>({
    data: [0, 0, 0, 0, 0, 0],
    flag: false,
    isBridge: false,
    isCrown: false,
    isImplant: false,
    isNoMovement: false,
    isRoot: false,
    isShow: false,
    toothId: 0,
    toothWidth: 0,
  })
  useEffect(() => {
    // toothInfo callback
    setCallbackShowToothInfo((data: panelDataProps) => {
      console.log("data:", data)
      const copy = JSON.parse(JSON.stringify(data))
      // handle data to fixed 2, unit
      for (let i = 0; i < copy.data.length; i++) {
        copy.data[i] = `${copy.data[i].toFixed(2)}${panelTmp[i].unit}`
      }
      setDataSource(copy)
    })
  }, [])
  // click button
  const trigClick = (dir: number, quick: boolean) => {
    disPatchButton(dir, quick)
  }
  //focus input
  const focusInput = (value: string, index: number, unit: string) => {
    value = value.replace(unit, "")
    const obj = JSON.parse(JSON.stringify(dataSource))
    obj.data[index] = +value
    setDataSource(obj)
  }
  // blur input
  const blurInput = (value: string, index: number, unit: string) => {
    value = value.replace(unit, "")
    moveWithInputValue(+value, index)
  }
  // enter input
  const enterInput = (
    event: KeyboardEvent<HTMLInputElement>,
    value: string,
    index: number,
    unit: string,
  ) => {
    if (event.code === "Enter") {
      value = value.replace(unit, "")
      moveWithInputValue(+value, index)
    }
  }
  // change value
  const changeInput = (value: string, index: number) => {
    value = value.replace(/[^\d+.-]/g, "") //清除"数字"、"."、"+"、"-"号以外的字符
    value = value.replace(/^\./g, "") //验证第一个字符是.字
    //value = value.replace(/^[0]/g, "");//验证第一个字符是0字
    value = value.replace(/00/g, "0") //验证第一个是多个0（只保留第一个0, 清除多余的0）
    value = value.replace(/\.{2,}/g, ".") //只保留第一个".", 清除多余的
    value = value.replace(/\-{2,}/g, "-") //只保留第一个"-", 清除多余的
    value = value.replace(/\+{2,}/g, "+") //只保留第一个"+", 清除多余的
    value = value.replace(/\+\-/g, "+") //只保留第一个"+", 清除多余的"-"
    value = value.replace(/\-\+/g, "-") //只保留第一个"-", 清除多余的"+"
    value = value.replace(/[0-9]+\+/g, "") //数字后面不准许输入"+"
    value = value.replace(/[0-9]+\-/g, "") //数字后面不准许输入"-"
    value = value.replace(/\.[0-9]*\+/g, ".") //去除"."号后面的"+"
    value = value.replace(/\.[0-9]*\-/g, ".") //去除"."号后面的"-"
    value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
    value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3") //只能输入两个小数
    const obj = JSON.parse(JSON.stringify(dataSource))
    obj.data[index] = value
    setDataSource(obj)
  }

  return (
    <>
      {dataSource.isShow ? (
        <Box className="toothContainer">
          <div className={"title"}>{t("treat.toothInfo.title")}</div>
          <div className={"topBox"}>
            <div className={"topWrap"}>
              <div className={"nameBox"}>
                <p className={"toothName"}>
                  {`${getName(dataSource.toothId)}`}
                </p>
                <FormControlLabel
                  sx={{
                    margin: 0,
                    "& .MuiFormControlLabel-label": {
                      color: "#fff",
                      fontSize: "16px",
                    },
                    "& .MuiSwitch-track": {
                      background: "rgba(255,255,255,0.38)",
                    },
                  }}
                  control={
                    <Switch
                      onChange={(e) => {
                        console.log(e.target.checked)
                        setRootControl(e.target.checked)
                      }}
                      checked={dataSource.isRoot}
                    />
                  }
                  label={t("treat.toothInfo.root")}
                />
              </div>
              <p className={"width"}>
                <span>Width</span>
                <span
                  className={"widthValue"}
                >{`${dataSource.toothWidth.toFixed(2)}mm`}</span>
              </p>
            </div>
          </div>
          {panelTmp.map((i) => {
            return (
              <div key={i.index} className={"controlBox"}>
                <Button
                  className={"btnCommon"}
                  sx={{
                    backgroundImage: `url(${leftTwo})`,
                  }}
                  onClick={() => {
                    trigClick(i.leftDir, true)
                  }}
                />
                <Button
                  className={"btnCommon"}
                  sx={{
                    backgroundImage: `url(${leftOne})`,
                  }}
                  onClick={() => {
                    trigClick(i.leftDir, false)
                  }}
                />
                <TextField
                  sx={{
                    width: "160px",
                    height: "28px",

                    "& .MuiInput-root": {
                      height: "100%",
                    },
                    "& .MuiInput-root:before": {
                      //   borderColor: "rgba(255,255,255,0.42)",
                      opacity: 0,
                    },
                    "& .MuiInput-root:after": {
                      border: "none",
                    },
                    "& .MuiInputBase-input": {
                      padding: "0",
                      height: "100%",
                      color: "#fff",
                      textAlign: "center",
                      fontSize: "12px",
                      lineHeight: "28px",
                      borderBottom: "1px solid rgba(255,255,255,0.42)",
                    },
                  }}
                  variant="standard"
                  value={`${dataSource.data[i.index]}`}
                  onChange={(e) => {
                    changeInput(e.target.value, i.index)
                  }}
                  onFocus={(e) => {
                    focusInput(e.target.value, i.index, i.unit)
                  }}
                  onBlur={(e) => {
                    blurInput(e.target.value, i.index, i.unit)
                  }}
                  onKeyPress={(e) => {
                    enterInput(e, e.target.value, i.index, i.unit)
                  }}
                />
                <Button
                  className={"btnCommon"}
                  sx={{
                    backgroundImage: `url(${rightOne})`,
                  }}
                  onClick={() => {
                    trigClick(i.rightDir, false)
                  }}
                />
                <Button
                  className={"btnCommon"}
                  sx={{
                    backgroundImage: `url(${rightTwo})`,
                  }}
                  onClick={() => {
                    trigClick(i.rightDir, true)
                  }}
                />
                <div className={"textBox"}>
                  <div className={"left"}>{i.leftLabel}</div>
                  <div className={"right"}>{i.rightLabel}</div>
                </div>
              </div>
            )
          })}
        </Box>
      ) : (
        <div className={"noSelect"}>
          <div className={"msg"}>
            <p className={"title"}>{t("treat.toothInfo.noTooth")}</p>
            <p className={"descrip"}>{t("treat.toothInfo.noSelect")}</p>
          </div>
        </div>
      )}
    </>
  )
}
export default ToothInfo
