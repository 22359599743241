import { FC, useMemo, useState } from "react"
import { JsonSchema, UISchemaElement } from "@jsonforms/core"
import { materialCells, materialRenderers } from "@jsonforms/material-renderers"
import { JsonForms } from "@jsonforms/react"

import {
  UArchesTreat,
  UArchesTreatTester,
  UInput,
  UInputTester,
  URadioGroup,
  URadioGroupTester,
  URadioTextGroup,
  URadioTextGroupTester,
  UTemplateSelect,
  UTemplateSelecTester,
  UTextarea,
  UTextareaTester,
} from "../components"

const JsonFormBuilder: FC<{
  schema: JsonSchema
  uischema: UISchemaElement
  data: any
  isFormSubmitted: boolean
}> = ({ schema, uischema, data, isFormSubmitted }) => {
  const createTranslator =
    (locale: string) => (key: string, defaultMessage: string) => {
      if (key === "error.required") {
        return "This field is required"
      }
      return defaultMessage
    }
  //TODO: In future we can make dynamic for now default as "en"
  const [locale, setLocale] = useState<"en">("en")
  const translation = useMemo(() => createTranslator(locale), [locale])

  const renderers = [
    ...materialRenderers,
    //register custom renderers
    { tester: UArchesTreatTester, renderer: UArchesTreat },
    { tester: UTemplateSelecTester, renderer: UTemplateSelect },
    { tester: URadioGroupTester, renderer: URadioGroup },
    { tester: UInputTester, renderer: UInput },
    { tester: URadioTextGroupTester, renderer: URadioTextGroup },
    { tester: UTextareaTester, renderer: UTextarea },
  ]

  return (
    <JsonForms
      i18n={{ locale: locale, translate: translation }}
      schema={schema}
      uischema={uischema}
      data={data}
      renderers={renderers}
      cells={materialCells}
      validationMode={isFormSubmitted ? "ValidateAndShow" : "NoValidation"}
      // onChange={(data) => {
      //   console.log("...data", data)
      // }}
    />
  )
}

export default JsonFormBuilder
