import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import {
  UButton,
  UFileBox,
  UImagePreview,
  UModalPopup,
  UText,
} from "../../../components"
import { Container, Grid } from "../../../components/mui.components"
import { convertMBtoKB } from "../../../core/utils/formatters"
import AdditionalPhotograph from "../AdditionalPhotograph"

import UploadXrayProcessing from "./uploadXrayProcessing"

const FileUploadSection: FC<{
  uploadSuccess: boolean
  selectedFiles: File[]
  onRemoveFileFromList: (file: File) => void
  onUploadSuccess: (val: boolean) => void
  deleteFile: () => void
  selectedFile: (file: FileList) => void
  id: string
}> = ({
  id,
  uploadSuccess,
  selectedFiles,
  onRemoveFileFromList,
  onUploadSuccess,
  deleteFile,
  selectedFile,
}) => {
  const { t } = useTranslation("common")
  const FILE_ALLOWED_TYPES = ["png", "tiff", "jpg", "jpeg", "bmp"]
  const FILE_ALLOWED_SIZE = 10 //in MB
  const FILE_ALLOWED_LIMIT = 1
  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        sx={{ pt: "10px !important", maxWidth: "396px" }}
      >
        <Container
          maxWidth={"md"}
          sx={{
            padding: "0 !important",
          }}
        >
          {!uploadSuccess && selectedFiles.length > 0 && (
            <Container
              maxWidth={"md"}
              id="processing"
              sx={{
                paddingLeft: "0 !important",
                paddingRight: "0px !important",
                width: "396px",
              }}
            >
              <UploadXrayProcessing
                key={id}
                xrayType={id}
                file={selectedFiles[0]}
                onRemove={onRemoveFileFromList}
                onUploadSuccess={onUploadSuccess}
              />
            </Container>
          )}
          {uploadSuccess && (
            <Container
              maxWidth={"md"}
              id="preview"
              sx={{
                paddingLeft: "0 !important",
                paddingRight: "0px !important",
                width: "400px",
              }}
            >
              <UImagePreview
                showFileName={true}
                files={selectedFiles}
                sxImageProp={{ mb: "0px", mt: "0px" }}
                imageHeight={400}
                imageWidth={400}
                deleteImage={deleteFile}
              />
            </Container>
          )}
          {selectedFiles.length === 0 && (
            <UFileBox
              boxSize={{ height: "216px", width: "396px", mt: "0 !important" }}
              allowedFileExtensions={FILE_ALLOWED_TYPES}
              allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE)}
              fileAcceptType={"image/*"}
              id={id}
              selectedFile={selectedFile}
              fileLimit={FILE_ALLOWED_LIMIT}
              isRequired={false}
              messages={{
                fileNote: t("records.file.filenote", {
                  fileTypes: "JPG, PNG, TIFF, BMP",
                  fileSize: FILE_ALLOWED_SIZE,
                }),
                uploadButton: t("records.file.btnclicktoupload"),
                uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                invalidfileFormat: (
                  <Trans components={{ newLine: <br /> }}>
                    {"records.photograph.invalidfileformat"}
                  </Trans>
                ),
                invalidFileSize: t("records.file.invalidfilesize", {
                  fileSize: FILE_ALLOWED_SIZE,
                }),
                invalidFileLimit: t("records.file.invalidfilelimit", {
                  noOfFiles: FILE_ALLOWED_LIMIT,
                }),
              }}
            />
          )}
        </Container>
      </Grid>
    </>
  )
}

const UploadPano: FC<{
  setXrayErrors: (val: boolean) => void
}> = ({ setXrayErrors }) => {
  const FILE_ALLOWED_TYPES = ["png", "tiff", "jpg", "jpeg", "bmp"]
  const FILE_ALLOWED_SIZE = 10 //in MB
  const FILE_ALLOWED_LIMIT = 4
  const [selectedPanoFiles, setSelectedPanoFiles] = useState<File[]>([])
  const [selectedCephFiles, setSelectedCephFiles] = useState<File[]>([])

  const [completedFiles, setCompletedFiles] = useState<File[]>([])
  const [showAdditionalFilesSection, setShowAdditionalFilesSection] =
    useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [uploadPanoSuccess, setUploadPanoSuccess] = useState<boolean>(false)
  const [uploadCephSuccess, setUploadCephSuccess] = useState<boolean>(false)
  const [addMore, setAddMore] = useState<boolean>(false)

  const { t } = useTranslation("common")

  const updateSelectedFiles = (
    prevFiles: File[],
    newFiles: File[],
    setState: React.Dispatch<React.SetStateAction<File[]>>,
  ): void => {
    setState((prevFiles) => {
      const uniqueFiles = new Set([...prevFiles, ...newFiles])
      return Array.from(uniqueFiles)
    })
  }

  const selectedPanoFile = (files: FileList): void => {
    const newFiles: File[] = Array.from(files)
    updateSelectedFiles(selectedPanoFiles, newFiles, setSelectedPanoFiles)
  }

  const selectedCephFile = (files: FileList): void => {
    const newFiles: File[] = Array.from(files)
    updateSelectedFiles(selectedCephFiles, newFiles, setSelectedCephFiles)
  }

  const removeFileFromList = (
    prevFiles: File[],
    file: File,
    setState: React.Dispatch<React.SetStateAction<File[]>>,
  ): void => {
    setState((prevFiles) => prevFiles.filter((f) => f !== file))
  }

  const onRemoveFileFromList = (file: File): void => {
    removeFileFromList(selectedPanoFiles, file, setSelectedPanoFiles)
  }

  const onRemoveCephFileFromList = (file: File): void => {
    removeFileFromList(selectedCephFiles, file, setSelectedCephFiles)
  }

  const deletePanoFile = (): void => {
    setUploadPanoSuccess(false)
    setSelectedPanoFiles([])
  }

  const deleteCephFile = (): void => {
    setUploadCephSuccess(false)
    setSelectedCephFiles([])
  }

  useEffect(() => {
    if (selectedCephFiles.length && selectedPanoFiles.length) {
      setShowAdditionalFilesSection(true)
    }
  }, [selectedPanoFiles, selectedCephFiles])

  useEffect(() => {
    if (
      !completedFiles.length &&
      !selectedCephFiles.length &&
      !selectedPanoFiles.length
    ) {
      setShowAdditionalFilesSection(false)
    }
  }, [completedFiles])

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent={"space-between"}
        gap={2}
        mt={0}
        pt={1}
        ml={0}
        width={"872px"}
        sx={{ minHeight: "278px" }}
      >
        <Grid
          item
          justifyContent={"flex-start"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ p: "10px !important", ml: "0px !important" }}
        >
          <UText
            color={"text.primary"}
            variant={"h6"}
            sxProp={{ display: "flex", justifyContent: "center" }}
          >
            {t("records.xray.pano")}
          </UText>
          <FileUploadSection
            id="pano"
            uploadSuccess={uploadPanoSuccess}
            selectedFiles={selectedPanoFiles}
            onRemoveFileFromList={onRemoveFileFromList}
            onUploadSuccess={setUploadPanoSuccess}
            deleteFile={deletePanoFile}
            selectedFile={selectedPanoFile}
          />
        </Grid>
        <Grid
          item
          justifyContent={"flex-start"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ p: "10px !important", ml: "0px !important" }}
        >
          <UText
            component={"div"}
            color={"text.primary"}
            variant={"h6"}
            sxProp={{ textAlign: "center" }}
          >
            {t("records.xray.ceph")}
          </UText>
          <FileUploadSection
            id="ceph"
            uploadSuccess={uploadCephSuccess}
            selectedFiles={selectedCephFiles}
            onRemoveFileFromList={onRemoveCephFileFromList}
            onUploadSuccess={setUploadCephSuccess}
            deleteFile={deleteCephFile}
            selectedFile={selectedCephFile}
          />
          {selectedCephFiles && selectedCephFiles.length === 0 && (
            <UText
              component={"div"}
              color={"text.secondary"}
              variant={"caption"}
              sxProp={{
                lineHeight: "166%",
                letterSpacing: "0.4px",
                pt: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {t("records.xray.optional")}
            </UText>
          )}
        </Grid>
      </Grid>
      <AdditionalPhotograph
        isXray={true}
        title={t("records.xray.modalPopupTitle")}
        openModal={openModal}
        setOpenModal={setOpenModal}
        linkText={t("records.xray.addXray") || ""}
        showAdditionalFilesSection={showAdditionalFilesSection}
        completedFiles={completedFiles}
        setCompletedFiles={setCompletedFiles}
      />
    </>
  )
}

export default UploadPano
