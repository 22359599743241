import * as React from "react"
import { useEffect, useState } from "react"
import {
  Card,
  CardContent,
  IconButton,
  CardHeader,
  Grid,
  Divider,
} from "@mui/material"
import {
  Typography,
  Stack,
  ToggleButtonGroup,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import { Rnd } from "react-rnd"

import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"
import { setIsShow, setData, setNewState } from "./ToothInfoSlice"
import { toothMovement } from "@ulab/wesmileclinical"
import { FlowToggleButton } from "../Custom/FlowCustom"

const UFlowToothInfo = (props) => {
  const {
    isShow,
    isCrown,
    isBridge,
    isImplant,
    toothId,
    toothWidth,
    isNoMovement,
    data,
  } = useAppSelector((state: RootState) => state.toothInfoSlice)

  const [toothType, SetToothType] = useState("Normal")
  const { disabled } = useAppSelector(
    (state: RootState) => state.autoSetupSlice,
  )

  const dispatch = useAppDispatch()

  const OnClickedToothCallback = (toothInfos) => {
    console.log("toothInfos :", toothInfos)
    dispatch(setNewState(toothInfos))
  }
  useEffect(() => {
    console.log("Panel Data Changed")
  }, [data, isShow])

  useEffect(() => {
    toothMovement.setCallbackShowToothInfo(OnClickedToothCallback)
  }, [])
  const ToothType = () => {
    const [types, setTypes] = useState(["crown", "implant"])
    const handleTypes = (
      event: React.MouseEvent<HTMLElement>,
      newTypes: string[],
    ) => {
      setTypes(newTypes)
    }

    return (
      <Grid container spacing={0.5} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="body1">ID: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" style={{ textAlign: "right" }}>
            {" "}
            {toothId}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          Type
        </Grid>
        <Grid item xs={10}>
          <FormControlLabel
            control={<Checkbox size="small" checked={isCrown} disabled />}
            label={<Typography sx={{ fontSize: 12 }}>Crown</Typography>}
          />
          <FormControlLabel
            control={<Checkbox size="small" checked={isBridge} disabled />}
            label={<Typography sx={{ fontSize: 12 }}>Bridge</Typography>}
          />
          <FormControlLabel
            control={<Checkbox size="small" checked={isImplant} disabled />}
            label={<Typography sx={{ fontSize: 12 }}>Implant</Typography>}
          />
        </Grid>
      </Grid>
    )
  }

  const ToothMove = () => {
    return (
      <Grid direction="row" container spacing={1} alignItems="center">
        <Grid item xs={6}>
          <Typography>Movement</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" style={{ textAlign: "right" }}>
            {"Tooth: " + toothId}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">M(+)/D(-)</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" style={{ textAlign: "right" }}>
            {data[0].toFixed(2)} mm
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">Tipping</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" style={{ textAlign: "right" }}>
            {data[3].toFixed(2)} &deg;
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">B(+)/L(-)</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" style={{ textAlign: "right" }}>
            {data[1].toFixed(2)} mm
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">Torque</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" style={{ textAlign: "right" }}>
            {data[4].toFixed(2)} &deg;
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">E(+)/I(-)</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" style={{ textAlign: "right" }}>
            {data[2].toFixed(2)} mm
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">Rotation</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" style={{ textAlign: "right" }}>
            {data[5].toFixed(2)} &deg;
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const ToothIPR = () => {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Typography>IPR / Space (mm)</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>To Prev</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Flexible</Typography>
        </Grid>
        <Grid item xs={3}>
          23.0mm
        </Grid>
        <Grid item xs={3}>
          + -
        </Grid>
        <Grid item xs={3}>
          <Typography>To Next</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Flexible</Typography>
        </Grid>
        <Grid item xs={3}>
          23.0mm
        </Grid>
        <Grid item xs={3}>
          + -
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      {isShow && (
        <Rnd
          default={{
            x: 50,
            y: 205,
            width: 360,
            height: 300,
          }}
          enableResizing={{
            top: false,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          bounds="window"
          dragHandleClassName="my-drag-handle"
        >
          <Card sx={{ width: 360 }}>
            <CardHeader
              className="my-drag-handle"
              sx={{ backgroundColor: "lightskyblue", height: 30 }}
              action={
                <IconButton
                  aria-label="settings"
                  sx={{ width: 30, height: 30, top: -10 }}
                  onClick={() => {
                    dispatch(setIsShow(false))
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
              titleTypographyProps={{ variant: "body1" }}
              title="Tooth Info"
            />
            <CardContent>
              <Stack>
                <ToothMove />
              </Stack>
            </CardContent>
          </Card>
        </Rnd>
      )}
    </div>
  )
}
export default UFlowToothInfo
