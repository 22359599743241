import * as React from "react"
import { FC, useEffect } from "react"
import { connect } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { Box, Backdrop, Button, LinearProgress, Alert } from "@mui/material"
import { useRef, useState } from "react"
import ManuStagingPercent from "./CommonPages/ManuStagingPercent"
import OpenCaseDialog from "./OpenCaseDialog"
import UFlowTMChart from "./CommonPages/UFlowTMChart"

import {
  clinicalRequestBasicType,
  getZipsType,
  UploadZips,
} from "../core/app/slices/clinical/clinical.types"
import {
  fetchFileList,
  fetchFileZips,
  getTreatmentInfos,
  uploadCaseZips,
} from "../core/app/slices/clinical/clinicalThunkApi"

import WasmCanvas from "./WasmCanvas"
import TopNav from "./Navs/TopNav"
import RightNavAllFlow from "./Navs/RightNavAllFlow"
import UFlowStageBar from "./Navs/UFlowStageBar"
import UFlowToothInfo from "./CommonPages/UFlowToothInfo"
import { caseManagement, wasmModule } from "@ulab/wesmileclinical"
import {
  resetAction,
  resetCase,
} from "../core/app/slices/clinical/clinicalSlice"
import { useAppSelector, useAppDispatch } from "../core/app/hooks"
import { UFlowAlertDialog } from "./CommonPages/UFlowAlertDialog"
import { uploadZips } from "../core/app/slices/clinical/clinical.service"
import { RootState } from "../core/app/store"
import { setRunPresetup, setSelCaseID } from "./Wasm3DServiceSlice"
import { tryPresetup, UFlowPresetup } from "./CommonPages/UFlowPresetup"
import { sagemakerModule } from "@ulab/wesmileclinical/src/core/modules/sagemaker/sagemakerModule"

const WasmMain: FC = () => {
  const navigate = useNavigate()
  const [loadingMsg, setLoadingMsg] = useState("")
  const [caseDlg, setCaseDlg] = useState(false)
  const [openAlertDlg, setOpenAlertDlg] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  const { fileListReady, fileNames, zipList, zipNames, treatmentList } =
    useAppSelector((state: RootState) => state.clinicalService)
  const { selCaseId, runPresetup, showTMChart } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )

  const handleCaseDlgClose = () => {
    setCaseDlg(false)
    navigate(-1)
  }

  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useAppDispatch()
  const { patientId } = useParams()

  const onClickSaveCase = (e) => {
    caseManagement.getCaseFiles().then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        let params = {
          patientId: patientId,
          caseId: selCaseId,
          zips: zips,
        } as UploadZips
        dispatch(uploadCaseZips(params))
      }
    })
  }

  useEffect(() => {
    console.log("getTreatmentInfos ::")
    setIsLoading(true)
    setLoadingMsg("Get treatment infos.")
    dispatch(resetCase())
    dispatch(getTreatmentInfos({ patientId }))
    sagemakerModule.logMsgCallback = (msg: string) => {
      setLoadingMsg(msg)
    }
    return () => {
      dispatch(resetCase())
      caseManagement.closeCase()
      sagemakerModule.logMsgCallback = undefined
    }
  }, [])

  useEffect(() => {
    if (treatmentList && treatmentList.length > 0) {
      setCaseDlg(true)
      setLoadingMsg("Get case ID.")
    }
  }, [treatmentList])

  useEffect(() => {
    if (selCaseId != "") {
      console.log("Selected case id", selCaseId)
      dispatch(
        fetchFileList({
          orgId: "",
          patientId,
          caseId: selCaseId,
        }),
      )
      setLoadingMsg("Fetch zip file list.")
      setCaseDlg(false) // close sel case dlg
    }
  }, [selCaseId]) // after select case dialog

  useEffect(() => {
    if (fileListReady) {
      if (zipNames.length > 0) {
        dispatch(
          fetchFileZips({
            orgId: "",
            patientId,
            caseId: selCaseId,
            zipNames,
          }),
        )
        setLoadingMsg("Download all zip files.")
      } else {
        // There is no zip files.
        console.log("No zip files found for this case: ", fileNames)
        setCaseDlg(false)
        setAlertMessage("No zip files for case:" + selCaseId.toString())
        setOpenAlertDlg(true)
        // navigate(-1)
      }
    }
  }, [fileListReady]) // Get all files & zip files.

  useEffect(() => {
    if (alertMessage != "" && !openAlertDlg) navigate(-1)
  }, [alertMessage, openAlertDlg])

  useEffect(() => {
    if (Object.keys(zipList).length > 0) {
      console.log("Enough files, zipList:", zipList)
      setLoadingMsg("Open case in 3D.")
      caseManagement
        .openCase(
          document.getElementById("canvas") as HTMLCanvasElement,
          zipList,
        )
        .then((val) => {
          setLoadingMsg("Open case in 3D.")
          setIsLoading(false)
        })
        .catch((error) => {
          setLoadingMsg("Error to open case.")
          setIsLoading(false)
        })
    }
  }, [zipList]) // The downloaded zip files

  useEffect(() => {
    if (runPresetup == "start") {
      setIsLoading(true)
      setLoadingMsg("Close current case.")
      caseManagement.closeCase()
      setLoadingMsg("Run presetup.")
      tryPresetup(() => {
        dispatch(setRunPresetup(""))
        setIsLoading(false)
        setLoadingMsg("Done!")
      })
    }
  }, [runPresetup])

  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
      <WasmCanvas />
      <TopNav />
      <RightNavAllFlow />
      <UFlowStageBar />
      <ManuStagingPercent />
      <UFlowToothInfo />
      <UFlowTMChart open={showTMChart} />
      <Button onClick={onClickSaveCase}>Save Case</Button>
      <OpenCaseDialog
        open={caseDlg}
        onClose={handleCaseDlgClose}
        patientId={patientId}
        txList={treatmentList}
      />
      <UFlowPresetup
        open={openAlertDlg}
        onCancel={() => {
          setOpenAlertDlg(false)
        }}
        onClose={() => {
          setAlertMessage("")
          setOpenAlertDlg(false)
          dispatch(setRunPresetup("start"))
        }}
      >
        {alertMessage}
      </UFlowPresetup>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <Box sx={{ width: "40%" }}>
          {loadingMsg}
          <LinearProgress />
        </Box>
      </Backdrop>
    </Box>
  )
}

export default WasmMain
