import { FC, useEffect, useState } from "react"
import { DragDropContext, Draggable } from "react-beautiful-dnd"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router"
import ClearIcon from "@mui/icons-material/Clear"
import DeleteIcon from "@mui/icons-material/Delete"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { AxiosProgressEvent } from "axios"
import { v4 } from "uuid"

import addFile from "../../../assets/images/AddFilled.png"
import frontImg from "../../../assets/images/front.png" //TODO: only for testing
import { UFileBox, UFileProcessingCard, UText } from "../../../components"
import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
} from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  downloadPhotographs,
  uploadPhotographs,
} from "../../../core/app/slices/records/photograph"
import { RootState } from "../../../core/app/store"
import { convertMBtoKB } from "../../../core/utils/formatters"
import AdditionalPhotograph from "../AdditionalPhotograph"

import StrictModeDroppable from "./StrictModeDroppable"

const FILE_ALLOWED_TYPES = ["png", "tiff", "jpg", "jpeg", "bmp"]
const FILE_ALLOWED_SIZE = 10 //in MB
const FILE_ALLOWED_LIMIT = 30

export interface imgArray {
  label: string
  id: string
  src: string
}
export interface positionArray {
  label: string
  src: string
  position: string
}

const UploadPhotographs: FC<{
  file: File
  onRemove: (f: any) => void
  sequence: number
  isLastElement: boolean
  setIsDragOpen: (val: boolean) => void
  isDragOpen: boolean
}> = ({
  file,
  setIsDragOpen,
  isDragOpen,
  onRemove,
  sequence,
  isLastElement,
}) => {
  const [progressValue, setProgressValue] = useState<number>(0)
  const [progressstatus, setProgresssStatus] = useState<
    "loading" | "complete" | "failed"
  >("loading")

  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()

  useEffect(() => {
    const uniqueId = v4()
    const newFile = new File(
      [file],
      `individual-${uniqueId}.${file.type.split("/")[1]}`,
      { type: file.type },
    )
    const formPayload = new FormData()
    formPayload.append("attachment", newFile)
    formPayload.append("original_file_name", file.name)
    formPayload.append("sequence", (sequence + 1).toString())
    dispatch(
      uploadPhotographs({
        patientId: patientId,
        caseId: caseId,
        formData: formPayload,
        fileName: `individual-${uniqueId}${file.name.substring(
          file.name.lastIndexOf("."),
        )}`,
        orgId: "",
        onFileProcesscallback: function (progress: AxiosProgressEvent): void {
          const { loaded, total } = progress
          setProgresssStatus("loading")

          const percentageProgress = Math.floor((loaded / total) * 100)
          setProgressValue(percentageProgress)
        },
      }),
    )
      .then((action) => {
        if (action.payload.status === 200) {
          setProgresssStatus("complete")
        } else {
          setProgresssStatus("failed")
        }
        if (isLastElement) {
          setIsDragOpen(!isDragOpen)
        }
      })
      .catch((error) => {
        setProgresssStatus("failed")
      })
  }, [file])

  return (
    <>
      <Grid item sm={12}>
        <UFileProcessingCard
          fileName={file.name}
          fileSize={file.size}
          progressValue={progressValue}
          progressstatus={progressstatus}
          onRemove={() => onRemove(file)}
        />
      </Grid>
    </>
  )
}

type FileObject = {
  fileName: string
  original_name: string
  sequence: string
  photo_position: string
}
const Individualphotograph: FC<{
  selectedFileSection: (str: "individual" | "both") => void
  isDragOpen: boolean
  setIsDragOpen: (value: boolean) => void
  individualphotoGraphList: FileObject[]
}> = ({
  selectedFileSection,
  isDragOpen,
  setIsDragOpen,
  individualphotoGraphList,
}) => {
  const addIcon = [
    {
      label: "Add",
      id: "xadd",
      src: addFile,
    },
  ]
  const photosPositions = [
    {
      label: "Front",
      src: "",
      position: "front",
    },
    {
      label: "Profile",
      src: "",
      position: "profile",
    },
    {
      label: "Smile",
      src: "",
      position: "smile",
    },
    {
      label: "Upper Occlusal",
      src: "",
      position: "upperocclusal",
    },
    {
      label: "Right",
      src: "",
      position: "right",
    },
    {
      label: "Anterior",
      src: "",
      position: "anterior",
    },
    {
      label: "Left",
      src: "",
      position: "left",
    },
    {
      label: "Lower Occulusal",
      src: "",
      position: "lowerocculusal",
    },
  ]
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false)
  const { t } = useTranslation("common")
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [indexNumber, setIndexNumber] = useState<number | null>(null)
  const [photoIndex, setPhotoIndex] = useState<number | null>(null)
  const [individualphotoGraphs, setIndividualPhotoGraphs] = useState<File[]>([])
  const [imgArray, setImgArray] = useState<imgArray[]>(addIcon)
  const [positionArray, setPositionArray] =
    useState<positionArray[]>(photosPositions)
  const [step, setStep] = useState<number>(0)
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const { individualPhotos } =
    useAppSelector((state: RootState) => state.PhotosService) || {}

  useEffect(() => {
    if (selectedFiles.length === 0) {
      selectedFileSection("both")
    }
  }, [selectedFiles])

  useEffect(() => {
    console.log(individualphotoGraphList)
    if (
      individualphotoGraphList &&
      individualphotoGraphList.length &&
      selectedFiles.length === 0
    ) {
      individualphotoGraphList.map((individual) => {
        console.log(individual)
        dispatch(
          downloadPhotographs({
            patientId: patientId,
            caseId: caseId,
            fileName: individual.fileName,
            photographType: "individual",
            original_file_name: individual.original_name,
            sequence: individual.sequence,
            position: individual.photo_position,
          }),
        ).then((result) => {
          if (result.payload.status === 200) {
            const file = new File(
              [result.payload.data],
              `${result.meta.arg.original_file_name}`,
              { type: "image/jpg" },
            )
            const obj = {
              label: "",
              src: URL.createObjectURL(file),
              id: "",
              position: result.meta.arg.position,
              sequence: result.meta.arg.sequence,
            }
            setSelectedFiles((o) => [file, ...o])
            setImgArray((o) => [obj, ...o])
          }
        })
      })
      setIsDragOpen(!isDragOpen)
    }
  }, [individualphotoGraphList])

  const selectedIndividualFile = (files: FileList): void => {
    selectedFileSection("individual")
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      setSelectedFiles((o) => [file, ...o])
      const fileobj = {
        label: file.name,
        id: i.toString(),
        src: URL.createObjectURL(file),
      }
      setImgArray((o) => [fileobj, ...o])
    }
  }

  const handleOnDragEnd = (_result) => {
    scrollDown()
    if (!_result.destination || _result.destination.droppableId === "boxes1")
      return
    const [draggedItem] = imgArray.splice(_result.source.index, 1)
    const newArray = [...imgArray]
    setImgArray(newArray)

    const index = photosPositions.findIndex((p) => {
      return p.position === _result.destination.droppableId
    })

    positionArray[index].src = draggedItem.src
  }

  const scrollUp = () => {
    if (imgArray.length - step > 3) {
      const obj = imgArray.shift()
      imgArray.push(obj)
      const newArray = [...imgArray]
      setStep(step + 1)
      setImgArray(newArray)
    }
  }

  const scrollDown = () => {
    if (step !== 0) {
      const obj = imgArray.pop()
      imgArray.unshift(obj)
      const newArray = [...imgArray]
      setImgArray(newArray)
      setStep(step - 1)
    }
  }
  const onRemoveFileFromList = (file: any): void => {
    setSelectedFiles((o) => o.filter((f) => f !== file))
  }

  const getItemStyle = (isDragging, draggableStyle, label) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    border: isDragging
      ? "2px solid rgba(0, 0, 0, 0.60)"
      : label === "Add"
      ? "1px dashed rgba(0, 0, 0, 0.40)"
      : "1px solid rgba(0, 0, 0, 0.40)",

    borderRadius: "4px",
    overflow: "hidden",

    // change background colour if dragging

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver
      ? "rgba(33, 150, 243, 0.08)"
      : "rgba(0, 0, 0, 0.04)",
    border: isDraggingOver
      ? "1px dotted rgba(33, 94, 205, 1)"
      : "1px dashed rgba(0, 0, 0, 0.40)",
  })

  const deleteImage = (index: number) => {
    imgArray.splice(index, 1)
    const newArray = [...imgArray]
    setImgArray(newArray)
  }

  const removeImage = (index: number, p: positionArray) => {
    positionArray[index].src = ""
    const newArray = [...positionArray]
    setPositionArray(newArray)
    const deletedImage = individualphotoGraphs.find(
      (item) => item.label === p.label,
    )
    console.log(deletedImage, "sanjay")
    const array = [deletedImage, ...imgArray]
    console.log(array, "new array")
    setImgArray(array)
  }
  return (
    <>
      {!isDragOpen ? (
        <>
          <UText
            color={"text.primary"}
            sxProp={{ display: "flex", justifyContent: "center" }}
            variant={"h6"}
          >
            {t("records.photograph.individual")}
          </UText>
          <Grid container>
            <Container
              maxWidth={"md"}
              sx={{
                paddingLeft: "0 !important",
                paddingRight: "0 !important",
                ".MuiBox-root": {
                  mt: "0 !important",
                },
              }}
            >
              <UFileBox
                boxSize={{ height: "216px" }}
                allowedFileExtensions={FILE_ALLOWED_TYPES}
                allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE)}
                fileAcceptType={"image/*"}
                id="fileBox"
                selectedFile={selectedIndividualFile}
                fileLimit={FILE_ALLOWED_LIMIT}
                isRequired={false}
                isMultiple={true}
                uploadedFiles={selectedFiles}
                messages={{
                  fileNote: t("records.file.filenote", {
                    fileTypes: "JPG, PNG, TIFF, BMP",
                    fileSize: FILE_ALLOWED_SIZE,
                  }),
                  uploadButton: t("records.file.btnclicktoupload"),
                  uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                  invalidfileFormat: (
                    <Trans components={{ newLine: <br /> }}>
                      {"records.photograph.invalidfileformat"}
                    </Trans>
                  ),
                  invalidFileSize: t("records.file.invalidfilesize", {
                    fileSize: FILE_ALLOWED_SIZE,
                  }),
                  invalidFileLimit: t("records.file.invalidfilelimit", {
                    noOfFiles: FILE_ALLOWED_LIMIT,
                  }),
                }}
              />
            </Container>
          </Grid>
          <UText
            color={"text.secondary"}
            sxProp={{
              display: "flex",
              justifyContent: "center",
              lineHeight: "166%",
              letterSpacing: "0.4px",
            }}
            variant={"caption"}
          >
            {t("records.photograph.individualnote")}
          </UText>

          {selectedFiles.length > 0 &&
            individualphotoGraphList.length === 0 && (
              <Container
                maxWidth={"md"}
                sx={{
                  paddingLeft: "0 !important",
                  paddingRight: "0 !important",
                }}
              >
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {selectedFiles.map((f: File, index: number) => (
                    <UploadPhotographs
                      isLastElement={index === selectedFiles.length - 1}
                      isDragOpen={isDragOpen}
                      setIsDragOpen={setIsDragOpen}
                      key={index}
                      file={f}
                      sequence={index}
                      onRemove={onRemoveFileFromList}
                    />
                  ))}
                </Grid>
              </Container>
            )}
        </>
      ) : (
        <>
          <UText
            color={"#616161"}
            variant={"caption"}
            sxProp={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {t("records.dragAndDropDescriptions")}
          </UText>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Grid
              container
              sm={12}
              md={12}
              item
              sx={{ p: "0 !important", mt: 1, mb: 3 }}
            >
              <Grid
                item
                width={"140px"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      "& :hover": {
                        background: "none !important",
                      },
                    }}
                  >
                    <IconButton
                      onClick={scrollUp}
                      sx={{ p: 0, height: "24px", width: "24px" }}
                    >
                      <KeyboardArrowUpIcon fontSize={"large"} />
                    </IconButton>
                  </Box>
                  <StrictModeDroppable droppableId="boxes1">
                    {(provided: any) => (
                      <Box
                        sx={{
                          overflow: "hidden",
                          height: "426px",
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {...imgArray?.map((image, index) => {
                          return (
                            <Draggable
                              key={index + ""}
                              draggableId={index + ""}
                              index={index}
                              isDragDisabled={image.label === "Add"}
                            >
                              {(provided: any, snapshot: any) => (
                                <Box
                                  component={"div"}
                                  sx={{
                                    m: "10px 10px 20px",
                                    ...getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                      image.label,
                                    ),
                                  }}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <Box
                                    component={"div"}
                                    onClick={(e) => {
                                      if (image.label === "Add") {
                                        setOpenModal(true)
                                      }
                                      e.preventDefault()
                                    }}
                                    onMouseEnter={() => setIndexNumber(index)}
                                    onMouseLeave={() => setIndexNumber(null)}
                                    sx={{
                                      height: "120px",
                                      width: "120px",
                                      borderRadius: "4px",
                                      overflow: "hidden",
                                      background: "rgba(0, 0, 0, 0.04)",
                                      position: "relative",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={image.src}
                                      alt={image.label}
                                      height={
                                        image.label === "Add" ? "21px" : "100%"
                                      }
                                      width={
                                        image.label === "Add" ? "21px" : "100%"
                                      }
                                    />
                                    {indexNumber === index &&
                                      image.label !== "Add" && (
                                        <Box
                                          component={"div"}
                                          sx={{
                                            height: "28px",
                                            width: "28px",
                                            borderRadius: "100px",
                                            background: "#fff",
                                            position: "absolute",
                                            bottom: "5px",
                                            right: "5px",
                                            zIndex: "100",
                                            padding: "4px",
                                          }}
                                          onClick={() => deleteImage(index)}
                                        >
                                          <DeleteIcon
                                            fontSize={"medium"}
                                            sx={{
                                              color: "black",
                                              opacity: "0.56",
                                              height: "20px",
                                              width: "20px",
                                            }}
                                          />
                                        </Box>
                                      )}
                                  </Box>
                                </Box>
                              )}
                            </Draggable>
                          )
                        })}
                        {/* </Slider> */}
                      </Box>
                    )}
                  </StrictModeDroppable>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      "& :hover": {
                        background: "none!important",
                      },
                    }}
                  >
                    <IconButton
                      onClick={scrollDown}
                      sx={{ p: 0, height: "24px", width: "24px" }}
                    >
                      <KeyboardArrowDownIcon fontSize={"large"} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                width={"930px"}
                justifyContent={"center"}
                alignContent={"center"}
                sx={{ height: "435px", display: "flex", alignItems: "center" }}
              >
                <Stack
                  useFlexGap
                  direction="row"
                  flexWrap="wrap"
                  justifyContent="center"
                  padding="10px"
                >
                  {positionArray &&
                    positionArray.map((p, index) => (
                      <StrictModeDroppable
                        droppableId={p.position}
                        key={p.position}
                      >
                        {(provided: any, snapshot: any) => (
                          <Box
                            key={p.position}
                            sx={{
                              textAlign: "center",
                              padding: "10px",
                              mx: 2,
                            }}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <UText
                              variant={"body1"}
                              sxProp={{
                                fontWeight: "500",
                                color: "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              {p.label}
                            </UText>
                            {!p.src && (
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "160px",
                                  height: "160px",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  borderRadius: "4px",
                                  mt: "10px",
                                  ...getListStyle(snapshot.isDraggingOver),
                                }}
                              />
                            )}
                            {p.src && (
                              <Box
                                onMouseEnter={() => setPhotoIndex(index)}
                                onMouseLeave={() => setPhotoIndex(null)}
                                sx={{
                                  display: "flex",
                                  width: "160px",
                                  height: "160px",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  borderRadius: "4px",
                                  border: "1px solid rgba(0, 0, 0, 0.40)",
                                  background: "rgba(0, 0, 0, 0.04)",
                                  mt: "10px",
                                  overflow: "hidden",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={p.src}
                                  alt={p.label}
                                  height={"100%"}
                                  width={"100%"}
                                />
                                {photoIndex === index && (
                                  <Box
                                    component={"div"}
                                    sx={{
                                      height: "28px",
                                      width: "28px",
                                      borderRadius: "100px",
                                      background: "#fff",
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      zIndex: "100",
                                      padding: "4px",
                                    }}
                                    onClick={() => removeImage(index, p)}
                                  >
                                    <ClearIcon
                                      fontSize={"medium"}
                                      sx={{
                                        color: "black",
                                        opacity: "0.56",
                                        height: "20px",
                                        width: "20px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Box>
                                )}
                              </Box>
                            )}
                          </Box>
                        )}
                      </StrictModeDroppable>
                    ))}
                </Stack>
              </Grid>
            </Grid>
          </DragDropContext>
        </>
      )}
      <AdditionalPhotograph
        title={t("records.xray.modalPopupTitle")}
        openModal={openModal}
        setOpenModal={setOpenModal}
        showAdditionalFilesSection={!!selectedFiles.length}
        setCompletedFiles={() => {
          //TODO: will change code once API integration is done
          const addindex = imgArray.findIndex((img) => img.label === "Add")
          setImgArray([
            ...imgArray.slice(0, addindex),
            {
              label: "Front",
              id: "xfront1",
              src: frontImg,
            },
            {
              label: "Front",
              id: "xfront2",
              src: frontImg,
            },
            ...imgArray.slice(addindex),
          ])
        }}
      />
    </>
  )
}

export default Individualphotograph
