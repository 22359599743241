import { FC, useEffect } from "react"
import React, { useState } from "react"
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { viewControlInTreatment } from "@ulab/wesmileclinical"

import { toolsTmp, toolValues, viewTmp, viewTmp1 } from "./columns"
import { archProps, toolBarProps } from "./type"
const {
  setAttachmentVisibility,
  setViewType,
  showArchMode,
  setIPRVisibility,
  setSpacecheckVisibility,
  setGridVisibility,
  setOcclusionVisibility,
  setSuperimposeVisibility,
} = viewControlInTreatment
const ToolBar: FC<toolBarProps> = ({
  preparation,
  boltonPanelShow,
  setBoltonPanelShow,
}) => {
  const [arch, setArch] = useState<archProps>("all")
  const [formats, setFormats] = useState(() => ["Auxiliaries"])
  const viewClick = (item: string) => {
    /* arch */
    if (item === "up" || item === "low") {
      if (arch === item) {
        setArch("all")
        showArchMode("all")
      } else {
        setArch(item)
        showArchMode(item)
      }
    }
    // else if (item === "attachment") {
    //     /* attachment */
    //     setisShowAttachment(!isShowAttachment);
    //     setAttachmentVisibility(!isShowAttachment);
    // } else if (item === "IPR") {
    //     /* ipr */
    //     setisShowIpr(!isShowIpr);
    //     setIPRVisibility(!isShowIpr, stageNumber);
    // }
    else if (
      /* viewType */
      item === "left" ||
      item === "right" ||
      item === "front" ||
      item === "split" ||
      item === "back"
    ) {
      setViewType(item)
    }
  }
  // toggle-buttons status control
  const handleFormat = (event: React.MouseEvent<HTMLElement>, value: any) => {
    const tmp = [].concat(formats)
    if (tmp.includes(value)) {
      tmp.splice(
        tmp.findIndex((item) => item === value),
        1,
      )
    } else {
      tmp.push(value)
    }
    setFormats(tmp)
  }
  // click tool
  const clickTool = (item: string) => {
    if (item === "Bolton") {
      setBoltonPanelShow(!boltonPanelShow)
      return
    }
    const tmp: string[] = [].concat(formats)
    if (tmp.includes(item)) {
      tmp.splice(
        tmp.findIndex((items) => items === item),
        1,
      )
    } else {
      tmp.push(item)
    }
    setFormats(tmp)
  }
  // pass to 3d view tool function
  const passTo3dFunc = () => {
    for (let i = 0; i < toolValues.length; i++) {
      const currentTool = toolValues[i]
      switch (currentTool) {
        case "Occlusal":
          if (formats.includes(currentTool)) {
            setOcclusionVisibility(true)
          } else {
            setOcclusionVisibility(false)
          }
          break
        case "Space":
          if (formats.includes(currentTool)) {
            setSpacecheckVisibility(true)
          } else {
            setSpacecheckVisibility(false)
          }
          break

        case "Auxiliaries":
          if (formats.includes(currentTool)) {
            setAttachmentVisibility(true)
          } else {
            setAttachmentVisibility(false)
          }
          break
        case "SuperImpose":
          if (formats.includes(currentTool)) {
            setSuperimposeVisibility(true)
          } else {
            setSuperimposeVisibility(false)
          }
          break
        case "Grid":
          if (formats.includes(currentTool)) {
            setGridVisibility(true)
          } else {
            setGridVisibility(false)
          }
          break
      }
    }
  }
  useEffect(() => {
    if (!preparation) return
    // TO DO wait wasm update package
    passTo3dFunc()
  }, [formats, preparation])
  return (
    <Box
      id="toolBar"
      sx={{
        height: "48px",
        background: "#37474F",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {viewTmp.map((i) => {
        return (
          <Button
            sx={{
              margin: "12px",
              padding: "0",
              width: "24px",
              height: "24px",
              minWidth: "unset",
              background: `url(${i.src}) no-repeat center`,
            }}
            title={i.key}
            key={i.key}
            onClick={() => {
              viewClick(i.key)
            }}
          >
            <span
              style={{ width: "100%", height: "100%" }}
              title={i.key}
            ></span>
          </Button>
        )
      })}
      <div
        style={{
          width: "1px",
          height: "48px",
          background: "rgba(255,255,255,0.12)",
        }}
      ></div>
      {viewTmp1.map((i) => {
        return (
          <Button
            sx={{
              margin: "12px",
              padding: "0",
              width: "24px",
              height: "24px",
              minWidth: "unset",
              background: `url(${i.src}) no-repeat center`,
            }}
            title={i.key}
            key={i.key}
            onClick={() => {
              viewClick(i.key)
            }}
          >
            <span
              style={{ width: "100%", height: "100%" }}
              title={i.key}
            ></span>
          </Button>
        )
      })}
      <div
        style={{
          width: "1px",
          height: "48px",
          background: "rgba(255,255,255,0.12)",
        }}
      ></div>
      {/* <ToggleButtonGroup
        color="primary"
        value={formats}
        sx={{
          "& .MuiToggleButton-root": {
            color: "#39f",
            background: "#fff",
            margin: "0 10px",
          },
          "& .MuiToggleButton-root:hover": {
            background: "#fff",
          },
          "& .MuiToggleButton-root.Mui-selected": {
            color: "#fff",
            background: "#39f",
          },
          "& .MuiToggleButton-root.Mui-selected:hover": {
            background: "#39f",
          },
        }}
      >
        {toolsTmp.map((i) => {
          return (
            <ToggleButton value={i.key} key={i.key} onClick={handleFormat}>
              {i.name}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup> */}
      {toolsTmp.map((i) => {
        return (
          <Button
            sx={{
              margin: "12px",
              padding: "0",
              width: "24px",
              height: "24px",
              minWidth: "unset",
              background: `url(${i.src}) no-repeat center`,
            }}
            value={i.key}
            key={i.key}
            onClick={() => {
              clickTool(i.key)
            }}
          >
            <span
              style={{ width: "100%", height: "100%" }}
              title={i.key}
            ></span>
          </Button>
        )
      })}
    </Box>
  )
}
export default ToolBar
