import { wasmModule } from "../wasm/wasmModule";
import { GlobalEvents, globalEvents } from "../../../utils/globalevents";

export type ReportIPRData = Record<
  number,
  {
    stage: number;
    total: string;
  }
>;

class IprModule {
  private isOpen = false;
  private stageIndexForShow = 0;

  setIPRVisibility(isVisible, stageIndexForShow: number) {
    if (!wasmModule.isInit) return;
    this.isOpen = isVisible;
    this.stageIndexForShow = stageIndexForShow;
    wasmModule.statusController.DisplayArchIPR(
      wasmModule.statusController.GetCurrentDiplayArch(),
      this.isOpen,
      this.stageIndexForShow
    );
  }
  updateIPRVisibility() {
    if (this.isOpen)
      wasmModule.statusController.DisplayArchIPR(
        wasmModule.statusController.GetCurrentDiplayArch(),
        this.isOpen,
        this.stageIndexForShow
      );
  }
  getIPRReport(): ReportIPRData {
    const iprReport: ReportIPRData = {};
    const iprup = wasmModule.statusController.GetIPRReport(0);
    // const toothupIds = wasmModule.mouthModel.GetArchModel(wasmModule.module.ArchType.UpArch).GetToothIds();
    const size = iprup.size();
    for (let i = 0; i < size; i++) {
      const iprData = iprup.get(i);
      const toothipr = iprData.value;
      const toothid1 = iprData.toothId1;
      const toothid2 = iprData.toothId2;
      const toothid = toothid1 < toothid2 ? toothid1 : toothid2;
      if (toothipr && toothipr !== 0) {
        const ipr = {
          stage: 0,
          total: toothipr,
        };
        iprReport[toothid] = ipr;
      }
    }
    const iprlow = wasmModule.statusController.GetIPRReport(1);
    // const toothlowIds = wasmModule.mouthModel.GetArchModel(wasmModule.module.ArchType.DownArch).GetToothIds();
    const sizelow = iprlow.size();
    for (let i = 0; i < sizelow; i++) {
      const iprData = iprlow.get(i);
      const toothipr = iprData.value;
      const toothid1 = iprData.toothId1;
      const toothid2 = iprData.toothId2;
      const toothid = toothid1 < toothid2 ? toothid1 : toothid2;
      if (toothipr !== 0) {
        const ipr = {
          stage: 0,
          total: toothipr,
        };
        iprReport[toothid] = ipr;
      }
    }
    return iprReport;
  }
}

export const iprModule = new IprModule();

globalEvents.on(GlobalEvents.VIEW_SWITCH_TYPE, (type: string) => {
  console.log("updateIPRVisibility......", type);
  iprModule.updateIPRVisibility();
});
