import { FC } from "react"
import { MenuItem, MenuList, Paper, Popper } from "@mui/material"

import { dentalChartMenuList } from "./toothObject"

interface propsType {
  menuProps: { open: boolean; position: number[] }
  setMenuProps: (object: { open: boolean; position: number[] }) => void
  setSelectedItem: (item: string) => void
  appliedTeeth: { teeth: string; checked: string }[]
  selectedTeethId: string
}
const Menu: FC = ({
  menuProps,
  setMenuProps,
  setSelectedItem,
  appliedTeeth,
  selectedTeethId,
}: propsType) => {
  const getStyle = (item: string) => {
    let res = {}
    appliedTeeth.forEach(({ teeth, checked }) => {
      if (
        teeth === selectedTeethId &&
        item.indexOf("(" + checked + ")") !== -1
      ) {
        res = {
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 600,
          color: "rgba(0, 0, 0, 0.87)",
          backgroundColor: "rgba(33, 150, 243, 0.04)",
        }
      }
    })
    return res
  }

  return (
    <Popper
      open={menuProps.open}
      sx={{
        top: `${menuProps.position[1]}px  !important`,
        left: `${menuProps.position[0]}px  !important`,
      }}
    >
      <Paper>
        <MenuList>
          {dentalChartMenuList.map((item) => {
            return (
              <MenuItem
                key={item}
                onClick={() => {
                  setMenuProps({ open: false, position: [0, 0] })
                  setSelectedItem(item)
                }}
                sx={getStyle(item)}
              >
                {item}
              </MenuItem>
            )
          })}
        </MenuList>
      </Paper>
    </Popper>
  )
}
export default Menu
