import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Tab, Tabs } from "@mui/material"

import ToothInfo from "../ToothInfo/toothInfo"

import "./rightPanel.scss"

const RightPanel: FC = () => {
  const [currentPanel, setCurrentPanel] = useState(0)
  const { t } = useTranslation("common")
  return (
    <>
      <Tabs
        value={currentPanel}
        onChange={(event, value) => {
          setCurrentPanel(value)
        }}
        sx={{
          minHeight: "unset",
          height: "40px",
          paddingLeft: "16px",
          borderBottom: "1px solid rgb(69, 90, 100)",
          "& .MuiTab-textColorPrimary": {
            color: "#fff",
            fontSize: "13px",
            fontWeight: "500",
            position: "relative",
            padding: "9px 16px",
            minHeight: "40px",
            minWidth: "unset",
          },
          "& .Mui-selected": {
            color: "#90CAF9 !important",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#90CAF9",
          },
        }}
      >
        <Tab value={0} label={<span>{t("treat.toothInfo.setUp")}</span>} />

        <Tab
          value={1}
          label={<span>{t("treat.toothInfo.auxullaries")}</span>}
        />
      </Tabs>
      <div
        className={"tabContent"}
        style={{ visibility: currentPanel === 0 ? "visible" : "hidden" }}
      >
        <ToothInfo />
      </div>
      <div
        className={"tabContent"}
        style={{ visibility: currentPanel === 1 ? "visible" : "hidden" }}
      >
        {/* no select tooth */}
        {/* <div className={"noSelect"}>
          <div className={"msg"}>
            <p>{t("treat.toothInfo.noTooth")}</p>
            <p>{t("treat.toothInfo.noSelect")}</p>
          </div>
        </div> */}
        auxullaries
      </div>
      {/* no edit message */}
      {/* <div className={"noToothBox"}>
        <div className={"tabs"}>
          <div className={"text"}>{t("treat.toothInfo.setUp")}</div>
          <div className={"text"}>{t("treat.toothInfo.auxullaries")}</div>
        </div>
        <div className={"msg"}>
          <p>{t("treat.toothInfo.noTooth")}</p>
          <p>{t("treat.toothInfo.noEdit")}</p>
        </div>
      </div> */}
    </>
  )
}
export default RightPanel
