import { createSlice } from "@reduxjs/toolkit"

interface IUserServiceState {
  user: {
    authorized_roles: []
    global_permissions: []
    email: string
    name: string
    current_orgId: string
    user_id: string
    uid: string
  }
}

const initialState: IUserServiceState = {
  user: {
    authorized_roles: [],
    global_permissions: [],
    email: "",
    name: "",
    current_orgId: "",
    user_id: "",
    uid: "",
  },
}

export const userServiceSlice = createSlice({
  name: "userService",
  initialState,
  reducers: {
    updateLoggedInUser(state, action) {
      const { claims } = action.payload
      try {
        if (claims) {
          const userObj: IUserServiceState = {
            user: {
              authorized_roles: claims.authorized_roles,
              global_permissions: claims.global_permissions,
              email: claims.email,
              name: claims.name,
              current_orgId: claims.authorized_roles[0].org_id,
              user_id: claims.ulab_userId,
              uid: claims.uid,
            },
          }
          if (!userObj.user.current_orgId) {
            throw new Error("orgId not found in authorized_roles")
          }
          state.user = userObj.user
        }
      } catch (err) {
        console.log("error on update logged in user object", err)
      }
    },
    clearLoggedInUser(state) {
      state.user = {
        authorized_roles: [],
        global_permissions: [],
        email: "",
        name: "",
        current_orgId: "",
        user_id: "",
        uid: "",
      }
    },
  },
})

export const { updateLoggedInUser, clearLoggedInUser } =
  userServiceSlice.actions
