import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Slider from "react-slick"

import preview from "../../assets/images/clinical/leanne_hamilton_ceph.png"
import left from "../../assets/images/clinical/left.jpg"
import tooth from "../../assets/images/clinical/Preview.png"
import stl from "../../assets/images/cube-outline.svg"
import zoomOut from "../../assets/svgs/arrow-expand-all.svg"
import { UImagePreview } from "../../components"
import { Box, IconButton } from "../../components/mui.components"
import Preview from "../../components/Preview/preview"
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "../../ui-component/mui.icons"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./photoList.scss"

interface ArrowProps {
  onClick: () => void
  currentSlide: number
  slideCount: number
}

const NextArrow: FC<ArrowProps> = ({ onClick, currentSlide, slideCount }) => {
  return (
    <Box component={"div"} className="next-arrow">
      <IconButton
        onClick={onClick}
        disabled={currentSlide + 3 === slideCount}
        sx={{
          position: "absolute",
          right: "21px",
          top: "50%",
          transform: "translateY(-50%)",
          p: 0,
        }}
      >
        <KeyboardArrowRight
          fontSize="large"
          sx={{ height: "35px", width: "35px" }}
        />
      </IconButton>
    </Box>
  )
}

const PrevArrow: FC<ArrowProps> = ({ onClick, currentSlide }) => {
  return (
    <Box component={"div"} className="prev-arrow">
      <IconButton
        onClick={onClick}
        disabled={currentSlide === 0}
        sx={{
          position: "absolute",
          left: "34px",
          top: "50%",
          transform: "translateY(-50%)",
          p: 0,
        }}
      >
        <KeyboardArrowLeft
          fontSize="large"
          sx={{ height: "35px", width: "35px" }}
        />
      </IconButton>
    </Box>
  )
}

interface PhotoListProps {
  zips: Record<string, File>
  setSelectedPreview: (value: string) => void
}

const PhotoList: FC<PhotoListProps> = ({ zips, setSelectedPreview }) => {
  const { t } = useTranslation("common")
  const [prevImage, setPrevImage] = useState<File[]>([])
  const [selectedImage, setSelectedImage] = useState<string>("")
  const [isStlPreview, setIsstlPreview] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<number>(0)

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  const handleImageClick = async (image: string, index: number) => {
    if (index === 0) {
      setIsstlPreview(true)
    } else {
      setIsstlPreview(false)
      const response = await fetch(image)
      const blob = await response.blob()
      const file = new File([blob], `Image`, { type: blob.type })
      setPrevImage([file])
      setSelectedImage(image)
    }
    setCurrentImage(index)
  }

  useEffect(() => {
    setIsstlPreview(true)
  }, [])

  const [upperStl, setUpperStl] = useState({ data: null, action: "init" })
  const [lowerStl, setLowerStl] = useState({ data: null, action: "init" })

  return (
    <Box>
      <Box
        className="preview"
        sx={{ position: "relative", width: "466px", height: "466px" }}
      >
        {isStlPreview ? (
          <Preview
            zips={zips}
            upperStl={upperStl}
            lowerStl={lowerStl}
            setUpperStl={setUpperStl}
            setLowerStl={setLowerStl}
          />
        ) : (
          <>
            <UImagePreview
              imageHeight={466}
              imageWidth={466}
              files={prevImage}
            />
            <IconButton
              onClick={() => setSelectedPreview(selectedImage)}
              className="zoom"
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "#EEE",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "4px",
                zIndex: 1,
                display: "none",
                padding: "7px",
              }}
            >
              <Box
                component={"img"}
                src={zoomOut}
                alt={"zoomout"}
                loading="lazy"
                sx={{ cursor: "pointer" }}
              />
            </IconButton>
          </>
        )}
      </Box>

      <Box id="slick" component={"div"} sx={{ paddingLeft: "18px", mt: 2 }}>
        <Slider {...settings}>
          {[stl, preview, left, tooth].map((file, index) =>
            index === 0 ? (
              <Box
                className={"slick-slider"}
                key={index}
                sx={{ p: "8px", m: 0 }}
                onClick={() => handleImageClick(file, index)}
              >
                <Box
                  component={"div"}
                  id="imagecontainer"
                  sx={{
                    borderRadius: "4px",
                    outline:
                      currentImage === index
                        ? "3px solid #1E88E5"
                        : "3px solid transparent",
                  }}
                >
                  <Box
                    id="imagebox"
                    sx={{
                      width: "120px",
                      height: "120px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#455A64",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      style={{
                        height: "48px",
                        width: "48px",
                      }}
                      src={file}
                      alt={"stl"}
                    />
                    <Box component={"div"} className="preview-text">
                      {t("uassist.stlPreview")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                className={"slick-slider"}
                key={index}
                sx={{ p: "5px", m: 0 }}
              >
                <img
                  style={{
                    borderRadius: "4px",
                    border: "3px solid",
                    borderColor:
                      currentImage === index ? "#1E88E5" : "transparent",
                  }}
                  src={file}
                  alt={index.toString()}
                  onClick={() => handleImageClick(file, index)}
                />
              </Box>
            ),
          )}
        </Slider>
      </Box>
    </Box>
  )
}

export default PhotoList
