import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { caseManagement, preview } from "@ulab/wesmileclinical"

import {
  UButton,
  UFileBox,
  UFileProcessingCard,
  UModalPopup,
  UText,
} from "../../../components"
import Preview from "../../../components/Preview/preview"
import {
  getZipsType,
  UploadZips,
} from "../../../core/app/slices/clinical/clinical.types"
import {
  DOWNLOAD_FILEZIP,
  resetAction,
} from "../../../core/app/slices/clinical/clinicalSlice"
import {
  fetchFileZips,
  uploadCaseZips,
} from "../../../core/app/slices/clinical/clinicalThunkApi"
import { Box, Checkbox, Grid } from "../../../ui-component/mui.components"

import {
  file,
  fileBox,
  fileBoxContent,
  previewBox,
  stltitle,
  title,
  zone,
  zoneBox,
} from "./Scans.style"

/**
 * a component for upload stl & show the teeth model
 * @param downLoadZips: 下载stl文件
 * @param uploadScans: 上传stl文件
 * @param resetStoreData: 重置store数据
 * @param isClickNext: click the next button
 * @returns scans compoent
 */

interface scansType {
  downLoadZips: (payload: object) => void
  resetStoreData: () => void
  setScanErrors: (value: boolean) => void
  flagClickNext: boolean
  zipList: FileList
  uploadZips: (payload: UploadZips) => void
  isSuccessUploadZips: boolean
  current_orgId: string
  steps: { id: string; lable: string; to: string }[]
}
const showBothScansPopup: boolean = localStorage.getItem("showBothScansPopup")
  ? localStorage.getItem("showBothScansPopup") === "1"
  : true

const Scans: FC = ({
  downLoadZips,
  resetStoreData,
  setScanErrors,
  flagClickNext,
  zipList,
  uploadZips,
  isSuccessUploadZips,
  current_orgId,
  steps,
}: scansType) => {
  const { t } = useTranslation("common")

  const [upperStl, setUpperStl] = useState({ data: null, action: "init" })
  const [lowerStl, setLowerStl] = useState({ data: null, action: "init" })
  const [holePopup, setholePopup] = useState(false)
  const [nextPopup, setnextPopup] = useState(false)
  const navigate = useNavigate()
  const { caseId, patientId } = useParams()

  useEffect(() => {
    downLoadZips({
      orgId: current_orgId,
      patientId,
      caseId,
    })

    return () => {
      resetStoreData()
      setholePopup(false)
      setnextPopup(false)
    }
  }, [])

  const gotoNextStep = () => {
    caseManagement.getCaseFiles().then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        uploadZips({
          zips,
          patientId,
          caseId,
          orgId: current_orgId,
        })
      }
    })
  }

  useEffect(() => {
    if (isSuccessUploadZips) {
      navigate(steps[1].to)
    }
  }, [isSuccessUploadZips])

  useEffect(() => {
    if (typeof flagClickNext === "undefined") return
    if (upperStl.action === "download" && lowerStl.action === "download") {
      navigate(steps[1].to)
      return
    }
    if (upperStl.data && lowerStl.data) {
      preview.saveStlToMtc(upperStl.data, lowerStl.data)
      gotoNextStep()
    } else if (upperStl.data || lowerStl.data) {
      preview.saveStlToMtc(upperStl.data, lowerStl.data)
      setnextPopup(true)
    } else {
      setScanErrors(true)
      navigate(steps[1].to)
    }
  }, [flagClickNext])

  return (
    <Grid>
      <UModalPopup
        sxModalProps={{
          ".MuiDialog-paper": {
            maxWidth: 444,
          },
          "#titleCntr": {
            padding: "16px 24px",
          },
          "#contentCntr": {
            padding: "20px 24px",
            height: 192,
          },
          "#btnListCntr": {
            padding: 1,
            gap: 1,
          },
        }}
        title={<UText variant={"h6"}>{t("records.scans.holePopupTile")}</UText>}
        content={
          <Box>
            <span>{t("records.scans.holePopup1")}</span>
            <br />
            <br />
            <span>{t("records.scans.holePopup2")}</span>
          </Box>
        }
        isAlert={holePopup}
        btnList={[
          <UButton
            variant={"contained"}
            btnText={t("button.ok")}
            sxProp={{ width: 53, height: 36 }}
            key={"hole"}
            onClickHandler={() => setholePopup(false)}
          />,
        ]}
      />
      <UModalPopup
        sxModalProps={{
          ".MuiDialog-paper": {
            maxWidth: 444,
          },
          "#titleCntr": {
            padding: "16px 24px",
          },
          "#contentCntr": {
            padding: "20px 24px",
            height: 182,
          },
          "#btnListCntr": {
            padding: 1,
            gap: 1,
          },
        }}
        title={<UText variant={"h6"}>{t("records.scans.nextPopupTile")}</UText>}
        content={
          <>
            <Grid container flexDirection={"column"}>
              <UText variant={"body1"}>
                {t("records.scans.nextPopup1")}
                <br />
                <br />
                {t("records.scans.nextPopup2")}
              </UText>
            </Grid>
            <Grid sx={{ height: 38 }}>
              <Checkbox
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    localStorage.setItem("showBothScansPopup", "0")
                  } else {
                    localStorage.setItem("showBothScansPopup", "1")
                  }
                }}
              />
              <UText variant={"body1"}>{t("popup.showAgain")}</UText>
            </Grid>
          </>
        }
        isAlert={showBothScansPopup && nextPopup}
        btnList={[
          <UButton
            variant={"contained"}
            btnText={"YES"}
            key={"yes"}
            sxProp={{ minWidth: 58, height: 36 }}
            onClickHandler={gotoNextStep}
          />,
          <UButton
            variant={"shade"}
            btnText={"NO"}
            key={"no"}
            onClickHandler={() => {
              setnextPopup(false)
            }}
            sxProp={{ minWidth: 54, height: 36 }}
          />,
        ]}
      />
      <UText sxProp={title} variant={"h5"}>
        {t("records.scans.title")}
      </UText>
      <Grid container sx={previewBox}>
        <Box style={fileBox}>
          <Grid sx={{ ...zone }}>
            <UText
              sxProp={{
                ...stltitle,
              }}
              variant={"h6"}
            >
              {"Upper"}
            </UText>
            {upperStl.action === "stl" || upperStl.action === "download" ? (
              <UFileProcessingCard
                fileName={
                  upperStl.action === "stl"
                    ? upperStl.data?.name
                    : "Uploaded Scan"
                }
                fileSize={upperStl.data?.size}
                progressValue={100}
                progressstatus={"complete"}
                onRemove={() => {
                  setUpperStl({ data: null, action: "delete" })
                }}
              ></UFileProcessingCard>
            ) : (
              <UFileBox
                id={"Upper"}
                allowedFileExtensions={["stl"]}
                allowedfileSize={10240}
                selectedFile={(file) => {
                  setScanErrors(false)

                  setUpperStl({ data: file[0], action: "stl" })
                }}
                fileAcceptType={".stl"}
                boxSize={file}
                fileBoxContent={fileBoxContent}
                isRequired={false}
                messages={{
                  fileNote: t("records.scans.fileNote"),
                  uploadButton: t("records.scans.uploadButton"),
                  uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                  invalidfileFormat: t("records.scans.invalidfileFormat"),
                  invalidFileSize: t("records.scans.invalidFileSize"),
                }}
                fileLimit={1}
              ></UFileBox>
            )}
          </Grid>
          <Grid sx={{ ...zone }}>
            <UText sxProp={{ ...stltitle }} variant={"h6"}>
              {"Lower"}
            </UText>
            {lowerStl.action === "stl" || lowerStl.action === "download" ? (
              <UFileProcessingCard
                fileName={
                  lowerStl.action === "stl"
                    ? lowerStl.data?.name
                    : "Uploaded Scan"
                }
                fileSize={lowerStl.data?.size}
                progressValue={100}
                progressstatus={"complete"}
                onRemove={() => {
                  setLowerStl({ data: null, action: "delete" })
                }}
              ></UFileProcessingCard>
            ) : (
              <UFileBox
                id={"Lower"}
                allowedFileExtensions={["stl"]}
                allowedfileSize={10240}
                selectedFile={(file) => {
                  setScanErrors(false)
                  setLowerStl({ data: file[0], action: "stl" })
                }}
                fileAcceptType={".stl"}
                boxSize={file}
                fileBoxContent={fileBoxContent}
                isRequired={false}
                messages={{
                  fileNote: t("records.scans.fileNote"),
                  uploadButton: t("records.scans.uploadButton"),
                  uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                  invalidfileFormat: t("records.scans.invalidfileFormat"),
                  invalidFileSize: t("records.scans.invalidFileSize"),
                }}
                fileLimit={1}
              ></UFileBox>
            )}
          </Grid>
        </Box>
        <Box style={zoneBox}>
          <Preview
            upperStl={upperStl}
            lowerStl={lowerStl}
            fillholeCallback={(isSuccess: boolean) => {
              if (!isSuccess) {
                setholePopup(true)
              }
            }}
            placeHolder={t("records.scans.previewPlaceHolder")}
            zips={zipList}
            setUpperStl={setUpperStl}
            setLowerStl={setLowerStl}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default connect(
  ({ clinicalService, userService }) => {
    const { zipList, isSuccessUploadZips } = clinicalService
    const {
      user: { current_orgId },
    } = userService
    return { zipList, isSuccessUploadZips, current_orgId }
  },
  (dispatch) => {
    return {
      downLoadZips: ({
        orgId,
        patientId,
        caseId,
        zipNames = DOWNLOAD_FILEZIP,
      }: getZipsType) => {
        dispatch(
          fetchFileZips({
            orgId,
            patientId,
            caseId,
            zipNames,
          }),
        )
      },
      uploadZips: (payload: UploadZips) => {
        dispatch(uploadCaseZips({ ...payload }))
      },
      resetStoreData: () => {
        dispatch(resetAction())
      },
    }
  },
)(Scans)
