import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Button, Tab } from "@mui/material"

import DentalChart from "../../../../components/DentalChart/DentalChart"
import Drag from "../../../../components/Drag/Drag"

import DentalMenu from "./components/IPR/DentalMenu"
import IPR from "./components/IPR/ipr"
import { reportProps } from "./type"

import "./report.scss"
const Report: FC<reportProps> = ({ setReportShow }) => {
  const [value, setValue] = useState("IPR")
  const [appliedTeeth, setappliedTeeth] = useState<
    { teeth: string; checked: string }[]
  >([])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const { t } = useTranslation("common")
  return (
    <Drag
      DragPosition={{
        x: `${document.body.clientWidth / 2 - 305}`,
        y: 0,
      }}
    >
      <Box className="ReportBox">
        <TabContext value={value}>
          <Box className={"tabBox"}>
            <TabList
              onChange={handleChange}
              aria-label="report tabs"
              sx={{
                "& .MuiTab-root": {
                  padding: "9px 16px",
                  fontSize: "14px",
                  fontWeight: "500",
                  minWidth: "unset",
                  maxWidth: "unset",
                },
              }}
            >
              <Tab label="IPR" value="IPR" />
              <Tab label="ATTACHMENT" value="ATTACHMENT" />
              <Tab label="AUXILLIARIES" value="AUXILLIARIES" />
              <Tab label="DENTAL CHART" value="DENTAL CHART" />
            </TabList>
            <Button className={"btn print"} />
            <Button
              className={"btn close"}
              onClick={() => {
                setReportShow(false)
              }}
            />
          </Box>
          <TabPanel className={"iprContent"} value="IPR">
            <IPR />
          </TabPanel>
          <TabPanel value="ATTACHMENT">Item Two</TabPanel>
          <TabPanel value="AUXILLIARIES">Item Three</TabPanel>
          <TabPanel
            value="DENTAL CHART"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <DentalMenu></DentalMenu>
            <DentalChart
              appliedTeeth={appliedTeeth}
              isReadOnly={true}
            ></DentalChart>
          </TabPanel>
        </TabContext>
      </Box>
    </Drag>
  )
}
export default Report
