import { FC, ReactNode } from "react"
import React from "react"
import { createTheme, ThemeProvider } from "@mui/material"

import uSmileTheme from "./theme/theme"

const UsmileThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider theme={createTheme(uSmileTheme)}>{children}</ThemeProvider>
  )
}

export default UsmileThemeProvider
