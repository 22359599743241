import { isUpTooth } from "../../common/toothtool";
import { report } from "../report/report";
import { wasmModule } from "../wasm/wasmModule";
import * as popupHelper from "../../common/popuphelper";

const resetbuttonevent = new CustomEvent("resetbuttonevent", {});

export const NormalAttIDs = [
  0, 1, 2, 3, 8, 9, 10, 21, 11, 12, 13, 14, 15, 16, 17, 18, 22,
];
export const PressurePointIDs = [7, 19, 20];
export const ButtonAndSlitsIDs = [6, 5, 4, 23];

/**
 * data of all stage
 */
export interface IAttachmentIPRVisible {
  isHasIPRData: boolean;
  isHasAttachmentData: boolean;
}

class AttachmentModule {
  attachmentPanelData: {
    data;
    isAttachmentPanelShow;
    deletePos;
  };

  wasmAttachmentModule;
  attachmentiprVisibility?: (stageData: IAttachmentIPRVisible) => void;
  constructor() {
    this.attachmentPanelData = {
      data: [0, 0, 0, 0, 0, 0], // 六排数据
      isAttachmentPanelShow: false, // attachment panel开关
      deletePos: [0, 0], // 删除按钮位置
    };
  }

  /**
   * 打开/关闭 attachment module
   * @param isOpen
   * @returns
   */
  openAttachmentModule(isOpen: boolean) {
    if (!wasmModule.isInit) return;

    wasmModule.moduleManager.SwitchAttachmentModule(isOpen);

    //  if (!this.wasmAttachmentModule) {
    this.wasmAttachmentModule = wasmModule.moduleManager.GetAttachmentModule();
    //   }
    console.log("wasmAttachmentModule :", this.wasmAttachmentModule);
    this.setAttachmentSelectAbleType("AttachmentAndTeeth");

    if (!isOpen) {
      this.attachmentPanelData.isAttachmentPanelShow = false;

      this.dispatchDataToUI(this.attachmentPanelData);
    }
  }

  /**
   * 设置鼠标可以选中的模型类型
   * @param type `NormalAttachment`：鼠标只能选中牙齿上的附件，在打开右侧aux面板时设置
   * `AttachmentAndTeeth `：鼠标可以选中牙齿或者牙齿上的附件
   * @returns
   */
  setAttachmentSelectAbleType(type: "NormalAttachment" | "AttachmentAndTeeth") {
    if (!wasmModule.isInit) return;
    this.wasmAttachmentModule = wasmModule.moduleManager.GetAttachmentModule();
    if (!this.wasmAttachmentModule) return;

    switch (type) {
      case "NormalAttachment":
        this.wasmAttachmentModule.SetAttachmentSelectAbleType(
          wasmModule.module.AttachmentSelectAbleType.NormalAttachment
        );
        break;
      case "AttachmentAndTeeth":
        this.wasmAttachmentModule.SetAttachmentSelectAbleType(
          wasmModule.module.AttachmentSelectAbleType.AttachmentAndTeeth
        );
        break;
      default:
        break;
    }
  }

  /**
   * 在AUX UI面板中选中相应附件后调用
   * @param attId 附件ID
   * @returns
   */
  setAttachmentSelectLibID(attId: number) {
    if (!wasmModule.isInit && this.wasmAttachmentModule) return;

    this.wasmAttachmentModule.SelectLibAttachment(attId);
  }

  addAllAttachment() {
    console.log("AddAllAttachment");
    if (!wasmModule.isInit && this.wasmAttachmentModule) return;
    this.wasmAttachmentModule.AddAllAttachment(0);
    this.wasmAttachmentModule.AddAllAttachment(1);
  }

  addAllBiteRamp() {
    console.log("AddAllBiteRamp");
    if (!wasmModule.isInit && this.wasmAttachmentModule) return;
    this.wasmAttachmentModule.AddAllBiteRamp();
  }

  deleteSelectedAttachment() {
    this.wasmAttachmentModule.DeleteAttachment();
    this.attachmentPanelData.isAttachmentPanelShow = false;
    this.dispatchDataToUI(this.attachmentPanelData);
    // popupHelper.alertPopup("warning", "Are your sure?", [
    //   {
    //     name: "ok",
    //     func: () => {
    //       this.wasmAttachmentModule.DeleteAttachment();

    //       this.attachmentPanelData.isAttachmentPanelShow = false;
    //       this.dispatchDataToUI(this.attachmentPanelData);
    //     },
    //   },
    //   {
    //     name: "cancel",
    //     func: () => {},
    //   },
    // ]);
  }

  deleteAllAttachment(attType) {
    console.log("DeleteAllAttachment");
    if (!wasmModule.isInit && this.wasmAttachmentModule) return;
    this.wasmAttachmentModule.CleanAllAttachment(attType);
  }

  onSelectedAttachment() {
    const toothId = this.wasmAttachmentModule.Getm_CurSelectedAttachToothID();
    console.log("onSelectAttachment :", toothId);

    let distanceXYZ;

    // if (isUpTooth(toothId))
    //   distanceXYZ = report.UpReportArrays.get(wasmModule.stagingcontoler.GetCurrentStageIndex(0))[
    //     toothId
    //   ].distanceRotate;
    // else
    //   distanceXYZ = report.LowReportArrays.get(wasmModule.stagingcontoler.GetCurrentStageIndex(1))[
    //     toothId
    //   ].distanceRotate;

    // this.attachmentPanelData.data = distanceXYZ;
    // this.attachmentPanelData.isAttachmentPanelShow = true;
    // this.dispatchDataToUI(this.attachmentPanelData);
  }

  async dispatchDataToUI(attachmentPanelData) {
    // await getDvaApp()._store.dispatch({
    //   type: 'patients/setStageData',
    //   payload: {
    //     vtkmodel: { attachmentPanelData },
    //   },
    // });
  }
  openGMAttachmentModule(isOpen: boolean) {
    if (!wasmModule.isInit) return;
    wasmModule.moduleManager.ToggleLowerArchMove(isOpen);
    this.wasmAttachmentModule = wasmModule.moduleManager.GetAttachmentModule();
  }
  onSelectedGMAttachment(archType: number) {
    console.log("archType: ", archType);

    wasmModule.moduleManager.OnGMAttachmentButtonDown(archType);
  }
  getAttachmentReport() {
    const attachment: Record<
      number,
      {
        toothId: number;
        attachment: { attachmentId: number; onLingualSide: boolean }[];
      }
    > = {};
    const uplist: number[] = [];
    const lowlist: number[] = [];
    const attachmentModel = wasmModule.mouthModel.GetAttachmentModel();
    const toothupIds = wasmModule.mouthModel
      .GetArchModel(wasmModule.module.ArchType.UpArch)
      .GetToothIds();
    const size = toothupIds.size();
    for (let i = 0; i < size; i++) {
      const toothid = toothupIds.get(i);
      uplist.push(toothid);
      if (!attachmentModel) continue;
      const attachmentDataInfo: any[] = [];
      const num = attachmentModel.GetToothAttachmentCount(toothid);
      for (let j = 0; j < num; j++) {
        const attachmentInfo = attachmentModel.GetAttachmentInfo(
          toothid,
          j,
          false
        );
        const isLinggua =
          attachmentModel.BAttachmentOnLingualSide(attachmentInfo);
        attachmentDataInfo.push({
          attachmentId: attachmentInfo.AttachmentId,
          onLingualSide: isLinggua,
        });
      }
      attachment[toothid] = {
        toothId: toothid,
        attachment: attachmentDataInfo,
      };
    }
    if (uplist[0] > uplist[uplist.length - 1]) {
      uplist.reverse();
    }
    const toothlowIds = wasmModule.mouthModel
      .GetArchModel(wasmModule.module.ArchType.DownArch)
      .GetToothIds();
    const sizelow = toothlowIds.size();
    for (let i = 0; i < sizelow; i++) {
      const toothid = toothlowIds.get(i);
      lowlist.push(toothid);
      if (!attachmentModel) continue;
      const attachmentDataInfo: any[] = [];
      const num = attachmentModel.GetToothAttachmentCount(toothid);
      for (let j = 0; j < num; j++) {
        const attachmentInfo = attachmentModel.GetAttachmentInfo(
          toothid,
          j,
          false
        );
        const isLinggua =
          attachmentModel.BAttachmentOnLingualSide(attachmentInfo);
        attachmentDataInfo.push({
          attachmentId: attachmentInfo.AttachmentId,
          onLingualSide: isLinggua,
        });
      }

      attachment[toothid] = {
        toothId: toothid,
        attachment: attachmentDataInfo,
      };
    }
    if (lowlist[0] < lowlist[uplist.length - 1]) {
      lowlist.reverse();
    }
    return { attachmentReport: attachment, toothList: { uplist, lowlist } };
  }
  onResetAttachmentSelectButton() {
    console.log("dispatch resetbuttonevent");
    document.dispatchEvent(resetbuttonevent);
  }
  onAddTempAttachemnt(checkId: number, optionType: number) {
    console.log("onAddTempAttachemnt: ", checkId, "; ", optionType);
    if (!wasmModule.isInit && this.wasmAttachmentModule) return;
    if (checkId != 3) {
      this.wasmAttachmentModule.AddTempAttachemnt(checkId, optionType);
    } else {
      popupHelper.alertPopup("info", "", [
        {
          name: "left side",
          func: () => {
            this.wasmAttachmentModule.AddTempAttachemnt(checkId, 1);
          },
        },
        {
          name: "right side",
          func: () => {
            this.wasmAttachmentModule.AddTempAttachemnt(checkId, 2);
          },
        },
        {
          name: "both side",
          func: () => {
            this.wasmAttachmentModule.AddTempAttachemnt(checkId, 3);
          },
        },
      ]);
    }
  }
}

export const attachmentModule = new AttachmentModule();
