import type { FC } from "react"
import React from "react"

const WasmCanves: FC<{ className?: string; style?: object }> = (props) => {
  return (
    <canvas
      onContextMenu={(e) => {
        //禁止右键
        e.preventDefault()
        return false
      }}
      id="canvas"
      {...props}
    />
  )
}
export default WasmCanves
